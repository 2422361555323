import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { Spin, Card } from 'antd';
import { translate } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import PageHeader from 'ant-design-pro/lib/PageHeader';

import { getCustomer, createDocument, deleteDocument } from 'store/modules/customers/actions';
import { ITranslator, Redux, SelectorType } from 'typings';
import { selectCustomer, selectCustomerError, selectCustomerLoading, selectDocs } from 'store/modules/customers/selectors';
import DocumentForm from 'view/containers/DocumentForm';
import { DocumentFormData } from 'view/containers/DocumentForm/@typings';
import CustomerInfo from 'view/components/CustomerInfo';
import CustomerDocumentsTable from 'view/components/CustomerDocumentsTable';

interface UrlParams {
    userId: string;
}

interface Props extends ITranslator, RouteComponentProps<UrlParams> {
    getCustomer: typeof getCustomer;
    createDocument: typeof createDocument;
    deleteDocument: typeof deleteDocument;
    loading: SelectorType<typeof selectCustomerLoading>;
    customer: SelectorType<typeof selectCustomer>;
    docs: SelectorType<typeof selectDocs>;
    error: SelectorType<typeof selectCustomerError>;
}

class CustomerDetail extends Component<Props> {
    componentDidMount() {
        this.props.getCustomer(this.props.match.params.userId);
    }

    render() {
        const { customer, docs, loading, createDocument, deleteDocument, t } = this.props;
        const { userId } = this.props.match.params;

        return (
            <Spin spinning={loading}>
                <PageHeader itemRender={null} title={t('routes.CustomerDetail.title')} />
                <Card style={{ marginBottom: '50px' }} bordered={false}>
                    {customer && <CustomerInfo {...customer} />}
                </Card>
                <DocumentForm onSubmit={(formData: DocumentFormData) => createDocument(userId, formData)} />
                <CustomerDocumentsTable documents={docs} onDelete={deleteDocument} />
            </Spin>
        );
    }
}

export default compose(
    withRouter,
    translate(),
    connect(
        (state: Redux) => ({
            loading: selectCustomerLoading(state),
            customer: selectCustomer(state),
            docs: selectDocs(state),
            error: selectCustomerError(state),
        }),
        { getCustomer, createDocument, deleteDocument },
    ),
)(CustomerDetail);
