import Row from 'antd/es/row';
import React from 'react';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { TextField } from 'redux-form-antd';
import { resetPassword, resetPasswordConfirm } from 'store/modules/auth/actions';
import { Routes } from 'store/modules/ui/constants';
import { ITranslator } from 'typings';
import { Form, StyledButton } from './styled';
import validate from './validate';

interface Props extends ITranslator, InjectedFormProps {
    confirmReset?: boolean;
}

const forgottenPasswordForm = ({ handleSubmit, t, submitting, confirmReset }: Props) => {
    return (
        <Form onSubmit={handleSubmit(confirmReset ? resetPasswordConfirm : resetPassword)}>
            {confirmReset ? (
                <>
                    <Field
                        name="newPassword"
                        size="large"
                        type="password"
                        label={t('routes.ForgottenPassword.newPassword')}
                        component={TextField}
                    />
                    <Field
                        name="passwordConfirmation"
                        size="large"
                        type="password"
                        label={t('routes.ForgottenPassword.passwordConfirmation')}
                        component={TextField}
                    />
                    <Field name="code" size="large" label={t('routes.ForgottenPassword.code')} component={TextField} />
                </>
            ) : (
                <Field name="email" size="large" label={t('routes.ForgottenPassword.email')} component={TextField} />
            )}
            <Row type="flex" justify="center">
                <StyledButton htmlType="submit" type="primary" loading={submitting}>
                    {t('routes.ForgottenPassword.submit')}
                </StyledButton>
                <Link to={Routes.SIGNIN.path}>
                    <StyledButton loading={submitting}>{t('routes.ForgottenPassword.back')}</StyledButton>
                </Link>
            </Row>
        </Form>
    );
};
export default compose<any>(reduxForm({ form: 'forgottenPassword', validate }), translate())(forgottenPasswordForm);
