import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 22px;
    padding-bottom: 22px;
`;

export const StyledLink = styled.a`
    margin-left: 15px;
`;

export const AppStore = styled.img`
    margin: 0 auto;
    width: 130px;
    height: 40px;
`;
