import Row from 'antd/es/row';
import Spin from 'antd/es/spin';
import React from 'react';

interface Props {}

const CenteredSpin: React.SFC<Props> = (props: Props) => {
    return (
        <Row type="flex" justify="center">
            <Spin size="large" />
        </Row>
    );
};
export default CenteredSpin;
