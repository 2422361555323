import styled from 'styled-components';

export const CustomerInfoContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
`;

export const CustomerInfoEntryContainer = styled.div`
    margin-right: 24px;
`;
