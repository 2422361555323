import { t } from 'i18next';
import React from 'react';
import { VideoData } from 'store/modules/video/@types';
import { ITranslator } from 'typings';
import { BoldSpan, Logo, Span, StyledPlayer, VideoWrapper, Wrapper } from './styled';

const logo = require('assets/ic_volvista_logo.png');

interface Props extends ITranslator {
    data?: VideoData;
    onResponseClick?: (response: any) => void;
    responseSent?: boolean;
}

export default (props: Props) => {
    const { email, phone, url, name } = props.data;
    return (
        <Wrapper>
            <Logo src={logo} alt="logo" />
            <Span>{t('routes.Video.info')}</Span>
            <VideoWrapper>
                <StyledPlayer url={url} width="100%" height="100%" controls />
            </VideoWrapper>
            <BoldSpan>{t('routes.Video.technician')}</BoldSpan>
            <span>{name}</span>
            <span>{email}</span>
            <span>{phone}</span>
        </Wrapper>
    );
};
