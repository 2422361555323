import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import rootReducers from 'store/reducers';
import rootSagas from 'store/sagas';

export const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory();

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth'],
};
const persistedReducer = persistReducer(persistConfig, rootReducers);

export const initStore = () => {
    return { type: 'init' };
};
const composeEnhancer: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const configureStore = () => {
    return createStore(
        connectRouter(history)(persistedReducer),
        composeEnhancer(applyMiddleware(routerMiddleware(history), sagaMiddleware)),
    );
};

const store = configureStore();

rootSagas.map((saga: any) => sagaMiddleware.run(saga, store.dispatch));

export const persistor = persistStore(store);
export default store;
