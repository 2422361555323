import find from 'lodash-es/find';
import { createSelector } from 'reselect';
import { CategoryOptions } from 'store/modules/symbols/constants';
import { Redux } from 'typings';

import { Detail, Filter, List, SymbolsReducer, SymbolType } from './@types.d';

const selectSymbolsSubstate = (state: Redux) => state.symbols;
const selectSymbolsListSubstate = createSelector(selectSymbolsSubstate, (subState: SymbolsReducer) => subState.list);
const selectSymbolsDetailSubstate = createSelector(selectSymbolsSubstate, (subState: SymbolsReducer) => subState.detail);

export const selectSymbolsLoading = createSelector(selectSymbolsListSubstate, (subState: List) => subState.loading);
export const selectSymbolsError = createSelector(selectSymbolsListSubstate, (subState: List) => subState.error);
export const selectSymbolById = (symbolId: string) =>
    createSelector(selectSymbolsListSubstate, (subState: List) => find(subState.data, { symbolId }));

export const selectSymbolLoading = createSelector(selectSymbolsDetailSubstate, (subState: Detail) => subState.loading);
export const selectSymbol = createSelector(selectSymbolsDetailSubstate, (subState: Detail) => subState.data);
export const selectSymbolId = createSelector(selectSymbolsDetailSubstate, (subState: Detail) => subState.data.symbolId);
export const selectVariantId = createSelector(selectSymbolsDetailSubstate, (subState: Detail) => subState.data.variantId);
export const selectSymbolError = createSelector(selectSymbolsDetailSubstate, (subState: Detail) => subState.error);
export const selectSymbolSending = createSelector(selectSymbolsDetailSubstate, (subState: Detail) => subState.sending);

const selectSymbolsFilters = createSelector(selectSymbolsListSubstate, (subState: List) => subState.filter);
export const selectSymbolsTextFilter = createSelector(selectSymbolsFilters, (subState: Filter) => subState.searchText);
export const selectSymbolsCategoryFilter = createSelector(selectSymbolsFilters, (subState: Filter) => subState.selectedCategory);

const selectSymbolsListData = createSelector(selectSymbolsListSubstate, (subState: List) => subState.data);
export const selectSymbolsByCategory = createSelector(
    [selectSymbolsListData, selectSymbolsCategoryFilter],
    (data: [SymbolType], selectedCategory: string) => {
        if (selectedCategory === CategoryOptions.ALL) {
            return data;
        }

        return data && data.filter((data: SymbolType) => data.category === selectedCategory);
    },
);

export const selectSymbols = createSelector(
    [selectSymbolsByCategory, selectSymbolsTextFilter],
    (data: [SymbolType], searchText: string) =>
        data &&
        data.filter(
            (data: SymbolType) =>
                data.name.cs
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .includes(
                        searchText
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, ''),
                    ) ||
                data.name.en
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .includes(
                        searchText
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, ''),
                    ),
        ),
);
