import PageHeader from 'ant-design-pro/lib/PageHeader';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import Select from 'antd/es/select';
import SearchInput from 'components/SearchInput';
import SelectInput from 'components/SelectInput';
import SymbolsList from 'containers/SymbolsList';
import { t } from 'i18next';
import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'redux';
import { setFilter } from 'store/modules/symbols/actions';
import { CategoryOptions } from 'store/modules/symbols/constants';
import { Routes } from 'store/modules/ui/constants';
import { ITranslator } from 'typings';
import { SearchWrapper } from './styled';

interface Props extends ITranslator {
    push?: typeof push;
    setFilter?: typeof setFilter;
}

const Option = Select.Option;
const CATEGORY_OPTIONS = [
    {
        label: t('routes.Symbols.all'),
        value: CategoryOptions.ALL,
    },
    {
        label: t('routes.Symbols.red'),
        value: CategoryOptions.RED,
    },
    {
        label: t('routes.Symbols.orange'),
        value: CategoryOptions.ORANGE,
    },
    {
        label: t('routes.Symbols.others'),
        value: CategoryOptions.OTHERS,
    },
];

class Symbols extends React.Component<Props> {
    componentDidMount() {
        const { setFilter } = this.props;
        // Reset filter when navigating between routes
        setFilter('searchText', '');
        setFilter('selectedCategory', CATEGORY_OPTIONS[0].value);
    }
    handleClickAddSymbol = () => {
        const { push } = this.props;
        push(Routes.ADD_SYMBOL.path);
    };

    renderSelectOptions = () => {
        return CATEGORY_OPTIONS.map((option: { label: string; value: string }) => (
            <Option key={option.value} value={option.value}>
                {option.label}
            </Option>
        ));
    };

    renderHeaderContent = () => {
        const { setFilter } = this.props;
        return (
            <SearchWrapper>
                <SearchInput onChange={(value: string) => setFilter('searchText', value)} />
                <SelectInput
                    defaultValue={CATEGORY_OPTIONS[0].value}
                    onOptionSelect={(value: string) => setFilter('selectedCategory', value)}
                >
                    {this.renderSelectOptions()}
                </SelectInput>
            </SearchWrapper>
        );
    };

    render() {
        const { t } = this.props;

        return (
            <>
                <PageHeader
                    itemRender={null}
                    action={
                        <Button type="primary" className="action" onClick={this.handleClickAddSymbol}>
                            {t('routes.Symbols.addSymbol')}
                        </Button>
                    }
                    title={t('routes.Symbols.title')}
                    content={this.renderHeaderContent()}
                />
                <Card bordered={false}>
                    <SymbolsList />
                </Card>
            </>
        );
    }
}
export default compose<any>(connect(null, { push, setFilter }), translate())(Symbols);
