import Auth from 'aws-amplify/lib/Auth';
import Storage from 'aws-amplify/lib/Storage';
import { ConnectedRouter } from 'connected-react-router';
import i18n from 'lib/i18n';
import * as React from 'react';
import { hot } from 'react-hot-loader';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { Switch } from 'react-router';
import { PersistGate } from 'redux-persist/integration/react';
import store, { history, persistor } from 'store/configStore';
import { TypeComponent, Routes } from 'store/modules/ui/constants';
import { ThemeProvider } from 'styled-components';
import GlobalTheme, { GlobalStyle } from 'stylesheets/GlobalTheme';
import { Route } from 'typings';
import Loader from 'view/containers/Loader';
import PrivateRoute from 'view/containers/PrivateRoute';
import UnsignedRoute from 'view/containers/UnsignedRoute';
import PublicRoute from 'view/containers/PublicRoute';

declare const Conf;
console.log('Conf', Conf);
Auth.configure(Conf.amplify.auth);
Storage.configure({
    bucket: Conf.amplify.storage.bucket.appData,
    region: Conf.amplify.storage.region,
    identityPoolId: Conf.amplify.storage.identityPoolId,
});
const typeOfComponent = (type: TypeComponent) => {
    switch (type) {
        case TypeComponent['PRIVATE']:
            return PrivateRoute;
        case TypeComponent['UNSIGNED']:
            return UnsignedRoute;
        case TypeComponent['PUBLIC']:
            return PublicRoute;

        default:
            return PrivateRoute;
    }
};

const createRoute = (route: Route) => {
    const { isExact, path, type, component, roles } = route;
    const RouteComponent = typeOfComponent(type);

    const props = Object.assign(
        {
            key: path,
            exact: isExact,
            path,
            component,
        },
        type === TypeComponent['PRIVATE'] && { roles },
    );

    return <RouteComponent {...props} />;
};

class App extends React.Component<{}, {}> {
    render() {
        return (
            <ThemeProvider theme={GlobalTheme}>
                <Provider store={store}>
                    <PersistGate loading={<div>LOADING</div>} persistor={persistor}>
                        <Loader>
                            <GlobalStyle />
                            <I18nextProvider i18n={i18n}>
                                <ConnectedRouter history={history}>
                                    <Switch>{Object.values(Routes).map(createRoute)}</Switch>
                                </ConnectedRouter>
                            </I18nextProvider>
                        </Loader>
                    </PersistGate>
                </Provider>
            </ThemeProvider>
        );
    }
}

export default hot(module)(App);
