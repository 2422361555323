import { ColumnFilterItem, ColumnProps } from 'antd/es/table';
import { TranslationFunction } from 'react-i18next';
import { Filters, TableRow } from 'store/modules/manuals/@types';

const transformFilter = (filter: string[]): ColumnFilterItem[] =>
    filter &&
    filter.map(
        (filter: string): ColumnFilterItem => ({
            text: filter,
            value: filter,
        }),
    );

export default function getColumns(filters: Filters, t: TranslationFunction): ColumnProps<TableRow>[] {
    return [
        {
            filters: transformFilter(filters.data.model),
            title: t('routes.Manuals.columns.models'),
            key: 'model',
            dataIndex: 'model',
        },
        {
            filters: transformFilter(filters.data.year),
            title: t('routes.Manuals.columns.years'),
            key: 'year',
            dataIndex: 'year',
        },
        {
            key: 'description.cs',
            title: t('routes.Manuals.columns.cz'),
            dataIndex: 'description.cs',
        },
        {
            key: 'description.en',
            title: t('routes.Manuals.columns.en'),
            dataIndex: 'description.en',
        },
    ];
}
