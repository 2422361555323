import Cascader from 'antd/es/cascader';
import FormItem from 'antd/es/form/FormItem';
import React from 'react';
import mapError from 'redux-form-antd/es/maps/mapError';

const FormCascader: React.SFC<any> = (props: any) => {
    const { label, labelCol, wrapperCol, help, extra, validateStatus, hasFeedback = true, colon, required, ...rest } = mapError(props);
    const { onChange, value } = props.input;
    return (
        <FormItem
            label={label}
            wrapperCol={wrapperCol}
            labelCol={labelCol}
            help={help}
            hasFeedback={hasFeedback}
            extra={extra}
            validateStatus={validateStatus}
            colon={colon}
            required={required}
        >
            <Cascader {...rest} onChange={(values: string[]) => onChange(values.join('-'))} value={value.split('-')} />
        </FormItem>
    );
};
export default FormCascader;
