import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

export const StyledLink = styled(Link)`
    padding-bottom: 15px;
    text-align: end;
`;
