import PageHeader from 'ant-design-pro/lib/PageHeader';
import Card from 'antd/es/card';
import AddUserComponent from 'containers/AddUser';
import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getUserGroups } from 'store/modules/users/actions';
import { selectUserGroups, selectUserGroupsLoading } from 'store/modules/users/selectors';
import { ITranslator, Redux, SelectorType } from 'typings';
import CenteredSpin from 'view/components/CenteredSpin';

interface Props extends ITranslator {
    userGroups?: SelectorType<typeof selectUserGroups>;
    loading?: SelectorType<typeof selectUserGroupsLoading>;
    getUserGroups?: typeof getUserGroups;
}

class AddUser extends React.Component<Props> {
    componentDidMount() {
        const { userGroups, getUserGroups } = this.props;
        if (!userGroups) {
            getUserGroups();
        }
    }

    render() {
        const { t, userGroups, loading } = this.props;

        if (!userGroups || loading) {
            return <CenteredSpin />;
        }

        return (
            <>
                <PageHeader itemRender={null} title={t('routes.AddUser.title')} />
                <Card bordered={false}>
                    <AddUserComponent userGroups={userGroups} />
                </Card>
            </>
        );
    }
}

export default compose<any>(
    connect(
        (state: Redux) => ({
            loading: selectUserGroupsLoading(state),
            userGroups: selectUserGroups(state),
        }),
        { getUserGroups },
    ),
    translate(),
)(AddUser);
