import FormItem from 'antd/es/form/FormItem';
import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import mapError from 'redux-form-antd/es/maps/mapError';

import TimeRange from './components/TimeRange';

interface Props extends FormItem, WrappedFieldProps {
    timeFormat?: string;
    minuteStep?: number;
    label?: string;
}

const FormTimeRange: React.SFC<Props> = (props: Props) => {
    const { label, labelCol, wrapperCol, help, extra, validateStatus, hasFeedback = true, colon, required, ...rest } = mapError(props);

    return (
        <FormItem
            label={label}
            wrapperCol={wrapperCol}
            labelCol={labelCol}
            help={help}
            hasFeedback={false}
            extra={extra}
            validateStatus={validateStatus}
            colon={colon}
            required={required}
        >
            <TimeRange input={props.input} format={props.timeFormat} {...rest} />
        </FormItem>
    );
};

export default FormTimeRange;
