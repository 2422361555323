import { api, documentsApi } from 'utils/request';
import { UserDocument } from './@types';

declare const Conf;
const APP_ID: string = Conf.APP_ID;
const PAGE_LIMIT: number = Conf.PAGE_LIMIT;

const fetcher = (token: string) => ({
    getCustomers: (page: number, vin?: string | string[], email?: string) =>
        api(
            `apps/${APP_ID}/users`,
            {
                method: 'GET',
                headers: {
                    Authorization: token,
                },
            },
            {
                page,
                limit: PAGE_LIMIT,
                vin,
                email,
            },
        ),
    unassignCar: (userId: string, vin: string) =>
        api(`apps/${APP_ID}/users/${userId}/cars/${vin}/unassign`, {
            method: 'POST',
            headers: {
                authorization: token,
            },
        }),

    getCustomer: (userId: string) =>
        api(`apps/${APP_ID}/users/${userId}`, {
            method: 'GET',
            headers: {
                authorization: token,
            },
        }),

    getCustomerDocs: (userId: string) =>
        documentsApi(
            '',
            {
                method: 'GET',
                headers: {
                    authorization: token,
                },
            },
            { userId },
        ),

    addCustomerDocument: (userId: string, s3Key: string, name: string, type: string): Promise<UserDocument> =>
        documentsApi('/', {
            method: 'POST',
            headers: { authorization: token },
            body: {
                userId,
                s3Key,
                name,
                type,
            },
        }),

    deleteCustomerDocument: (docId: string) =>
        documentsApi(`/${docId}`, {
            method: 'DELETE',
            headers: { authorization: token },
        }),
});

export default fetcher;
