import Button from 'antd/es/button';
import React from 'react';
import { Field, InjectedFormProps } from 'redux-form';
import TextField from 'redux-form-antd/es/components/TextField';
import { signIn } from 'store/modules/auth/actions';
import { ITranslator } from 'typings';

import { Form, StyledLink } from './styled';

interface Props extends ITranslator {
    submitting: boolean;
    handleSubmit: InjectedFormProps['handleSubmit'];
}

const SignInForm = ({ handleSubmit, t, submitting }: Props) => {
    return (
        <Form onSubmit={handleSubmit(signIn)}>
            <Field name="username" size="large" label={t('routes.SignIn.email')} component={TextField} />
            <Field name="password" type="password" size="large" label={t('routes.SignIn.password')} component={TextField} />
            <StyledLink to="/forgotten-password">{t('routes.SignIn.forgottenPassword')}</StyledLink>
            <Button htmlType="submit" type="primary" loading={submitting}>
                {t('routes.SignIn.submit')}
            </Button>
        </Form>
    );
};
export default SignInForm;
