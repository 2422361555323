import Layout from 'antd/es/layout';
import Menu from 'antd/es/menu';
import React, { ReactNode } from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { signOut } from 'store/modules/auth/actions';
import { selectUserEmail } from 'store/modules/auth/selectors';
import { Role, Routes } from 'store/modules/ui/constants';
import { ITranslator, Redux, Route, SelectorType } from 'typings';
import isRouteAllowed from 'utils/rolesAuth';

import { SimpleMenuItem } from 'components/MenuItem/SimpleMenuItem';
import {
    Email,
    Icon,
    InnerContent,
    ScanCodeWrap,
    Signout,
    StyledContent,
    StyledHeader,
    StyledLayout,
    Title,
    User,
    UserInfo,
} from './styled';
import { getPresence } from 'store/modules/scanCode/actions';
import { List } from 'store/modules/scanCode/@types';
import { selectList } from 'store/modules/scanCode/selectors';
import ScanCodeAlert from 'templates/DefaultTemplate/ScanCodeAlert';

const { Sider } = Layout;

const heartIcon = require('assets/ic_heart_volvo.png');

interface Props extends ITranslator {
    signOut?: typeof signOut;
    getPresence?: typeof getPresence;
    children: ReactNode;
    userRoles?: Role[];
    userEmail?: SelectorType<typeof selectUserEmail>;
    list?: List[];
}

const createMenuItem = (route: Route, t: TranslationFunction) => {
    const { path, sideMenu } = route;

    const { icon, label } = sideMenu;
    return <SimpleMenuItem key={path} iconType={icon} labelText={t(`sider.${label}`)} route={path} />;
};

const DefaultTemplate = ({ signOut, getPresence, children, userRoles, userEmail, list, t }: Props) => {
    const [collapsed, setCollapsed] = React.useState(false);
    const msInterval = 1000;

    React.useEffect(() => {
        getPresence();
    }, []);

    const poolingPresenceEndpoint = () => {
        const interval = setInterval(() => {
            getPresence();
        }, msInterval);
        return () => clearInterval(interval);
    };

    React.useEffect(poolingPresenceEndpoint, []);

    const onCollapse = (collapsed: boolean) => setCollapsed(collapsed);
    const handleSignOut = () => signOut();

    return (
        <StyledLayout>
            <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
                <Title>{collapsed ? t('title-collapsed') : t('title')}</Title>
                <Menu theme="dark" mode="inline">
                    {Object.values(Routes)
                        .filter((route: Route) => route.sideMenu && isRouteAllowed(route.roles, userRoles))
                        .map((route: Route) => createMenuItem(route, t))}
                </Menu>
            </Sider>
            <Layout>
                <StyledHeader>
                    <User>
                        <UserInfo>
                            <Email>{userEmail}</Email>
                            <Signout onClick={handleSignOut}>{t('sider.SignOut')}</Signout>
                        </UserInfo>
                        <Icon src={heartIcon} alt="user" />
                    </User>
                </StyledHeader>
                {list && !!list.length && (
                    <ScanCodeWrap>
                        <ScanCodeAlert list={list} />
                    </ScanCodeWrap>
                )}
                <StyledContent>
                    <InnerContent>{children}</InnerContent>
                </StyledContent>
            </Layout>
        </StyledLayout>
    );
};

//FIXME?: Negating Props interface to prevent typescript error..
export default compose<any>(
    connect(
        (state: Redux) => ({
            userEmail: selectUserEmail(state),
            list: selectList(state),
        }),
        { signOut, getPresence },
    ),
    translate(),
)(DefaultTemplate);
