import { t } from 'i18next';
import isEmpty from 'lodash-es/isEmpty';

export function createValidator(rules: any) {
    return (data: any = {}, props: any) => {
        const errors: any = {};
        Object.keys(rules).forEach((key: any) => {
            const rule = join([].concat(rules[key])); // concat enables both functions and arrays of functions
            const error = rule(data[key], data, props, key);
            if (error) {
                errors[key] = error;
            }
        });
        return errors;
    };
}

const join = (rules: any) => (value: any, data: any, props: any, name: any) =>
    rules.map((rule: any) => rule(value, data, props, name)).filter((error: any) => !!error)[0 /* first error */];

export const required = (value: any) => (value && value.length ? null : t('validation.required'));
export const requiredArray = (values: any) => {
    const hasValues = values && values.some((value: string | number) => !!value);
    return !hasValues ? t('validation.required') : null;
};

export function email(value) {
    // Let's not start a debate on email regex. This is just for an example app!
    if (!isEmpty(value) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        return t('validation.email');
    }
}

export function phoneNumber(value) {
    if (!isEmpty(value) && !/^[0-9]{9}$/i.test(value)) {
        return t('validation.phoneNumber');
    }
}

export function includesNumbers(values: any[]) {
    const hasNaN = values.some((value: any) => isNaN(Number(value)));
    return hasNaN ? t('validation.includesNumbers') : null;
}

export function minLength(minimum: number) {
    return (value: string) => {
        if (!isEmpty(value) && value.length < minimum) {
            return t('validation.minLength', { minimum });
        }
    };
}

export function maxLength(maximum: number) {
    return (value: string) => {
        if (!isEmpty(value) && value.length > maximum) {
            return t('validation.maxLength', { maximum });
        }
    };
}
export const confirmPassword = (value: string, values: any) =>
    values.passwordConfirmation !== values.newPassword && t('validation.confirmPassword');
