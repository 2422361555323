import { Button } from 'antd';
import { t } from 'i18next';
import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, formValueSelector, InjectedFormProps, reduxForm, reset } from 'redux-form';
import { DatePickerField, SelectField, TextAreaField } from 'redux-form-antd';
import { Events } from 'store/modules/appInfo/constants';
import { scheduleNotification, updateTicket } from 'store/modules/tickets/detail/actions';
import { ITranslator, Redux } from 'typings';
import TimeInput from 'view/components/TimeInput';

import { ButtonWrapper, StyledForm, TextArea, Title } from './styled';
import validate from './validate';

interface Props extends InjectedFormProps, ITranslator {
    notificationOption?: string;
}

const NOTIFICATION_OPTIONS = [
    {
        label: t('containers.AddEvent.contactSucceeded'),
        value: Events.Reception.CONTACT_SUCCEEDED,
    },
    {
        label: t('containers.AddEvent.contactFailed'),
        value: Events.Reception.CONTACT_FAILED,
    },
    {
        label: t('containers.AddEvent.notificationScheduled'),
        value: Events.Notification.SCHEDULED,
    },
];

class AddEvent extends React.Component<Props> {
    render() {
        const { handleSubmit, t, submitting, notificationOption } = this.props;
        const isSchedulingNotification = notificationOption === Events.Notification.SCHEDULED;
        return (
            <StyledForm onSubmit={handleSubmit(isSchedulingNotification ? scheduleNotification : updateTicket)}>
                <Title className="antd-pro-description-list-title">{t('containers.AddEvent.title')}</Title>
                <Field
                    name="eventName"
                    component={SelectField}
                    options={NOTIFICATION_OPTIONS}
                    label={t('containers.AddEvent.eventName')}
                    required
                />
                {!isSchedulingNotification && <TextArea name="note" component={TextAreaField} label={t('containers.AddEvent.message')} />}

                {isSchedulingNotification && (
                    <>
                        <Field
                            name="visitDate"
                            dateFormat={'DD/MM/YYYY'}
                            component={DatePickerField}
                            label={t('containers.AddEvent.visitDate')}
                            placeholder={t('containers.AddEvent.visitDate')}
                            required
                        />
                        <Field
                            name="visitTime"
                            component={TimeInput}
                            label={t('containers.AddEvent.visitTime')}
                            timeFormat={'HH:mm'}
                            minuteStep={1}
                            required
                        />
                    </>
                )}
                <ButtonWrapper>
                    <Button type="primary" htmlType="submit" loading={submitting}>
                        {t('containers.AddEvent.send')}
                    </Button>
                </ButtonWrapper>
            </StyledForm>
        );
    }
}

const resetForm = (result: any, dispatch: any) => dispatch(reset('addEvent'));
const selector = formValueSelector('addEvent');

export default compose<any>(
    reduxForm({
        form: 'addEvent',
        validate,
        initialValues: {
            eventName: NOTIFICATION_OPTIONS[0].value,
        },
        onSubmitSuccess: resetForm,
    }),
    connect((state: Redux) => ({
        notificationOption: selector(state, 'eventName'),
    })),
    translate(),
)(AddEvent);
