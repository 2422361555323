import createReducer from 'utils/createReducer';

import { BranchesReducer } from './@types.d';
import { Types } from './actions';
import * as actions from './actions';

export const initialState: BranchesReducer = {
    data: null,
    loading: false,
    sending: false,
    error: null,
};

export default createReducer(initialState, {
    [Types.GET_BRANCHES]: (state: BranchesReducer): BranchesReducer => {
        return {
            ...state,
            loading: true,
        };
    },
    [Types.GET_BRANCHES_SUCCESS]: (state: BranchesReducer, action: ReturnType<typeof actions.getBranchesSuccess>): BranchesReducer => {
        const { branches } = action.payload;
        return {
            ...state,
            data: branches,
            loading: false,
            error: null,
        };
    },
    [Types.GET_BRANCHES_FAILURE]: (state: BranchesReducer, action: ReturnType<typeof actions.getBranchesFailure>): BranchesReducer => {
        const { error } = action.payload;
        return {
            ...state,
            loading: false,
            error,
        };
    },
    [Types.CREATE_BRANCH]: (state: BranchesReducer): BranchesReducer => {
        return {
            ...state,
            sending: true,
        };
    },
    [Types.UPDATE_BRANCH]: (state: BranchesReducer): BranchesReducer => {
        return {
            ...state,
            sending: true,
        };
    },
    [Types.UPDATE_BRANCHES_SUCCESS]: (
        state: BranchesReducer,
        action: ReturnType<typeof actions.updateBranchesSuccess>,
    ): BranchesReducer => {
        const { branches } = action.payload;
        return {
            ...state,
            data: branches,
            sending: false,
            error: null,
        };
    },
    [Types.UPDATE_BRANCHES_FAILURE]: (
        state: BranchesReducer,
        action: ReturnType<typeof actions.updateBranchesFailure>,
    ): BranchesReducer => {
        const { error } = action.payload;
        return {
            ...state,
            sending: false,
            error,
        };
    },
});
