import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import { t } from 'i18next';
import { sortBy } from 'lodash';
import React, { Component } from 'react';
import { Field } from 'redux-form';
import { SelectField } from 'redux-form-antd';
import { SelectOption } from 'store/modules/manuals/@types';
import theme from 'stylesheets/GlobalTheme';
import { required } from 'utils/validation';

import { StyledButton, StyledCard } from './styled';

interface Props {
    carTypeId?: string;
    field: string;
    onRemove: (index: number) => void;
    index: number;
    selectedModel?: string;
    carOptions?: SelectOption[];
    yearOptions?: SelectOption[];
}

export default class CarRow extends Component<Props> {
    render() {
        const { field, onRemove, carOptions, yearOptions, index, selectedModel } = this.props;

        const filteredYearOptions = selectedModel
            ? yearOptions && yearOptions.filter((yearOption: SelectOption) => yearOption.name === selectedModel)
            : yearOptions;

        return (
            <StyledCard bodyStyle={{ paddingBottom: 0, backgroundColor: theme.colors.lighterGrey }}>
                <Row type="flex" align="top">
                    <Col span={10}>
                        <Field
                            options={carOptions}
                            name={`${field}.name`}
                            hasFeedback={false}
                            showSearch
                            validate={[required]}
                            component={SelectField}
                            label={t('containers.ManualForm.labels.model')}
                            placeholder={t('containers.ManualForm.placeholders.model')}
                        />
                    </Col>
                    <Col span={10}>
                        {selectedModel && (
                            <Field
                                options={sortBy(filteredYearOptions, ['label'])}
                                name={`${field}.years`}
                                hasFeedback={false}
                                mode="multiple"
                                validate={[required]}
                                component={SelectField}
                                label={t('containers.ManualForm.labels.year')}
                                placeholder={t('containers.ManualForm.placeholders.year')}
                            />
                        )}
                    </Col>
                    <Col span={4}>
                        <StyledButton type="danger" onClick={() => onRemove(index)}>
                            {t('containers.ManualForm.deleteCar')}
                        </StyledButton>
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}
