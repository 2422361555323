import { AxiosError } from 'axios';
import { createFormAction } from 'redux-form-saga';

import { SymbolType } from './@types';

export enum Types {
    GET_SYMBOLS = 'symbols/GET_SYMBOLS',
    GET_SYMBOLS_SUCCESS = 'symbols/GET_SYMBOLS_SUCCESS',
    GET_SYMBOLS_FAILURE = 'symbols/GET_SYMBOLS_FAILURE',
    CREATE_SYMBOL = 'symbols/CREATE_SYMBOL',
    CREATE_SYMBOL_SUCCESS = 'symbols/CREATE_SYMBOL_SUCCESS',
    CREATE_SYMBOL_FAILURE = 'symbols/CREATE_SYMBOL_FAILURE',
    GET_SYMBOL = 'symbols/GET_SYMBOL',
    GET_SYMBOL_SUCCESS = 'symbols/GET_SYMBOL_SUCCESS',
    GET_SYMBOL_FAILURE = 'symbols/GET_SYMBOL_FAILURE',
    UPDATE_SYMBOL = 'symbols/UPDATE_SYMBOL',
    UPDATE_SYMBOL_SUCCESS = 'symbols/UPDATE_SYMBOL_SUCCESS',
    UPDATE_SYMBOL_FAILURE = 'symbols/UPDATE_SYMBOL_FAILURE',
    DELETE_SYMBOL = 'symbols/DELETE_SYMBOL',
    DELETE_SYMBOL_SUCCESS = 'symbols/DELETE_SYMBOL_SUCCESS',
    DELETE_SYMBOL_FAILURE = 'symbols/DELETE_SYMBOL_FAILURE',
    SET_FILTER = 'symbols/SET_FILTER',
}

export const createSymbol = createFormAction(Types.CREATE_SYMBOL);
export const updateSymbol = createFormAction(Types.UPDATE_SYMBOL);

export function createSymbolSuccess(symbol: SymbolType) {
    return {
        type: Types.CREATE_SYMBOL_SUCCESS,
        payload: { symbol },
    };
}

export function createSymbolFailure(error: AxiosError) {
    return {
        type: Types.CREATE_SYMBOL_FAILURE,
        payload: { error },
    };
}

export function getSymbols() {
    return {
        type: Types.GET_SYMBOLS,
    };
}

export function getSymbolsSuccess(symbols: SymbolType[]) {
    return {
        type: Types.GET_SYMBOLS_SUCCESS,
        payload: {
            symbols,
        },
    };
}

export function getSymbolsFailure(error: AxiosError) {
    return {
        type: Types.GET_SYMBOLS_FAILURE,
        payload: { error },
    };
}

export function getSymbol(symbolId: string) {
    return {
        type: Types.GET_SYMBOL,
        payload: {
            symbolId,
        },
    };
}

export function getSymbolSuccess(symbol: SymbolType) {
    return {
        type: Types.GET_SYMBOL_SUCCESS,
        payload: {
            symbol,
        },
    };
}

export function getSymbolFailure(error: AxiosError) {
    return {
        type: Types.GET_SYMBOL_FAILURE,
        payload: { error },
    };
}

export function deleteSymbol(symbolId: string) {
    return {
        type: Types.DELETE_SYMBOL,
        payload: {
            symbolId,
        },
    };
}
export function deleteSymbolSuccess() {
    return {
        type: Types.DELETE_SYMBOL_SUCCESS,
    };
}
export function deleteSymbolFailure(error: AxiosError) {
    return {
        type: Types.DELETE_SYMBOL_FAILURE,
        payload: { error },
    };
}

export function setFilter(key: string, value: string) {
    return {
        type: Types.SET_FILTER,
        payload: { key, value },
    };
}
