import Row from 'antd/es/row';
import React from 'react';
import { Trans } from 'react-i18next';
import { connect, Dispatch } from 'react-redux';
import { FieldArray, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { Choice, VideoData } from 'store/modules/video/@types';
import { makeResponse } from 'store/modules/video/actions';
import { selectData, selectLoading } from 'store/modules/video/selectors';
import { ITranslator, Redux } from 'typings';
import { formatPrice } from 'utils/helpers';
import { getCaseId } from 'view/routes/Video/utils';
import Items from './items';
import { ChoicesTitle, Form, Span, StyledButton, Wrapper } from './styled';

interface Props extends InjectedFormProps, ITranslator {
    data?: VideoData;
    selectedRepairItems?: Choice[];
    makeResponse: typeof makeResponse;
    loading: boolean;
}

const calculatePrice = (data: Choice[]) => data.reduce((acc: any, curr: any) => (curr.approved === true ? acc + curr.price : acc), 0);

const FormWrapper = ({ handleSubmit, data, selectedRepairItems, makeResponse, loading }: Props) => {
    const isDisabled = data.status !== 'PENDING';
    const countCheckedItems = selectedRepairItems && selectedRepairItems.filter((val: Choice) => val.approved === true);
    const isNoCheckedItems = countCheckedItems && countCheckedItems.length > 0;
    const price = selectedRepairItems ? calculatePrice(selectedRepairItems) : 0;

    const handleSubmitForm = (data: any, dispatch: Dispatch) => {
        const caseId = getCaseId();
        return makeResponse(caseId, selectedRepairItems);
    };
    return (
        <Form onSubmit={handleSubmit(handleSubmitForm)}>
            <Wrapper>
                <ChoicesTitle>
                    <Trans i18nKey="containers.EventTimeline.choices" />
                </ChoicesTitle>
                <FieldArray
                    name="repairItems"
                    component={(fields: any) => <Items formValues={fields} data={data} disabled={isDisabled} />}
                />
                <Span>
                    {isNoCheckedItems ? (
                        <Trans i18nKey="routes.Video.question" values={{ price: formatPrice(price) }}>
                            <strong>{price}</strong>
                        </Trans>
                    ) : (
                        <Trans i18nKey="routes.Video.noSelectedItems" />
                    )}
                </Span>
                <Row type="flex" justify="center">
                    <StyledButton type="primary" size="large" htmlType="submit" disabled={isDisabled} loading={loading}>
                        Potvrdit
                    </StyledButton>
                </Row>
                {isDisabled && (
                    <Span style={{ fontWright: 'bold' }}>
                        <Trans i18nKey="routes.Video.response" />
                    </Span>
                )}
            </Wrapper>
        </Form>
    );
};

const selector = formValueSelector('repairSelection');

const ChoicesForm = reduxForm({
    form: 'repairSelection',
})(FormWrapper);

export default connect(
    (state: Redux) => ({
        data: selectData(state),
        selectedRepairItems: selector(state, 'repairItems'),
        loading: selectLoading(state),
    }),
    { makeResponse },
)(ChoicesForm);
