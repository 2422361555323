import { createValidator, maxLength, required } from 'utils/validation';

const AddSymbolFormValidation = createValidator({
    nameCs: [required, maxLength(30)],
    descriptionCs: [required],
    nameEn: [required, maxLength(30)],
    descriptionEn: [required],
    category: [required],
});

export default AddSymbolFormValidation;
