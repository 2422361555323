import createReducer from 'utils/createReducer';

import { SymbolsReducer } from './@types.d';
import { Types } from './actions';
import * as actions from './actions';

export const initialState: SymbolsReducer = {
    list: {
        loading: false,
        data: null,
        limit: 0,
        error: null,
        filter: {
            searchText: '',
            selectedCategory: 'all',
        },
    },
    detail: {
        loading: false,
        data: null,
        error: null,
        sending: false,
    },
};

export default createReducer(initialState, {
    [Types.GET_SYMBOLS]: (state: SymbolsReducer, action: ReturnType<typeof actions.getSymbols>): SymbolsReducer => {
        const { data } = state.list;
        return {
            ...state,
            list: {
                ...state.list,
                loading: true,
                error: null,
                data,
            },
        };
    },
    [Types.GET_SYMBOLS_SUCCESS]: (state: SymbolsReducer, action: ReturnType<typeof actions.getSymbolsSuccess>): SymbolsReducer => {
        const { symbols } = action.payload;
        return {
            ...state,
            list: {
                ...state.list,
                loading: false,
                data: symbols,
            },
        };
    },
    [Types.GET_SYMBOLS_FAILURE]: (state: SymbolsReducer, action: ReturnType<typeof actions.getSymbolsFailure>): SymbolsReducer => {
        const { error } = action.payload;
        return {
            ...state,
            list: {
                ...state.list,
                loading: false,
                error,
            },
        };
    },
    [Types.CREATE_SYMBOL_SUCCESS]: (state: SymbolsReducer, action: ReturnType<typeof actions.createSymbolSuccess>): SymbolsReducer => {
        const { symbol } = action.payload;
        return {
            ...state,
            detail: {
                ...state.detail,
                data: symbol,
            },
        };
    },
    [Types.GET_SYMBOL]: (state: SymbolsReducer, action: ReturnType<typeof actions.getSymbol>): SymbolsReducer => {
        return {
            ...state,
            detail: {
                ...state.detail,
                loading: true,
            },
        };
    },
    [Types.GET_SYMBOL_SUCCESS]: (state: SymbolsReducer, action: ReturnType<typeof actions.getSymbolSuccess>): SymbolsReducer => {
        const { symbol } = action.payload;
        return {
            ...state,
            detail: {
                ...state.detail,
                loading: false,
                data: symbol,
            },
        };
    },
    [Types.GET_SYMBOL_FAILURE]: (state: SymbolsReducer, action: ReturnType<typeof actions.getSymbolFailure>): SymbolsReducer => {
        const { error } = action.payload;
        return {
            ...state,
            detail: {
                ...state.detail,
                loading: false,
                error,
            },
        };
    },
    [Types.DELETE_SYMBOL]: (state: SymbolsReducer, action: ReturnType<typeof actions.deleteSymbol>): SymbolsReducer => {
        return {
            ...state,
            detail: {
                ...state.detail,
                sending: true,
            },
        };
    },
    [Types.DELETE_SYMBOL_SUCCESS]: (state: SymbolsReducer, action: ReturnType<typeof actions.deleteSymbolSuccess>): SymbolsReducer => {
        return {
            ...state,
            detail: {
                ...state.detail,
                sending: false,
            },
        };
    },
    [Types.DELETE_SYMBOL_FAILURE]: (state: SymbolsReducer, action: ReturnType<typeof actions.deleteSymbolFailure>): SymbolsReducer => {
        const { error } = action.payload;
        return {
            ...state,
            detail: {
                ...state.detail,
                sending: false,
                error,
            },
        };
    },
    [Types.SET_FILTER]: (state: SymbolsReducer, action: ReturnType<typeof actions.setFilter>): SymbolsReducer => {
        const { key, value } = action.payload;
        return {
            ...state,
            list: {
                ...state.list,
                filter: {
                    ...state.list.filter,
                    [key]: value,
                },
            },
        };
    },
});
