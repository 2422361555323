import { AxiosError } from 'axios';
import { Branch } from 'store/modules/branches/@types';

export enum Types {
    GET_BRANCHES = 'branches/GET_BRANCHES',
    GET_BRANCHES_SUCCESS = 'branches/GET_BRANCHES_SUCCESS',
    GET_BRANCHES_FAILURE = 'branches/GET_BRANCHES_FAILURE',
    CREATE_BRANCH = 'branches/CREATE_BRANCH',
    UPDATE_BRANCH = 'branches/UPDATE_BRANCH',
    UPDATE_BRANCHES_SUCCESS = 'branches/UPDATE_BRANCHES_SUCCESS',
    UPDATE_BRANCHES_FAILURE = 'branches/UPDATE_BRANCHES_FAILURE',
}

export function getBranches() {
    return {
        type: Types.GET_BRANCHES,
    };
}

export function getBranchesSuccess(branches: Branch[]) {
    return {
        type: Types.GET_BRANCHES_SUCCESS,
        payload: { branches },
    };
}

export function getBranchesFailure(error: AxiosError) {
    return {
        type: Types.GET_BRANCHES_FAILURE,
        payload: { error },
    };
}

export function createBranch(branch: Branch) {
    return {
        type: Types.CREATE_BRANCH,
        payload: { branch },
    };
}

export function updateBranch(branch: Branch) {
    return {
        type: Types.UPDATE_BRANCH,
        payload: { branch },
    };
}

export function updateBranchesSuccess(branches: Branch[]) {
    return {
        type: Types.UPDATE_BRANCHES_SUCCESS,
        payload: { branches },
    };
}

export function updateBranchesFailure(error: AxiosError) {
    return {
        type: Types.UPDATE_BRANCHES_FAILURE,
        payload: { error },
    };
}
