import Button from 'antd/es/button';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import React, { ChangeEvent, Component } from 'react';
import { translate } from 'react-i18next';
import { ITranslator } from 'typings';

import { SearchInput, StyledContainer } from './styled';

interface Props extends ITranslator {
    value?: string | string[];
    name?: string;
    onChange?: (name: string, value: string) => void;
    onReset?: (name: string) => void;
    onSearch?: () => void;
}
class SearchFilter extends Component<Props> {
    handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { onChange, name } = this.props;
        if (onChange) {
            onChange(name, event.target.value);
        }
    };

    handleOnReset = () => {
        const { onReset, name } = this.props;
        if (onReset) {
            onReset(name);
        }
    };

    render() {
        const { value, onSearch, t } = this.props;

        return (
            <StyledContainer>
                <Row type="flex" gutter={10}>
                    <Col>
                        <SearchInput
                            placeholder={t('routes.Tickets.searchFilter.placeholder')}
                            value={value}
                            onChange={this.handleOnChange}
                            onPressEnter={onSearch}
                        />
                    </Col>
                    <Col>
                        <Button type="primary" onClick={onSearch}>
                            {t('routes.Tickets.searchFilter.search')}
                        </Button>
                    </Col>
                    <Col>
                        <Button onClick={this.handleOnReset}>{t('routes.Tickets.searchFilter.reset')}</Button>
                    </Col>
                </Row>
            </StyledContainer>
        );
    }
}

export default translate()(SearchFilter);
