import Row from 'antd/es/row';
import TimePicker from 'antd/es/time-picker';
import { t } from 'i18next';
import moment, { Moment } from 'moment';
import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { _get } from 'utils/get';

interface Props extends WrappedFieldProps {
    format?: string;
    minuteStep?: number;
}

class TimeRange extends React.Component<Props> {
    render() {
        const {
            input: { value, onChange },
            format,
            minuteStep,
        } = this.props;

        return (
            <Row gutter={8}>
                <TimePicker
                    value={_get(value, 'from') ? moment(value.from, format) : null}
                    onChange={(newValue: Moment) => onChange({ to: null, ...value, from: newValue ? newValue.format(format) : null })}
                    format={format}
                    minuteStep={minuteStep}
                    placeholder={t('containers.BranchForm.placeholders.timeFrom')}
                />
                <TimePicker
                    value={_get(value, 'to') ? moment(value.to, format) : null}
                    onChange={(newValue: Moment) => onChange({ from: null, ...value, to: newValue ? newValue.format(format) : null })}
                    format={format}
                    minuteStep={minuteStep}
                    placeholder={t('containers.BranchForm.placeholders.timeTo')}
                />
            </Row>
        );
    }
}

export default TimeRange;
