import filter from 'lodash-es/filter';
import { createSelector } from 'reselect';
import { Redux } from 'typings';

import { UsersReducer } from './@types.d';

const selectUsersSubstate = (state: Redux) => state.users;

export const selectUserLoading = createSelector(selectUsersSubstate, (subState: UsersReducer) => subState.detail.loading);
export const selectUser = createSelector(selectUsersSubstate, (subState: UsersReducer) => subState.detail.data);
export const selectUserError = createSelector(selectUsersSubstate, (subState: UsersReducer) => subState.detail.error);
export const selectUserSending = createSelector(selectUsersSubstate, (subState: UsersReducer) => subState.detail.sending);

export const selectUserGroupsLoading = createSelector(selectUsersSubstate, (subState: UsersReducer) => subState.userGroups.loading);
export const selectUserGroups = createSelector(selectUsersSubstate, (subState: UsersReducer) => subState.userGroups.data);
export const selectUserGroupsError = createSelector(selectUsersSubstate, (subState: UsersReducer) => subState.userGroups.error);

export const selectUsersInGroupsLoading = createSelector(selectUsersSubstate, (subState: UsersReducer) => subState.usersInGroups.loading);
export const selectUsersInGroups = createSelector(selectUsersSubstate, (subState: UsersReducer) => subState.usersInGroups.data);
export const selectUsersInGroupsError = createSelector(selectUsersSubstate, (subState: UsersReducer) => subState.usersInGroups.error);
export const selectUserById = (userId: string) =>
    createSelector(selectUsersSubstate, (subState: UsersReducer) => filter(subState.usersInGroups.data, { Username: userId }));
