import Row from 'antd/es/row';
import FromS3 from 'components/FromS3';
import UploadField from 'components/UploadField';
import { t } from 'i18next';
import React from 'react';
import { translate } from 'react-i18next';
import { compose } from 'redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import SelectField from 'redux-form-antd/es/components/SelectField';
import TextAreaField from 'redux-form-antd/es/components/TextAreaField';
import TextField from 'redux-form-antd/es/components/TextField';
import { SymbolType } from 'store/modules/symbols/@types';
import { CategoryOptions } from 'store/modules/symbols/constants';
import { ITranslator } from 'typings';

import validate from './validate';

interface Props extends InjectedFormProps, ITranslator {
    symbolImage?: string;
    onSubmit: (symbol: SymbolType) => void;
}

const CATEGORY_OPTIONS = [
    {
        label: t('containers.AddSymbol.red'),
        value: CategoryOptions.RED,
    },
    {
        label: t('containers.AddSymbol.orange'),
        value: CategoryOptions.ORANGE,
    },
    {
        label: t('containers.AddSymbol.others'),
        value: CategoryOptions.OTHERS,
    },
];

class AddSymbol extends React.Component<Props> {
    render() {
        const { handleSubmit, t, onSubmit, symbolImage } = this.props;
        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <Field name="nameCs" component={TextField} label={t('containers.AddSymbol.nameCZ')} required />
                <Field name="descriptionCs" component={TextAreaField} label={t('containers.AddSymbol.descriptionCZ')} required />
                <Field name="nameEn" component={TextField} label={t('containers.AddSymbol.nameEN')} required />
                <Field name="descriptionEn" component={TextAreaField} label={t('containers.AddSymbol.descriptionEN')} required />
                <Field
                    name="category"
                    component={SelectField}
                    hasFeedback={false}
                    options={CATEGORY_OPTIONS}
                    label={t('containers.AddSymbol.category')}
                    required
                />
                <Row type="flex" justify="center">
                    <FromS3 name={symbolImage}>
                        {(url: string) => (
                            <Field
                                name="symbolImage"
                                awsFolder="symbols"
                                component={UploadField}
                                placeholder={t('containers.AddSymbol.uploadSymbol')}
                                type="image"
                                url={url}
                            />
                        )}
                    </FromS3>
                </Row>
            </form>
        );
    }
}

export default compose<any>(
    reduxForm({
        form: 'addSymbol',
        validate,
        initialValues: {
            category: CATEGORY_OPTIONS[0].value,
        },
    }),
    translate(),
)(AddSymbol);
