import createReducer from 'utils/createReducer';

import { CarTypeReducer } from './@types';
import { Types } from './actions';
import * as actions from './actions';

export const initialState: CarTypeReducer = {
    loading: false,
    error: null,
    data: {
        allCarTypes: null,
    },
    detail: {
        loading: false,
        error: null,
        data: null,
    },
    filteredData: {
        filteredCarTypes: null,
    },
};

export default createReducer(initialState, {
    [Types.GET_ALL_CAR_TYPES]: (state: CarTypeReducer, action: ReturnType<typeof actions.getAllCarTypes>): CarTypeReducer => {
        return {
            ...state,
            loading: true,
        };
    },
    [Types.GET_ALL_CAR_TYPES_SUCCESS]: (
        state: CarTypeReducer,
        action: ReturnType<typeof actions.getAllCarTypesSuccess>,
    ): CarTypeReducer => {
        return {
            ...state,
            loading: false,
            error: null,
            data: {
                ...state.data,
                allCarTypes: action.payload.carTypes,
            },
        };
    },
    [Types.GET_ALL_CAR_TYPES_FAILURE]: (
        state: CarTypeReducer,
        action: ReturnType<typeof actions.getAllCarTypesFailure>,
    ): CarTypeReducer => {
        return {
            ...state,
            error: action.payload.error,
            loading: false,
        };
    },
    [Types.GET_FILTERED_CAR_TYPES_REQUEST]: (
        state: CarTypeReducer,
        action: ReturnType<typeof actions.getFilteredCarTypes>,
    ): CarTypeReducer => {
        return {
            ...state,
            loading: true,
        };
    },
    [Types.GET_FILTERED_CAR_TYPES_SUCCESS]: (
        state: CarTypeReducer,
        action: ReturnType<typeof actions.getFilteredCarTypesSuccess>,
    ): CarTypeReducer => {
        return {
            ...state,
            loading: false,
            error: null,
            filteredData: {
                filteredCarTypes: action.payload.carTypes,
            },
        };
    },
    [Types.GET_FILTERED_CAR_TYPES_FAILURE]: (
        state: CarTypeReducer,
        action: ReturnType<typeof actions.getFilteredCarTypesFailure>,
    ): CarTypeReducer => {
        return {
            ...state,
            error: action.payload.error,
            loading: false,
        };
    },
    [Types.UPDATE_CAR_TYPE]: (state: CarTypeReducer, action: ReturnType<typeof actions.updateCarType>): CarTypeReducer => {
        return {
            ...state,
            loading: true,
        };
    },
    [Types.UPDATE_CAR_TYPE_SUCCESS]: (state: CarTypeReducer, action: ReturnType<typeof actions.updateCarTypeSuccess>): CarTypeReducer => {
        return {
            ...state,
            loading: false,
            error: null,
        };
    },
    [Types.UPDATE_CAR_TYPE_FAILURE]: (state: CarTypeReducer, action: ReturnType<typeof actions.updateCarTypeFailure>): CarTypeReducer => {
        return {
            ...state,
            loading: false,
            error: action.payload.error,
        };
    },
    [Types.GET_CAR_TYPE]: (state: CarTypeReducer, action: ReturnType<typeof actions.getCarType>): CarTypeReducer => {
        return {
            ...state,
            detail: {
                ...state.detail,
                loading: true,
            },
        };
    },
    [Types.GET_CAR_TYPE_SUCCESS]: (state: CarTypeReducer, action: ReturnType<typeof actions.getCarTypeSuccess>): CarTypeReducer => {
        const { carType } = action.payload;
        return {
            ...state,
            detail: {
                ...state.detail,
                loading: false,
                data: carType,
            },
        };
    },
    [Types.GET_CAR_TYPE_FAILURE]: (state: CarTypeReducer, action: ReturnType<typeof actions.getCarTypeFailure>): CarTypeReducer => {
        const { error } = action.payload;
        return {
            ...state,
            detail: {
                ...state.detail,
                loading: false,
                error,
            },
        };
    },
});
