import Card from 'antd/es/card';
import Col from 'antd/es/col';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
    &:hover {
        transition: 0.3s;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
    }
`;

export const ColStyled = styled(Col)`
    margin-bottom: 16px;
`;

export const DetailLink = styled(Link)`
    display: block;
    margin-top: 32px;
    text-align: right;
`;

export const Error = styled.div`
    text-align: center;
`;
