import React from 'react';
import { translate } from 'react-i18next';
import { Customer } from 'store/modules/customers/@types';
import { ITranslator } from 'typings';

import { CustomerInfoContainer, CustomerInfoEntryContainer } from './styled';

interface CustomerInfoEntryProps {
    label: string;
    value: string;
}

const CustomerInfoEntry = ({ label, value }: CustomerInfoEntryProps) => (
    <CustomerInfoEntryContainer>
        <b>{label}: </b>
        {value}
    </CustomerInfoEntryContainer>
);

type CustomerInfoProps = Pick<Partial<Customer>, 'name' | 'createdAt' | 'branch' | 'email' | 'phone'> & ITranslator;

const CustomerInfo = ({ name, createdAt, email, phone, branch, t }: CustomerInfoProps) => (
    <CustomerInfoContainer>
        {name && <CustomerInfoEntry label={t('routes.CustomerDetail.customerInfo.name')} value={name} />}
        {email && <CustomerInfoEntry label={t('routes.CustomerDetail.customerInfo.email')} value={email} />}
        {phone && <CustomerInfoEntry label={t('routes.CustomerDetail.customerInfo.phone')} value={phone} />}
        {branch && <CustomerInfoEntry label={t('routes.CustomerDetail.customerInfo.branch')} value={branch} />}
        {createdAt && <CustomerInfoEntry label={t('routes.CustomerDetail.customerInfo.createdAt')} value={createdAt.toString()} />}
    </CustomerInfoContainer>
);

export default translate()(CustomerInfo);
