import { AxiosError } from 'axios';
import { createFormAction } from 'redux-form-saga';
import { CarType } from 'store/modules/carTypes/@types';

import { Filter } from '../manuals/@types';

export enum Types {
    // Unique car types
    GET_FILTERED_CAR_TYPES = 'cartypes/GET_FILTERED_CAR_TYPES',
    GET_FILTERED_CAR_TYPES_REQUEST = 'cartypes/GET_FILTERED_CAR_TYPES_REQUEST',
    GET_FILTERED_CAR_TYPES_SUCCESS = 'cartypes/GET_FILTERED_CAR_TYPES_SUCCESS',
    GET_FILTERED_CAR_TYPES_FAILURE = 'cartypes/GET_FILTERED_CAR_TYPES_FAILURE',
    GET_ALL_CAR_TYPES = 'cartypes/GET_ALL_CAR_TYPES',
    GET_ALL_CAR_TYPES_SUCCESS = 'cartypes/GET_ALL_CAR_TYPES_SUCCESS',
    GET_ALL_CAR_TYPES_FAILURE = 'cartypes/GET_ALL_CAR_TYPES_FAILURE',
    GET_CAR_TYPE = 'cartypes/GET_CAR_TYPE',
    GET_CAR_TYPE_SUCCESS = 'cartypes/GET_CAR_TYPE_SUCCESS',
    GET_CAR_TYPE_FAILURE = 'cartypes/GET_CAR_TYPE_FAILURE',
    UPDATE_CAR_TYPE = 'cartypes/UPDATE_CAR_TYPE',
    UPDATE_CAR_TYPE_SUCCESS = 'cartypes/UPDATE_CAR_TYPE_SUCCESS',
    UPDATE_CAR_TYPE_FAILURE = 'cartypes/UPDATE_CAR_TYPE_FAILURE',
}

export const updateCarType = createFormAction(Types.UPDATE_CAR_TYPE);
export const getFilteredCarTypes = createFormAction(Types.GET_FILTERED_CAR_TYPES);

export const refreshFilteredCarTypes = (filters: Filter) => ({
    type: Types.GET_FILTERED_CAR_TYPES_REQUEST,
    payload: filters,
});

export const getFilteredCarTypesSuccess = (carTypes: CarType[]) => ({
    type: Types.GET_FILTERED_CAR_TYPES_SUCCESS,
    payload: { carTypes },
});

export const getFilteredCarTypesFailure = (error: AxiosError) => ({
    type: Types.GET_FILTERED_CAR_TYPES_FAILURE,
    payload: { error },
});

export const getAllCarTypes = () => ({
    type: Types.GET_ALL_CAR_TYPES,
});

export const getAllCarTypesSuccess = (carTypes: CarType[]) => ({
    type: Types.GET_ALL_CAR_TYPES_SUCCESS,
    payload: { carTypes },
});

export const getAllCarTypesFailure = (error: AxiosError) => ({
    type: Types.GET_ALL_CAR_TYPES_FAILURE,
    payload: { error },
});

export const getCarType = (carId: string) => ({
    type: Types.GET_CAR_TYPE,
    payload: { carId },
});

export const getCarTypeSuccess = (carType: CarType) => ({
    type: Types.GET_CAR_TYPE_SUCCESS,
    payload: { carType },
});

export const getCarTypeFailure = (error: string) => ({
    type: Types.GET_CAR_TYPE_FAILURE,
    payload: { error },
});

export const updateCarTypeSuccess = () => ({
    type: Types.UPDATE_CAR_TYPE_SUCCESS,
});

export const updateCarTypeFailure = (error: AxiosError) => ({
    type: Types.UPDATE_CAR_TYPE_FAILURE,
    payload: { error },
});
