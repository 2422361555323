import PageHeader from 'ant-design-pro/lib/PageHeader';
import Button from 'antd/es/button';
import Modal from 'antd/es/modal';
import * as React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { match, withRouter } from 'react-router';
import { compose } from 'redux';
import { isSubmitting, submit } from 'redux-form';
import { Contact } from 'store/modules/contacts/@types';
import { deleteContact, getContact, updateContact } from 'store/modules/contacts/actions';
import { selectContact, selectContactError, selectContactLoading } from 'store/modules/contacts/selectors';
import { ITranslator, Redux, SelectorType } from 'typings';
import CenteredSpin from 'view/components/CenteredSpin';
import ContactForm from 'view/containers/Contacts/ContactForm';
import { Center, StyledCard } from './styled';

const confirm = Modal.confirm;

interface Props extends ITranslator {
    getContact?: typeof getContact;
    deleteContact?: typeof deleteContact;
    updateContact?: typeof updateContact;
    loading?: boolean;
    error?: SelectorType<typeof selectContactError>;
    match?: match<{ contactId: string }>;
    contact?: Contact;
    submitting?: SelectorType<typeof isSubmitting>;
    submit?: typeof submit;
}

class EditContact extends React.Component<Props> {
    componentDidMount() {
        const { getContact, match } = this.props;
        getContact(match.params.contactId);
    }

    removeContact = () => {
        const { deleteContact, t, match } = this.props;
        confirm({
            title: t('routes.EditContact.deleteWarning'),
            okText: t('routes.EditContact.okText'),
            okType: 'danger',
            cancelText: t('routes.EditContact.cancelText'),
            onOk() {
                deleteContact(match.params.contactId);
            },
        });
    };

    render() {
        const { loading, t, contact, error, submitting, submit } = this.props;

        if (!contact || loading) {
            return <CenteredSpin />;
        }

        return (
            <>
                <PageHeader
                    itemRender={null}
                    title={t('routes.EditContact.title')}
                    action={
                        <>
                            <Button type="primary" onClick={() => submit('contactForm')} loading={submitting}>
                                {t('containers.ContactForm.submit')}
                            </Button>
                            <Button type="danger" onClick={this.removeContact} loading={submitting}>
                                {t('routes.EditContact.deleteButton')}
                            </Button>
                        </>
                    }
                />
                <StyledCard bordered={false} loading={loading}>
                    {error && <Center>{t('routes.EditContact.error')}</Center>}
                    {!loading && !error && <ContactForm onSubmit={updateContact} initialValues={contact} imageName={contact.image} />}
                </StyledCard>
            </>
        );
    }
}

export default compose<any>(
    connect(
        (state: Redux) => {
            return {
                submitting: isSubmitting('contactForm')(state),
                contact: selectContact(state),
                loading: selectContactLoading(state),
                error: selectContactError(state),
            };
        },
        { getContact, deleteContact, submit },
    ),
    translate(),
    withRouter,
)(EditContact);
