import AddBranch from 'view/routes/AddBranch';
import AddContact from 'view/routes/AddContact';
import AddManual from 'view/routes/AddManual';
import AddSymbol from 'view/routes/AddSymbol';
import AddUser from 'view/routes/AddUser';
import Branch from 'view/routes/Branch';
import Branches from 'view/routes/Branches';
import CarTypes from 'view/routes/CarTypes';
import Contacts from 'view/routes/Contacts';
import CustomerDetail from 'view/routes/CustomerDetail';
import Customers from 'view/routes/Customers';
import EditContact from 'view/routes/EditContact';
import Files from 'view/routes/Files';
import ForgottenPassword from 'view/routes/ForgottenPassword';
import ForgottenPasswordConfirm from 'view/routes/ForgottenPasswordConfirm';
import Homepage from 'view/routes/Homepage';
import Manual from 'view/routes/Manual';
import Manuals from 'view/routes/Manuals';
import PasswordChange from 'view/routes/PasswordChange';
import SignIn from 'view/routes/SignIn';
import Symbol from 'view/routes/Symbol';
import Symbols from 'view/routes/Symbols';
import Ticket from 'view/routes/Ticket';
import Tickets from 'view/routes/Tickets';
import User from 'view/routes/User';
import Users from 'view/routes/Users';
import Video from 'view/routes/Video';

export enum Pages {
    INTRODUCTION = 'introduction',
    PHONE_PAGE = 'phonePage',
}

export enum Role {
    BACKOFFICE = 'backoffice',
    ADMIN = 'admin',
    TECH = 'tech',
    APP = 'app',
}

export enum TypeComponent {
    PRIVATE = 'PRIVATE',
    UNSIGNED = 'UNSIGNED',
    PUBLIC = 'PUBLIC',
}

export const Routes = {
    SIGNIN: {
        path: '/sign-in',
        component: SignIn,
        type: TypeComponent['UNSIGNED'],
    },
    TICKETS: {
        path: '/tickets',
        type: TypeComponent['PRIVATE'],
        isExact: true,
        component: Tickets,
        sideMenu: {
            icon: 'folder-open',
            label: 'tickets',
        },
    },
    MANUALS: {
        path: '/manuals',
        type: TypeComponent['PRIVATE'],
        isExact: true,
        component: Manuals,
        sideMenu: {
            icon: 'read',
            label: 'manuals',
        },
    },
    SYMBOLS: {
        path: '/symbols',
        type: TypeComponent['PRIVATE'],
        isExact: true,
        component: Symbols,
        sideMenu: {
            icon: 'alert',
            label: 'symbols',
        },
    },
    CUSTOMERS: {
        path: '/customers',
        type: TypeComponent['PRIVATE'],
        isExact: true,
        component: Customers,
        sideMenu: {
            icon: 'shopping',

            label: 'customers',
        },
    },
    CUSTOMER_DETAIL: {
        path: '/customers/:userId',
        type: TypeComponent['PRIVATE'],
        isExact: true,
        component: CustomerDetail,
    },
    HOME: {
        path: '/',
        type: TypeComponent['PRIVATE'],
        isExact: true,
        component: Homepage,
        /*         sideMenu: {
    icon: 'pie-chart',
    label: 'homepage',
}, */
    },
    BRANCHES: {
        path: '/branches',
        type: TypeComponent['PRIVATE'],
        isExact: true,
        component: Branches,
        sideMenu: {
            icon: 'branches',
            label: 'branches',
        },
    },
    CONTACTS: {
        path: '/contacts',
        type: TypeComponent['PRIVATE'],
        isExact: true,
        component: Contacts,
        sideMenu: {
            icon: 'team',
            label: 'contacts',
        },
    },
    USERS: {
        path: '/users',
        type: TypeComponent['PRIVATE'],
        isExact: true,
        component: Users,
        sideMenu: {
            icon: 'desktop',
            label: 'users',
        },
        roles: {
            whitelist: [Role.ADMIN],
        },
    },
    CAR_TYPES: {
        path: '/car-types',
        type: TypeComponent['PRIVATE'],
        isExact: true,
        component: CarTypes,
        sideMenu: {
            icon: 'car',
            label: 'carTypes',
        },
    },
    ADD_USER: {
        path: '/users/add',
        type: TypeComponent['PRIVATE'],
        isExact: true,
        component: AddUser,
        roles: {
            whitelist: [Role.ADMIN],
        },
    },
    USER_DETAIL: {
        path: '/users/:userId',
        type: TypeComponent['PRIVATE'],
        isExact: true,
        component: User,
        roles: {
            whitelist: [Role.ADMIN],
        },
    },
    ADD_SYMBOL: {
        path: '/symbols/add',
        type: TypeComponent['PRIVATE'],
        isExact: true,
        component: AddSymbol,
    },
    SYMBOL_DETAIL: {
        path: '/symbols/:symbolId',
        type: TypeComponent['PRIVATE'],
        isExact: true,
        component: Symbol,
    },
    FILES: {
        path: '/files',
        type: TypeComponent['PRIVATE'],
        isExact: true,
        component: Files,
        // sideMenu: {
        //     icon: 'file',
        //     label: 'files',
        // },
    },
    ADD_BRANCH: {
        path: '/branches/add',
        type: TypeComponent['PRIVATE'],
        isExact: true,
        component: AddBranch,
    },
    BRANCH_DETAIL: {
        path: '/branches/:branchId',
        type: TypeComponent['PRIVATE'],
        isExact: true,
        component: Branch,
    },
    ADD_MANUAL: {
        path: '/manuals/add',
        type: TypeComponent['PRIVATE'],
        isExact: true,
        component: AddManual,
    },
    MANUAL_DETAIL: {
        path: '/manuals/:manualId',
        type: TypeComponent['PRIVATE'],
        isExact: true,
        component: Manual,
    },
    TICKET_DETAIL: {
        path: '/tickets/:caseId',
        type: TypeComponent['PRIVATE'],
        isExact: true,
        component: Ticket,
    },
    ADD_CONTACT: {
        path: '/contacts/add-contact',
        type: TypeComponent['PRIVATE'],
        isExact: true,
        component: AddContact,
    },
    EDIT_CONTACT: {
        path: '/contacts/:contactId',
        type: TypeComponent['PRIVATE'],
        isExact: true,
        component: EditContact,
    },
    PASSWORD_CHANGE: {
        path: '/password-change',
        type: TypeComponent['UNSIGNED'],
        isExact: true,
        component: PasswordChange,
    },
    FORGOTTEN_PASSWORD: {
        path: '/forgotten-password',
        type: TypeComponent['UNSIGNED'],
        isExact: true,
        component: ForgottenPassword,
    },
    FORGOTTEN_PASSWORD_CONFIRM: {
        path: '/forgotten-password-confirm',
        type: TypeComponent['UNSIGNED'],
        isExact: true,
        component: ForgottenPasswordConfirm,
    },
    VIDEO: {
        path: '/video',
        component: Video,
        type: TypeComponent['PUBLIC'],
    },
};
