import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

interface Props {
    rehydrated?: boolean;
    children: ReactNode;
}

interface State {}

class Loader extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        const { rehydrated, children } = this.props;
        return rehydrated ? children : <div>loading</div>;
    }
}
export default compose<any>(
    connect(
        (state: any) => ({
            rehydrated: state._persist.rehydrated,
        }),
        null,
    ),
)(Loader);
