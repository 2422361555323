import PageHeader from 'ant-design-pro/lib/PageHeader';
import Card from 'antd/es/card';
import React from 'react';
import { translate } from 'react-i18next';
import { ITranslator, Redux, SelectorType } from 'typings';
import CustomersList from 'view/containers/customersList';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectCustomersRawCustomer } from 'store/modules/customers/selectors';

interface Props extends ITranslator {
    rawCustomers?: SelectorType<typeof selectCustomersRawCustomer>;
}

class Customers extends React.Component<Props> {
    render() {
        const { t, rawCustomers } = this.props;

        return (
            <>
                <PageHeader itemRender={null} title={`${t('routes.Customers.title')} (${rawCustomers?.total ? rawCustomers.total : 0})`} />
                <Card bordered={false}>
                    <CustomersList />
                </Card>
            </>
        );
    }
}

export default compose<any>(
    translate(),
    connect(
        (state: Redux) => ({
            rawCustomers: selectCustomersRawCustomer(state),
        }),
        null,
    ),
)(Customers);
