import { AnyAction } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { Redux } from 'typings';
import { Types } from '../customers/actions';

const reducer = formReducer.plugin({
    documentForm: (state: Redux, action: AnyAction) => {
        switch (action.type) {
            case Types.CREATE_DOCUMENT_SUCCESS:
                return undefined;
            default:
                return state;
        }
    },
});

export default reducer;
