export enum CustomerDocumentType {
    INVOICE = 'invoice',
    OFFER = 'offer',
    PROTOCOL = 'protocol',
    ORDER = 'order',
    OTHER = 'other',
}

export const allCustomerDocumentTypes = [
    CustomerDocumentType.INVOICE,
    CustomerDocumentType.OFFER,
    CustomerDocumentType.ORDER,
    CustomerDocumentType.PROTOCOL,
    CustomerDocumentType.OTHER,
];
