import Button from 'antd/es/button';
import Card from 'antd/es/card';
import Row from 'antd/es/row';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
    margin-bottom: 1rem;
`;

export const StyledButton = styled(Button)`
    margin: 0.3rem;
`;

export const StyledRow = styled(Row)`
    @media screen and (max-width: 1415px) {
        .ant-row-flex {
            display: flex;
            flex-direction: column;
            .ant-btn {
                margin-bottom: 15px;
            }
        }
        .ant-btn-primary {
            margin-left: 32px;
        }
    }
`;
