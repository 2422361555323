import Button from 'antd/es/button';
import Icon from 'antd/es/icon';
import Upload from 'antd/es/upload';
import { UploadFile, UploadListType } from 'antd/es/upload/interface';
import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import { WrappedFieldProps } from 'redux-form';
import { ITranslator } from 'typings';

interface Props extends WrappedFieldProps, ITranslator {
    action: string;
    label?: string;
    url?: string;
    accept?: string;
    type?: string;
    awsFolder?: string;
    placeholder?: string;
}

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

const getListType = (type: string): UploadListType => {
    return type === 'image' ? 'picture-card' : 'text';
};

const createFile = (name: string, url: string) => ({
    uid: 'uuid',
    name,
    status: 'done',
    size: -1,
    type: 'type',
    url,
});

class UploadField extends PureComponent<Props> {
    handleOnFileSelect = (file: UploadFile) => {
        const {
            input: { onChange },
            awsFolder,
        } = this.props;

        getBase64(file, (fileUrl: string) => {
            file.url = fileUrl;
            onChange({ file, name: `${awsFolder}/${file.name}` });
        });
        return false;
    };

    handleOnRemove = () => {
        const {
            input: { onChange },
        } = this.props;
        onChange(null);
        this.setState({ file: null });
    };

    renderUploadButton = () => {
        const { type, label, input, t, placeholder } = this.props;

        if (type === 'button') {
            return (
                <Button>
                    <Icon type="upload" /> {label}
                </Button>
            );
        }

        if (!input.value) {
            return (
                <div>
                    <Icon type="plus" />
                    <div className="ant-upload-text">{placeholder || t('components.UploadInput.upload')}</div>
                </div>
            );
        }
        return null;
    };

    render() {
        const { input, meta, label, type = 'button', url, ...rest } = this.props;
        const { name, value } = input;
        const fileList = value.file ? [value.file] : value ? [createFile(value, url)] : [];

        const props = {
            className: 'ant-row ant-form-item',
            beforeUpload: this.handleOnFileSelect,
            fileList,
            name,
            onRemove: this.handleOnRemove,
            listType: getListType(type),
            ...rest,
        };

        return <Upload {...props}>{this.renderUploadButton()}</Upload>;
    }
}

export default translate()(UploadField);
