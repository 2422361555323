import CenteredSpin from 'components/CenteredSpin';
import SymbolCard from 'components/SymbolCard';
import * as React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'redux';
import { getSymbols } from 'store/modules/symbols/actions';
import { selectSymbols, selectSymbolsError, selectSymbolsLoading } from 'store/modules/symbols/selectors';
import { Routes } from 'store/modules/ui/constants';
import { ITranslator, Redux, SelectorType } from 'typings';
import { Wrapper } from './styled';

interface Props extends ITranslator {
    getSymbols?: typeof getSymbols;
    loading?: SelectorType<typeof selectSymbolsLoading>;
    symbols?: SelectorType<typeof selectSymbols>;
    error?: SelectorType<typeof selectSymbolsError>;
    push?: typeof push;
}

class Symbols extends React.Component<Props> {
    componentDidMount() {
        const { getSymbols } = this.props;
        getSymbols();
    }

    renderSymbolsList = () => {
        const { push, symbols } = this.props;

        return symbols.map((symbol: Props['symbols'][0]) => {
            const { symbolId, ...rest } = symbol;
            return <SymbolCard key={symbolId} onOpenSymbolDetail={() => push(`${Routes.SYMBOLS.path}/${symbolId}`)} {...rest} />;
        });
    };

    render() {
        const { symbols } = this.props;
        return <>{symbols ? <Wrapper>{this.renderSymbolsList()}</Wrapper> : <CenteredSpin />}</>;
    }
}

export default compose<any>(
    connect(
        (state: Redux) => ({
            loading: selectSymbolsLoading(state),
            symbols: selectSymbols(state),
            error: selectSymbolsError(state),
        }),
        { getSymbols, push },
    ),
    translate(),
)(Symbols);
