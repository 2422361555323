import find from 'lodash-es/find';
import { createSelector } from 'reselect';
import { Branch, BranchesReducer } from 'store/modules/branches/@types';
import { Redux } from 'typings';

const selectBranchesState = (state: Redux) => state.branches;

export const selectBranches = createSelector(selectBranchesState, (subState: BranchesReducer) => subState.data);
export const selectBranchesLoading = createSelector(selectBranchesState, (subState: BranchesReducer) => subState.loading);
export const selectBranchesSending = createSelector(selectBranchesState, (subState: BranchesReducer) => subState.sending);
export const selectBranchesError = createSelector(selectBranchesState, (subState: BranchesReducer) => subState.error);

export const selectBranchById = function(branchId: Branch['id']) {
    return createSelector(selectBranches, (branchesSelector: Branch[]) => {
        const selectedBranch = find(branchesSelector, (branch: Branch) => {
            return branchId === branch.id;
        });

        return selectedBranch || null;
    });
};
