import { Table } from 'antd';
import styled from 'styled-components';
import { ThemeProps } from 'stylesheets/GlobalTheme';
import { LinkOutlined } from '@ant-design/icons';

export const StyledTable = styled(Table)`
    .ant-table-tbody > tr:hover > td {
        background: ${(props: ThemeProps) => props.theme.colors.white};
    }
    .ant-table-row:hover {
        cursor: default;
    }
`;

export const StyledLinkOutlined = styled(LinkOutlined)`
    vertical-align: baseline;
    color: rgba(0, 0, 0, 0.45);
`;
