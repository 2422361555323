import { Ticket } from 'store/modules/tickets/@types';
import createReducer from 'utils/createReducer';

import { ListReducer, ResponsePagination } from './@types';
import { Types } from './actions';
import * as actions from './actions';

export const initialState: ListReducer = {
    data: null,
    error: null,
    loading: false,
    filter: {},
    pagination: {
        pageSize: 10,
        total: null,
        current: 1,
    },
};

export default createReducer(initialState, {
    [Types.GET_TICKETS]: (state: ListReducer, action: ReturnType<typeof actions.getTickets>): ListReducer => {
        return {
            ...state,
            loading: true,
        };
    },
    [Types.GET_TICKETS_SUCCESS]: (state: ListReducer, action: ReturnType<typeof actions.getTicketsSuccess>): ListReducer => {
        const data: Ticket[] = action.payload.tickets;
        const pagination: ResponsePagination = action.payload.pagination;
        return {
            ...state,
            data,
            loading: false,
            error: null,
            pagination: {
                total: pagination.total,
                pageSize: pagination.limit,
                current: pagination.page,
            },
        };
    },
    [Types.GET_TICKETS_FAILURE]: (state: ListReducer, action: ReturnType<typeof actions.getTicketsFailure>): ListReducer => {
        return {
            ...state,
            error: action.payload.error,
            loading: false,
        };
    },
    [Types.SET_FILTER]: (state: ListReducer, action: ReturnType<typeof actions.setFilter>): ListReducer => {
        const { key, value } = action.payload;
        return {
            ...state,
            filter: {
                ...state.filter,
                [key]: value,
            },
        };
    },
    [Types.SET_FILTERS]: (state: ListReducer, action: ReturnType<typeof actions.setFilters>): ListReducer => {
        const { filters } = action.payload;
        return {
            ...state,
            filter: {
                ...state.filter,
                ...filters,
            },
        };
    },
});
