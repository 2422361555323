import { Field } from 'redux-form';
import styled from 'styled-components';

export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const Title = styled.div`
    text-align: center;
    font-size: 14px;
    margin-bottom: 12px;
    font-weight: 500;
`;

export const TextArea = styled(Field)`
    min-width: 200px;
`;
