import Row from 'antd/es/row';
import Spin from 'antd/es/spin';
import Timeline from 'antd/es/timeline';
import orderBy from 'lodash-es/orderBy';
import reverse from 'lodash-es/reverse';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Events } from 'store/modules/appInfo/constants';
import { Event } from 'store/modules/tickets/@types';
import { ITranslator } from 'typings';
import { _get } from 'utils/get';
import EventComponent from './Event';
import Scheduled from './Event/Scheduled';
import { PrimaryIcon, StyledCard, StyledTimeline } from './Event/styled';
import iconsList from './Event/utils/icons';
import eventsList from './events';

interface Props extends ITranslator {
    events?: Event[];
    loading?: boolean;
}

const eventsMapper = (events: Event[]) => (event: Event, index: number) => {
    const props = {
        key: index,
        events,
        ...event,
    };

    const isScheduled = (event: Event) => event.eventName === Events.Notification.SCHEDULED;
    const idMatches = (id: string) => event.data.notificationId === id;
    const findServiceDate = (event: Event) => events.find((e: Event) => isScheduled(e) && idMatches(event.data.notificationId));

    if (isScheduled(event)) {
        return (
            <Timeline.Item key={index} dot={<PrimaryIcon type="hourglass" theme="outlined" />}>
                <Scheduled {...props} />
            </Timeline.Item>
        );
    }

    if (event.eventName === Events.Notification.RESPONSE) {
        event.data = { ...event.data, serviceDate: _get(findServiceDate(event), 'data', 'data', 'date') };
    }

    // Render event dynamicaly based on events list
    const eventData = eventsList[event.eventName];
    if (!eventData) {
        return null;
    }

    const {
        icon: { name, theme, color },
        title,
    } = eventData;

    const Icon = iconsList[color];
    return (
        <Timeline.Item key={index} dot={<Icon type={name} theme={theme} />}>
            <EventComponent title={title} timestamp={event.timestamp} data={event.data} eventName={event.eventName} />
        </Timeline.Item>
    );
};

const date = (event: Event) => new Date(event.timestamp);

class EventTimeline extends Component<Props> {
    render() {
        const { events, loading, t } = this.props;

        if (!events || events.length === 0) {
            return (
                <StyledCard>
                    <Row justify="center" type="flex">
                        {t('containers.EventTimeline.noEvents')}
                    </Row>
                </StyledCard>
            );
        }

        const renderEvents = eventsMapper(events);
        return (
            <>
                <h2>{t('containers.EventTimeline.title')}</h2>
                {loading && (
                    <Row justify="center" type="flex">
                        <Spin />
                    </Row>
                )}
                <StyledTimeline loading={loading}>
                    <Timeline mode="alternate">{reverse(orderBy(events, date)).map(renderEvents)}</Timeline>
                </StyledTimeline>
            </>
        );
    }
}

export default translate()(EventTimeline);
