import CenteredSpin from 'components/CenteredSpin';
import * as React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { CarType } from 'store/modules/carTypes/@types';
import { selectError, selectLoading } from 'store/modules/carTypes/selectors';
import shortenCarTypes from 'store/modules/carTypes/utils/loadMore';
import { ITranslator, Redux, SelectorType } from 'typings';
import CarCard from 'view/components/CarCard';
import CarCardSelected from 'view/components/CarCardSelected';

import { ButtonWrapper, LoadMore, Wrapper } from './styled';

interface Props extends ITranslator {
    loading?: SelectorType<typeof selectLoading>;
    error?: SelectorType<typeof selectError>;
    carTypes: CarType[];
}

interface State {
    selected: number;
    cars: CarType[];
    carsLimit: number;
}

const DEFAULT_CAR_LIMIT = 25;

class CarTypesList extends React.Component<Props, State> {
    state = {
        selected: null,
        cars: null,
        carsLimit: DEFAULT_CAR_LIMIT,
    };

    componentDidMount() {
        const { carTypes } = this.props;
        const { carsLimit } = this.state;

        this.setState({ cars: shortenCarTypes(carTypes, carsLimit) });
    }

    componentWillReceiveProps(previousProps: Props) {
        const { carTypes } = this.props;

        if (previousProps.carTypes !== carTypes) {
            this.setState({ cars: shortenCarTypes(previousProps.carTypes, DEFAULT_CAR_LIMIT) });
            this.setState({ carsLimit: DEFAULT_CAR_LIMIT });
        }
    }

    handleSelect = (index: number) => {
        this.setState({ selected: index });
    };

    handleDeselect = () => {
        this.setState({ selected: null });
    };

    handleLoadMore = (limit: number) => {
        const { carTypes } = this.props;

        this.setState({ carsLimit: limit + 10 });
        this.setState({ cars: shortenCarTypes(carTypes, limit + 10) });
    };

    renderCarsList() {
        const { selected, cars } = this.state;

        if (cars === null) {
            return null;
        }
        return cars.map((carType: CarType, index: number) =>
            index === selected ? (
                <CarCardSelected
                    key={index}
                    onDeselect={this.handleDeselect}
                    id={carType.id}
                    carTypeImage={carType.image}
                    model={carType.model}
                    year={carType.year}
                    initialValues={{ carTypeImage: carType.image }}
                />
            ) : (
                <CarCard
                    key={index}
                    onSelect={() => this.handleSelect(index)}
                    carTypeImage={carType.image}
                    model={carType.model}
                    year={carType.year}
                />
            ),
        );
    }

    render() {
        const { carTypes, loading, t } = this.props;
        const { carsLimit, cars } = this.state;
        const shouldRenderButton = carTypes !== null && cars !== null && cars.length !== carTypes.length;
        if ((!carTypes && !cars) || loading) {
            return <CenteredSpin />;
        }
        return (
            <>
                <Wrapper>{this.renderCarsList()}</Wrapper>
                {shouldRenderButton && (
                    <ButtonWrapper>
                        <LoadMore type="dashed" onClick={() => this.handleLoadMore(carsLimit)}>
                            {t('routes.CarTypes.loadMore')}
                        </LoadMore>
                    </ButtonWrapper>
                )}
            </>
        );
    }
}

export default compose<any>(
    connect(
        (state: Redux) => ({
            loading: selectLoading(state),
            error: selectError(state),
        }),
        {},
    ),
    translate(),
)(CarTypesList);
