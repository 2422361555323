import { t } from 'i18next';
import moment from 'moment';
import { push } from 'react-router-redux';
import { SagaIterator } from 'redux-saga';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { Events } from 'store/modules/appInfo/constants';
import { Routes } from 'store/modules/ui/constants';
import { openNotification } from 'utils/notifications';
import { getToken } from 'utils/withToken';

import { NotificationType } from '../constants';
import * as actions from './actions';
import fetcher from './requests';
import { selectCaseId } from './selectors';

export function* getTicket() {
    yield takeLatest(actions.Types.GET_TICKET, function* handle(action: ReturnType<typeof actions.getTicket>) {
        try {
            const { caseId } = action.payload;
            const requests = fetcher(yield getToken());
            const ticket = yield requests.getTicket(caseId);
            yield put(actions.getTicketSuccess(ticket));
        } catch (err) {
            yield put(actions.getTicketFailure(err));
            yield put(push(Routes.TICKET_DETAIL.path));
            if (err.response.status === 404) {
                yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.ticketNotFound'));
            } else {
                yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
            }
        }
    });
}

export function* closeTicket() {
    yield takeLatest(actions.Types.CLOSE_TICKET, function* handle(action: ReturnType<typeof actions.closeTicket>) {
        try {
            const { caseId } = action.payload;
            const requests = fetcher(yield getToken());
            yield requests.closeTicket(caseId);
            const ticket = yield requests.getTicket(caseId);
            yield put(actions.closeTicketSuccess(ticket));
            yield call(openNotification, 'success', t('apiRequest.success.title'), t('apiRequest.success.ticketClosed'));
        } catch (err) {
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
            yield put(actions.closeTicketFailure(err));
        }
    });
}

export function* updateTicket() {
    yield takeLatest(actions.updateTicket.REQUEST, function* handle(action: ReturnType<typeof actions.updateTicket>) {
        try {
            const { eventName, note } = action.payload;
            const caseId = yield select(selectCaseId);
            const requests = fetcher(yield getToken());

            const request = {
                [Events.Reception.CONTACT_SUCCEEDED]: requests.contactSuccess,
                [Events.Reception.CONTACT_FAILED]: requests.contactFail,
            };

            const ticket = yield request[eventName]({ note }, caseId);
            yield put(actions.updateTicketSuccess(ticket));
            yield call(openNotification, 'success', t('apiRequest.success.title'), t('apiRequest.success.eventAdded'));
        } catch (err) {
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
            yield put(actions.updateTicketFailure(err));
        }
    });
}

export function* scheduleNotification() {
    yield takeLatest(actions.scheduleNotification.REQUEST, function* handle(action: ReturnType<typeof actions.scheduleNotification>) {
        try {
            const { visitDate, visitTime } = action.payload;
            const caseId = yield select(selectCaseId);
            const requests = fetcher(yield getToken());

            const date = moment(`${visitDate} ${visitTime}`, 'DD/MM/YYYY HH:mm').toISOString(true);
            const ticket = yield requests.scheduleNotification({ date, type: NotificationType.SERVICE_REMINDER_CONFIRM }, caseId);
            yield put(actions.scheduleNotificationSuccess(ticket));
            yield call(openNotification, 'success', t('apiRequest.success.title'), t('apiRequest.success.notificationScheduled'));
        } catch (err) {
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
            yield put(actions.scheduleNotificationFailure(err));
        }
    });
}

export function* cancelNotification() {
    yield takeLatest(actions.Types.CANCEL_NOTIFICATION, function* handle(action: ReturnType<typeof actions.cancelNotification>) {
        try {
            const { caseId, eventId } = action.payload;
            const requests = fetcher(yield getToken());

            const ticket = yield requests.cancelNotification(caseId, eventId);
            yield put(actions.cancelNotificationSuccess(ticket));
            yield call(openNotification, 'success', t('apiRequest.success.title'), t('apiRequest.success.notificationCanceled'));
        } catch (err) {
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
            yield put(actions.cancelNotificationFailure(err));
        }
    });
}

export default function* ticketSaga(): SagaIterator {
    yield fork(getTicket);
    yield fork(closeTicket);
    yield fork(updateTicket);
    yield fork(scheduleNotification);
    yield fork(cancelNotification);
}
