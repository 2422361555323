import createReducer from 'utils/createReducer';

import { Customer, CustomersReducer, UserDocument } from './@types.d';
import { Types } from './actions';
import * as actions from './actions';

const sortByDate = (customers: Customer[]) =>
    customers.sort((a: Customer, b: Customer) => (new Date(b.createdAt) >= new Date(a.createdAt) ? 1 : -1));

export const initialState: CustomersReducer = {
    loading: false,
    data: {
        docs: null,
        total: null,
        pages: null,
        limit: null,
        page: null,
        filter: null,
    },
    error: null,
    detail: {
        customer: null,
        loading: false,
        docs: [],
        error: null,
        uploading: false,
    },
};

export default createReducer(initialState, {
    [Types.GET_CUSTOMERS]: (state: CustomersReducer): CustomersReducer => {
        return {
            ...state,
            loading: true,
        };
    },
    [Types.GET_CUSTOMERS_SUCCESS]: (state: CustomersReducer, action: ReturnType<typeof actions.getCustomersSuccess>): CustomersReducer => {
        const { data } = action.payload;
        return {
            ...state,
            data: {
                ...data,
                filter: state.data.filter,
            },
            loading: false,
        };
    },
    [Types.GET_CUSTOMERS_FAILURE]: (state: CustomersReducer, action: ReturnType<typeof actions.getCustomersFailure>): CustomersReducer => {
        const { error } = action.payload;
        return {
            ...state,
            data: {
                ...state.data,
                filter: {},
            },
            error,
            loading: false,
        };
    },
    [Types.SET_FILTER]: (state: CustomersReducer, action: ReturnType<typeof actions.setFilter>): CustomersReducer => {
        const { key, value } = action.payload;
        return {
            ...state,
            data: {
                ...state.data,
                filter: {
                    ...state.data.filter,
                    [key]: value,
                },
            },
        };
    },
    [Types.SET_FILTERS]: (state: CustomersReducer, action: ReturnType<typeof actions.setFilters>): CustomersReducer => {
        const { filters } = action.payload;
        return {
            ...state,
            data: {
                ...state.data,
                filter: {
                    ...state.data.filter,
                    ...filters,
                },
            },
        };
    },
    [Types.UNASSIGNED_CAR]: (state: CustomersReducer, action: ReturnType<typeof actions.unassignedCar>): CustomersReducer => {
        const { userId, vin } = action.payload;
        const currentCustomer = state.data.docs.find((customer: Customer) => customer.userId === userId);
        const allCustomers = state.data.docs.filter((customer: Customer) => customer.userId !== userId);

        const newCustomerVin: Customer = {
            ...currentCustomer,
            cars: [...currentCustomer.cars.filter((cars: string) => cars !== vin)],
        };

        const docs = sortByDate([...allCustomers, newCustomerVin]);

        return {
            ...state,
            data: {
                ...state.data,
                docs,
            },
        };
    },
    [Types.UNASSIGNED_CAR_SUCCESS]: (
        state: CustomersReducer,
        action: ReturnType<typeof actions.unassignedCarSuccess>,
    ): CustomersReducer => {
        return {
            ...state,
            loading: false,
        };
    },
    [Types.GET_CUSTOMERS_FAILURE]: (state: CustomersReducer, action: ReturnType<typeof actions.unassignedCarFailure>): CustomersReducer => {
        const { error } = action.payload;
        return {
            ...state,
            error,
        };
    },
    [Types.GET_CUSTOMER]: (state: CustomersReducer): CustomersReducer => {
        return {
            ...state,
            detail: {
                ...state.detail,
                loading: true,
            },
        };
    },
    [Types.GET_CUSTOMER_SUCCESS]: (state: CustomersReducer, action: ReturnType<typeof actions.getCustomerSuccess>): CustomersReducer => {
        return {
            ...state,
            detail: {
                ...state.detail,
                loading: false,
                customer: action.payload.customer,
                docs: action.payload.docs,
            },
        };
    },
    [Types.GET_CUSTOMER_FAILURE]: (state: CustomersReducer, action: ReturnType<typeof actions.getCustomerFailure>): CustomersReducer => {
        return {
            ...state,
            detail: {
                ...state.detail,
                loading: false,
                error: action.payload.error,
            },
        };
    },
    [Types.CREATE_DOCUMENT]: (state: CustomersReducer): CustomersReducer => ({
        ...state,
        detail: {
            ...state.detail,
            uploading: true,
        },
    }),
    [Types.CREATE_DOCUMENT_SUCCESS]: (
        state: CustomersReducer,
        action: ReturnType<typeof actions.createDocumentSuccess>,
    ): CustomersReducer => ({
        ...state,
        detail: {
            ...state.detail,
            docs: [...state.detail.docs, action.payload.document],
            uploading: false,
        },
    }),
    [Types.CREATE_DOCUMENT_FAILURE]: (state: CustomersReducer): CustomersReducer => ({
        ...state,
        detail: {
            ...state.detail,
            uploading: false,
        },
    }),
    [Types.DELETE_DOCUMENT_SUCCESS]: (state: CustomersReducer, action: ReturnType<typeof actions.deleteDocumentSuccess>) => ({
        ...state,
        detail: {
            ...state.detail,
            docs: state.detail.docs.filter(({ docId }: UserDocument) => docId !== action.payload.docId),
        },
    }),
});
