import Card from 'antd/es/card';
import Icon from 'antd/es/icon';
import { Checkbox, Button } from 'antd';
import styled from 'styled-components';
import theme, { ThemeProps } from 'stylesheets/GlobalTheme';

export const StyledCard = styled(Card)`
    margin-bottom: 1rem;
    margin-top: 1rem;
    @media screen and (max-width: ${(props: ThemeProps) => props.theme.resolution.screenXl}) {
        text-align: left;
    }
`;

export const SuccessIcon = styled(Icon)`
    color: ${() => theme.colors.success};
`;

export const PrimaryIcon = styled(Icon)`
    color: ${() => theme.colors.primary};
`;

export const FailureIcon = styled(Icon)`
    color: ${() => theme.colors.failure};
`;

export const WarningIcon = styled(Icon)`
    color: ${() => theme.colors.warning};
`;

export const StyledTimeline = styled.div`
    opacity: ${(props: any) => (props.loading ? 0.2 : 1)};
`;

export const StyledDate = styled.span`
    font-size: 12px;
    margin-right: 1rem;
`;

export const StyledHeader = styled.h3`
    margin-bottom: 0;
`;

export const StyledLabel = styled.div`
    font-weight: bolder;
`;

export const StyledValue = styled.div`
    margin-bottom: 10px;
`;

export const StyledPrice = styled.span`
    margin-right: 15px;
    margin-left: 5px;
`;

export const StyledCheckbox = styled(Checkbox)`
    margin-left: 15px;
`;

export const StyledButton = styled(Button)`
    margin-top: 20px;
`;

export const ModalWrapper = styled.div`
    padding: 20px;
`;

export const StyledChoice = styled.div`
    display: flex;
    align-items: center;
`;
