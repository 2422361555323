import { UserType } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import { AxiosError } from 'axios';
import { createFormAction } from 'redux-form-saga';
import { UserGroup } from 'store/modules/users/@types';

import { UserAction } from './constants';

export enum Types {
    CREATE_USER = 'users/CREATE_USER',
    CREATE_USER_SUCCESS = 'users/CREATE_USER_SUCCESS',
    CREATE_USER_FAILURE = 'users/CREATE_USER_FAILURE',
    GET_USER = 'users/GET_USER',
    GET_USER_SUCCESS = 'users/GET_USER_SUCCESS',
    GET_USER_FAILURE = 'users/GET_USER_FAILURE',
    UPDATE_USER = 'users/UPDATE_USER',
    UPDATE_USER_SUCCESS = 'users/UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILURE = 'users/UPDATE_USER_FAILURE',
    USER_ACTION = 'users/USER_ACTION',
    USER_ACTION_SUCCESS = 'users/USER_ACTION_SUCCESS',
    USER_ACTION_FAILURE = 'users/USER_ACTION_FAILURE',
    GET_USER_GROUPS = 'users/GET_USER_GROUPS',
    GET_USER_GROUPS_SUCCESS = 'users/GET_USER_GROUPS_SUCCESS',
    GET_USER_GROUPS_FAILURE = 'users/GET_USER_GROUPS_FAILURE',
    GET_USERS_IN_GROUPS = 'users/GET_USERS_IN_GROUPS',
    GET_USERS_IN_GROUPS_SUCCESS = 'users/GET_USERS_IN_GROUPS_SUCCESS',
    GET_USERS_IN_GROUPS_FAILURE = 'users/GET_USERS_IN_GROUPS_FAILURE',
}

export const createUser = createFormAction(Types.CREATE_USER);
export const updateUser = createFormAction(Types.UPDATE_USER);

export function createUserSuccess(user: UserType) {
    return {
        type: Types.CREATE_USER_SUCCESS,
        payload: { user },
    };
}

export function createUserFailure(error: AxiosError) {
    return {
        type: Types.CREATE_USER_FAILURE,
        payload: { error },
    };
}

export function getUser(userId: string) {
    return {
        type: Types.GET_USER,
        payload: {
            userId,
        },
    };
}

export function getUserSuccess(user: UserType) {
    return {
        type: Types.GET_USER_SUCCESS,
        payload: {
            user,
        },
    };
}

export function getUserFailure(error: string) {
    return {
        type: Types.GET_USER_FAILURE,
        payload: { error },
    };
}

export function userAction(userAction: UserAction, userId: string) {
    return {
        type: Types.USER_ACTION,
        payload: {
            userAction,
            userId,
        },
    };
}
export function userActionSuccess(userAction: UserAction) {
    return {
        type: Types.USER_ACTION_SUCCESS,
        payload: {
            userAction,
        },
    };
}
export function userActionFailure(error: AxiosError) {
    return {
        type: Types.USER_ACTION_FAILURE,
        payload: { error },
    };
}
export function getUserGroups() {
    return {
        type: Types.GET_USER_GROUPS,
    };
}
export function getUserGroupsSuccess(userGroups: UserGroup[], paginationToken: string) {
    return {
        type: Types.GET_USER_GROUPS_SUCCESS,
        payload: {
            userGroups,
            paginationToken,
        },
    };
}
export function getUserGroupsFailure(error: AxiosError) {
    return {
        type: Types.GET_USER_GROUPS_FAILURE,
        payload: { error },
    };
}
export function getUsersInGroups(userGroup: string) {
    return {
        type: Types.GET_USERS_IN_GROUPS,
        payload: {
            userGroup,
        },
    };
}
export function getUsersInGroupsSuccess(users: UserType[], userGroup: string) {
    return {
        type: Types.GET_USERS_IN_GROUPS_SUCCESS,
        payload: {
            users,
            userGroup,
        },
    };
}
export function getUsersInGroupsFailure(error: AxiosError) {
    return {
        type: Types.GET_USERS_IN_GROUPS_FAILURE,
        payload: { error },
    };
}
