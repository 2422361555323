import Table from 'antd/es/table';
import { UserType } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import { t } from 'i18next';
import forEach from 'lodash-es/forEach';
import isEqual from 'lodash-es/isEqual';
import map from 'lodash-es/map';
import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'redux';
import { Routes } from 'store/modules/ui/constants';
import { ExtendedUserType, Pagination } from 'store/modules/users/@types';
import { getUserGroups } from 'store/modules/users/actions';
import { selectUsersInGroups, selectUsersInGroupsError, selectUsersInGroupsLoading } from 'store/modules/users/selectors';
import { ITranslator, Redux, SelectorType } from 'typings';
import Name from './components/Name';
import { orderBy } from 'lodash-es';

interface Props extends ITranslator {
    getUserGroups?: typeof getUserGroups;
    loading?: SelectorType<typeof selectUsersInGroupsLoading>;
    users?: ExtendedUserType[];
    error?: SelectorType<typeof selectUsersInGroupsError>;
    push?: typeof push;
}

interface DataSource extends UserType {
    email: string;
    verified: boolean;
}

interface State {
    dataSource: DataSource[];
    pagination: Pagination;
}

const columns = [
    {
        title: t('routes.Users.email'),
        dataIndex: 'email',
        render: (text: string, record: DataSource) => <Name text={text} {...record} />,
    },
    {
        title: t('routes.Users.role'),
        dataIndex: 'userGroup',
        render: (value: string) => t(`userGroups.${value}`),
    },
    {
        title: t('routes.Users.status'),
        dataIndex: 'UserStatus',
        render: (value: string) => t(`userStatus.${value}`),
    },
];

class UserList extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: null,
            pagination: null,
        };
    }

    componentDidMount() {
        const { getUserGroups, users } = this.props;
        getUserGroups();

        if (users) {
            this.createTableData(users);
        }
    }

    componentDidUpdate(prevProps: Props) {
        const { users } = this.props;
        if (!isEqual(prevProps.users, users)) {
            this.createTableData(users);
        }
    }

    handleTableChange = (pagination: Pagination) => {
        const { users } = this.props;

        if (users) {
            this.createTableData(users, pagination);
        }
    };

    createTableData = (users: Props['users'], pagination?: Pagination) => {
        const dataSource = map(users, (user: Props['users'][0]) => {
            const { Attributes, userGroup } = user;
            const attributes: any = {};
            forEach(Attributes, (attribute: { Name: string; Value: string }) => {
                attributes[attribute.Name] = attribute.Value;
            });

            return {
                ...user,
                email: attributes.email,
                verified: attributes.email_verified,
                userGroup,
            };
        });

        const pageSize = 10;
        const current = pagination?.current ?? 1;
        const total = dataSource?.length;

        this.setState({
            dataSource: orderBy(dataSource, ['email'], ['asc']),
            pagination: {
                pageSize,
                current,
                total,
            },
        });
    };

    render() {
        const { loading, error, t, push } = this.props;
        const { dataSource, pagination } = this.state;

        if (error || !pagination) {
            return <>chyba</>;
        }

        return (
            <>
                <Table
                    loading={loading}
                    dataSource={dataSource}
                    columns={columns}
                    pagination={pagination}
                    onChange={this.handleTableChange}
                    locale={{ emptyText: t('containers.UserList.noUsers') }}
                    rowKey={(record: Props['users'][0]) => record.Username}
                    onRow={(record: Props['users'][0]) => {
                        return {
                            onClick: () => push(`${Routes.USERS.path}/${record.Username}`),
                        };
                    }}
                />
            </>
        );
    }
}

export default compose<any>(
    connect(
        (state: Redux) => ({
            loading: selectUsersInGroupsLoading(state),
            error: selectUsersInGroupsError(state),
            users: selectUsersInGroups(state),
        }),
        { getUserGroups, push },
    ),
    translate(),
)(UserList);
