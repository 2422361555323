import { t } from 'i18next';
import { SagaIterator } from 'redux-saga';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { openNotification } from 'utils/notifications';
import { uploadUserDocument } from 'utils/s3';
import { getToken } from 'utils/withToken';

import * as actions from './actions';
import fetcher from './requests';

function* getCustomers() {
    yield takeLatest(actions.Types.GET_CUSTOMERS, function* handle(action: ReturnType<typeof actions.getCustomers>) {
        const { page, vin, email } = action.payload;
        try {
            const requests = fetcher(yield getToken());
            const data = yield requests.getCustomers(page, vin, email);
            yield put(actions.getCustomersSuccess(data));
        } catch (err) {
            yield put(actions.getCustomersFailure(err));
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.customers'));
        }
    });
}

function* unassignedCar() {
    yield takeLatest(actions.Types.UNASSIGNED_CAR, function* handle(action: ReturnType<typeof actions.unassignedCar>) {
        const { userId, vin } = action.payload;

        try {
            const requests = fetcher(yield getToken());
            yield requests.unassignCar(userId, vin);
            yield put(actions.unassignedCarSuccess());
        } catch (err) {
            yield put(actions.unassignedCarFailure(err));
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.customers'));
        }
    });
}

function* getCustomer() {
    yield takeLatest(actions.Types.GET_CUSTOMER, function*(action: ReturnType<typeof actions.getCustomer>) {
        const { userId } = action.payload;

        try {
            const requests = fetcher(yield getToken());

            const customerPromise = requests.getCustomer(userId);
            const docsPromise = requests.getCustomerDocs(userId);

            const customer = yield customerPromise;
            const { documents } = yield docsPromise;

            yield put(actions.getCustomerSuccess(customer, documents));
        } catch (err) {
            yield put(actions.getCustomerFailure(err));
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.customers'));
        }
    });
}

function* createDocument() {
    yield takeLatest(actions.Types.CREATE_DOCUMENT, function* handle(action: ReturnType<typeof actions.createDocument>) {
        const { document, userId, documentName, documentType } = action.payload;

        try {
            const requests = fetcher(yield getToken());

            const documentWithPath = { ...document, name: `documents/${document.name}` };

            const { key } = yield uploadUserDocument(documentWithPath);
            // TODO: dont prepend public, solve it in the bucket config
            const { document: newDocument } = yield requests.addCustomerDocument(userId, 'public/' + key, documentName, documentType);
            yield put(actions.createDocumentSuccess(newDocument));
        } catch (err) {
            yield put(actions.createDocumentFailure(err));
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.documentCreationError'));
        }
    });
}

function* deleteDocument() {
    yield takeLatest(actions.Types.DELETE_DOCUMENT, function* handle(action: ReturnType<typeof actions.deleteDocument>) {
        const { docId } = action.payload;

        try {
            const requests = fetcher(yield getToken());
            yield requests.deleteCustomerDocument(docId);
            yield put(actions.deleteDocumentSuccess(docId));
        } catch (err) {
            yield put(actions.createDocumentFailure(err));
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.documentDeletionError'));
        }
    });
}

export default function* customersSaga(): SagaIterator {
    yield fork(getCustomers);
    yield fork(unassignedCar);
    yield fork(getCustomer);
    yield fork(createDocument);
    yield fork(deleteDocument);
}
