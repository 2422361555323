import 'ant-design-pro/dist/ant-design-pro.css';
import 'antd/dist/antd.css';
import 'sanitize.css';

import * as React from 'react';
import * as ReactDOM from 'react-dom';

import App from './App';

ReactDOM.render(<App />, document.getElementById('app'));
