import { t } from 'i18next';
import { Events } from 'store/modules/appInfo/constants';

import { EventIconType } from './Event/utils/enums';

const eventTypes = 'containers.EventTimeline.eventTypes';
const translate = (key: string) => t(`${eventTypes}.${key}`);

export default {
    [Events.Notification.CANCELLED]: {
        title: translate('cancelled'),
        icon: {
            color: EventIconType.WARNING,
            name: 'stop',
            theme: 'outlined',
        },
    },
    [Events.Notification.SEEN]: {
        title: translate('seen'),
        icon: {
            color: EventIconType.SUCCESS,
            name: 'check-circle',
            theme: 'filled',
        },
    },
    [Events.Notification.SENT]: {
        title: translate('sent'),
        icon: {
            color: EventIconType.PRIMARY,
            name: 'mail',
            theme: 'outlined',
        },
    },
    [Events.Notification.RECEIVED]: {
        title: translate('received'),
        icon: {
            color: EventIconType.SUCCESS,
            name: 'check-circle',
            theme: 'outlined',
        },
    },
    [Events.Notification.RESPONSE]: {
        title: translate('response'),
        icon: {
            color: EventIconType.SUCCESS,
            name: 'message',
            theme: 'outlined',
        },
    },
    [Events.Reception.CONTACT_SUCCEEDED]: {
        title: translate('contactSucceeded'),
        icon: {
            color: EventIconType.SUCCESS,
            name: 'phone',
            theme: 'outlined',
        },
    },
    [Events.Reception.CONTACT_FAILED]: {
        title: translate('contactFailed'),
        icon: {
            color: EventIconType.FAILURE,
            name: 'phone',
            theme: 'outlined',
        },
    },
    [Events.StateChanged.CLOSED]: {
        title: translate('closed'),
        icon: {
            color: EventIconType.PRIMARY,
            name: 'lock',
            theme: 'outlined',
        },
    },
};
