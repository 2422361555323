import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { ITranslator } from 'typings';
import { CloseOutlined } from '@ant-design/icons/lib';
import { Tag as TagAntd, Popconfirm, message } from 'antd';
import { unassignedCar } from 'store/modules/customers/actions';

interface Props extends ITranslator {
    unassignedCar?: typeof unassignedCar;
    vin: string;
    userId: string;
}

class Tag extends Component<Props> {
    confirm = (userId: string, vin: string) => {
        const { unassignedCar } = this.props;
        unassignedCar(userId, vin);
        message.success(`Vin ${vin} odpárovan`);
    };

    stopPropagation = (e: React.MouseEvent) => e.stopPropagation();

    render() {
        const { vin, userId } = this.props;

        return (
            <div onClick={this.stopPropagation}>
                <Popconfirm
                    title={`Skutečně si přejete odpárovat vozidlo od uživatele?`}
                    onConfirm={() => this.confirm(userId, vin)}
                    okText="Ano"
                    cancelText="Ne"
                >
                    <TagAntd style={{ padding: '6px', margin: '4px', background: '#e8e8e8', cursor: 'pointer' }}>
                        <div style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            {vin}
                            <CloseOutlined />
                        </div>
                    </TagAntd>
                </Popconfirm>
            </div>
        );
    }
}

export default translate()(Tag);
