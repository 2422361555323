import PageHeader from 'ant-design-pro/lib/PageHeader';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import BranchForm from 'containers/BranchForm';
import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isSubmitting, submit } from 'redux-form';
import { createBranch } from 'store/modules/branches/actions';
import { selectBranchesError, selectBranchesSending } from 'store/modules/branches/selectors';
import { ITranslator, Redux, SelectorType } from 'typings';

interface Props extends ITranslator {
    sending?: SelectorType<typeof selectBranchesSending>;
    submitting?: SelectorType<typeof isSubmitting>;
    error?: SelectorType<typeof selectBranchesError>;
    submit?: typeof submit;
    createBranch?: typeof createBranch;
}

class AddBranch extends React.Component<Props> {
    render() {
        const { sending, t, submit, createBranch } = this.props;
        return (
            <>
                <PageHeader
                    itemRender={null}
                    title={t('routes.AddBranch.title')}
                    action={
                        <Button type="primary" onClick={() => submit('branchForm')} loading={sending}>
                            {t('routes.AddBranch.addBranch')}
                        </Button>
                    }
                />
                <Card bordered={false}>
                    <BranchForm onSubmit={createBranch} />
                </Card>
            </>
        );
    }
}

export default compose<any>(
    connect(
        (state: Redux, props: Props) => ({
            sending: selectBranchesSending(state),
            submitting: isSubmitting('branchForm')(state),
            error: selectBranchesError(state),
        }),
        { submit, createBranch },
    ),
    translate(),
)(AddBranch);
