import styled from 'styled-components';
import { ThemeProps } from 'stylesheets/GlobalTheme';

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media screen and (min-width: ${(props: ThemeProps) => props.theme.resolution.screenMd}) {
        justify-content: flex-start;
    }
`;
