import Exception from 'ant-design-pro/lib/Exception';
import Button from 'antd/es/button';
import { t } from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';
import { Routes } from 'store/modules/ui/constants';

const actions = (
    <Link to={Routes.HOME.path}>
        <Button type="primary">{t('sider.homepage')}</Button>
    </Link>
);

export default function Page403() {
    return <Exception type="403" title={t('components.Page403.title')} desc={t('components.Page403.desc')} actions={actions} />;
}
