import { AxiosError } from 'axios';
import { createFormAction } from 'redux-form-saga';

import { Contact } from './@types';

export enum Types {
    GET_CONTACTS = 'contacts/GET_CONTACTS',
    GET_CONTACTS_SUCCESS = 'contacts/GET_CONTACTS_SUCCESS',
    GET_CONTACTS_FAILURE = 'contacts/GET_CONTACTS_FAILURE',
    CREATE_CONTACT = 'contacts/CREATE_CONTACT',
    CREATE_CONTACT_SUCCESS = 'contacts/CREATE_CONTACT_SUCCESS',
    CREATE_CONTACT_FAILURE = 'contacts/CREATE_CONTACT_FAILURE',
    UPDATE_CONTACT = 'contacts/UPDATE_CONTACT',
    UPDATE_CONTACT_SUCCESS = 'contacts/UPDATE_CONTACT_SUCCESS',
    UPDATE_CONTACT_FAILURE = 'contacts/UPDATE_CONTACT_FAILURE',
    DELETE_CONTACT = 'contacts/DELETE_CONTACT',
    DELETE_CONTACT_SUCCESS = 'contacts/DELETE_CONTACT_SUCCESS',
    DELETE_CONTACT_FAILURE = 'contacts/DELETE_CONTACT_FAILURE',
    GET_CONTACT = 'contacts/GET_CONTACT',
    GET_CONTACT_SUCCESS = 'contacts/GET_CONTACT_SUCCESS',
    GET_CONTACT_FAILURE = 'contacts/GET_CONTACT_FAILURE',
}

export const createContact = createFormAction(Types.CREATE_CONTACT);
export const updateContact = createFormAction(Types.UPDATE_CONTACT);

export function getContact(contactId: string) {
    return {
        type: Types.GET_CONTACT,
        payload: {
            contactId,
        },
    };
}

export function getContactSuccess(contact: Contact) {
    return {
        type: Types.GET_CONTACT_SUCCESS,
        payload: {
            contact,
        },
    };
}

export function getContactFailure(error: any) {
    return {
        type: Types.GET_CONTACT_FAILURE,
        payload: {
            error,
        },
    };
}

export function getContacts() {
    return {
        type: Types.GET_CONTACTS,
    };
}
export function getContactsSuccess(contacts: Contact[]) {
    return {
        type: Types.GET_CONTACTS_SUCCESS,
        payload: { contacts },
    };
}
export function getContactsFailure(error: AxiosError) {
    return {
        type: Types.GET_CONTACTS_FAILURE,
        payload: { error },
    };
}

export function createContactSuccess() {
    return {
        type: Types.CREATE_CONTACT_SUCCESS,
    };
}

export function createContactFailure(error: AxiosError) {
    return {
        type: Types.CREATE_CONTACT_FAILURE,
        payload: {
            error,
        },
    };
}

export function updateContactSuccess() {
    return {
        type: Types.UPDATE_CONTACT_SUCCESS,
    };
}

export function updateContactFailure(error: AxiosError) {
    return {
        type: Types.UPDATE_CONTACT_FAILURE,
        payload: {
            error,
        },
    };
}

export function deleteContact(contactId: string) {
    return {
        type: Types.DELETE_CONTACT,
        payload: {
            contactId,
        },
    };
}

export function deleteContactSuccess() {
    return {
        type: Types.DELETE_CONTACT_SUCCESS,
    };
}

export function deleteContactFailure(error: AxiosError) {
    return {
        type: Types.DELETE_CONTACT_FAILURE,
        payload: {
            error,
        },
    };
}
