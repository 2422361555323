import { AxiosError } from 'axios';
import { DocumentFormData } from 'view/containers/DocumentForm/@typings';

import { RawCustomer, Filter, Customer, UserDocument } from './@types';

export enum Types {
    GET_CUSTOMERS = 'customers/GET_CUSTOMERS',
    GET_CUSTOMERS_SUCCESS = 'customers/GET_CUSTOMERS_SUCCESS',
    GET_CUSTOMERS_FAILURE = 'customers/GET_CUSTOMERS_FAILURE',
    SET_FILTER = 'customers/SET_FILTER',
    SET_FILTERS = 'customers/SET_FILTERS',
    UNASSIGNED_CAR = 'customer/UNASSIGNED_CAR',
    UNASSIGNED_CAR_SUCCESS = 'customer/UNASSIGNED_CAR_SUCCESS',
    UNASSIGNED_CAR_FAILURE = 'customer/UNASSIGNED_CAR_FAILURE',
    GET_CUSTOMER = 'customers/GET_CUSTOMER',
    GET_CUSTOMER_SUCCESS = 'customers/GET_CUSTOMER_SUCCESS',
    GET_CUSTOMER_FAILURE = 'customers/GET_CUSTOMER_FAILURE',
    CREATE_DOCUMENT = 'customers/CREATE_DOCUMENT',
    CREATE_DOCUMENT_SUCCESS = 'customers/CREATE_DOCUMENT_SUCCESS',
    CREATE_DOCUMENT_FAILURE = 'customers/CREATE_DOCUMENT_FAILURE',
    DELETE_DOCUMENT = 'customers/DELETE_DOCUMENT',
    DELETE_DOCUMENT_SUCCESS = 'customers/DELETE_DOCUMENT_SUCCESS',
    DELETE_DOCUMENT_FAILURE = 'customers/DELETE_DOCUMENT_FAILURE',
}

export function getCustomers(page: number, filter: Filter = {}) {
    return {
        type: Types.GET_CUSTOMERS,
        payload: { page, ...filter },
    };
}

export function getCustomersSuccess(data: RawCustomer) {
    return {
        type: Types.GET_CUSTOMERS_SUCCESS,
        payload: { data },
    };
}

export function getCustomersFailure(error: AxiosError) {
    return {
        type: Types.GET_CUSTOMERS_FAILURE,
        payload: { error },
    };
}

export const setFilter = (key: string, value: string | string[]) => ({
    type: Types.SET_FILTER,
    payload: { key, value },
});

export const setFilters = (filters: Filter) => ({
    type: Types.SET_FILTERS,
    payload: { filters },
});

export const unassignedCar = (userId: string, vin: string) => ({
    type: Types.UNASSIGNED_CAR,
    payload: { userId, vin },
});

export function unassignedCarSuccess() {
    return {
        type: Types.UNASSIGNED_CAR_SUCCESS,
    };
}

export function unassignedCarFailure(error: AxiosError) {
    return {
        type: Types.UNASSIGNED_CAR_FAILURE,
        payload: { error },
    };
}

export const getCustomer = (userId: string) => ({
    type: Types.GET_CUSTOMER,
    payload: { userId },
});

export const getCustomerSuccess = (customer: Customer, docs: UserDocument[]) => ({
    type: Types.GET_CUSTOMER_SUCCESS,
    payload: { customer, docs },
});

export const getCustomerFailure = (error: AxiosError) => ({
    type: Types.GET_CUSTOMER_FAILURE,
    payload: { error },
});

export const createDocument = (userId: string, formData: DocumentFormData) => ({
    type: Types.CREATE_DOCUMENT,
    payload: { ...formData, userId },
});

export const createDocumentSuccess = (document: UserDocument) => ({
    type: Types.CREATE_DOCUMENT_SUCCESS,
    payload: { document },
});

export const createDocumentFailure = (error: AxiosError) => ({
    type: Types.CREATE_DOCUMENT_FAILURE,
    payload: { error },
});

export const deleteDocument = (docId: string) => ({
    type: Types.DELETE_DOCUMENT,
    payload: { docId },
});

export const deleteDocumentSuccess = (docId: string) => ({
    type: Types.DELETE_DOCUMENT_SUCCESS,
    payload: { docId },
});

export const deleteDocumentFailure = (error: AxiosError | Error) => ({
    type: Types.DELETE_DOCUMENT_FAILURE,
    payload: { error },
});
