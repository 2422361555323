import PageHeader from 'ant-design-pro/lib/PageHeader';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import AddSymbolComponent from 'containers/AddSymbol';
import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isSubmitting, submit } from 'redux-form';
import { createSymbol } from 'store/modules/symbols/actions';
import { ITranslator, Redux, SelectorType } from 'typings';

interface Props extends ITranslator {
    submit?: typeof submit;
    submitting?: SelectorType<typeof isSubmitting>;
}

class AddSymbol extends React.Component<Props> {
    render() {
        const { t, submit, submitting } = this.props;
        return (
            <>
                <PageHeader
                    itemRender={null}
                    title={t('routes.AddSymbol.title')}
                    action={
                        <Button type="primary" onClick={() => submit('addSymbol')} loading={submitting}>
                            {t('containers.AddSymbol.submit')}
                        </Button>
                    }
                />
                <Card bordered={false}>
                    <AddSymbolComponent onSubmit={createSymbol} />
                </Card>
            </>
        );
    }
}

export default compose<any>(
    connect(
        (state: Redux) => ({
            submitting: isSubmitting('addSymbol')(state),
        }),
        { submit },
    ),
    translate(),
)(AddSymbol);
