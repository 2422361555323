import Row from 'antd/es/row';
import FormCascader from 'components/CascaderField';
import FromS3 from 'components/FromS3';
import UploadField from 'components/UploadField';
import React from 'react';
import { translate } from 'react-i18next';
import { compose } from 'redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import SelectField from 'redux-form-antd/es/components/SelectField';
import SwitchField from 'redux-form-antd/es/components/SwitchField';
import TextField from 'redux-form-antd/es/components/TextField';
import { Contact } from 'store/modules/contacts/@types';
import { allowedImageTypes } from 'store/modules/contacts/constants';
import { ITranslator } from 'typings';

import { cascaderOptions, dropdownOptions } from './formOptions';
import { FormWrapper } from './styled';
import validate from './validate';

interface Props extends InjectedFormProps, ITranslator {
    imageName?: string;
    onSubmit: (contact: Contact) => void;
}

class ContactForm extends React.Component<Props> {
    render() {
        const { t, handleSubmit, imageName, onSubmit } = this.props;
        return (
            <FormWrapper>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Field name="email" component={TextField} label={t('containers.ContactForm.email')} required />
                    <Field name="name" component={TextField} label={t('containers.ContactForm.name')} required />
                    <Field name="phone" component={TextField} label={t('containers.ContactForm.phone')} required />
                    <Field
                        name="department_position"
                        component={FormCascader}
                        label={t('containers.ContactForm.position')}
                        options={cascaderOptions}
                        placeholder=""
                        required
                    />
                    <Field
                        name="branch"
                        component={SelectField}
                        label={t('containers.ContactForm.branch')}
                        options={dropdownOptions.branch}
                        required
                    />
                    <Field name="primary" component={SwitchField} label={t('containers.ContactForm.primary')} />
                    <Row type="flex" justify="center">
                        <FromS3 name={imageName}>
                            {(url: string) => (
                                <Field
                                    name="image"
                                    awsFolder="contacts"
                                    placeholder={t('routes.AddContact.uploadPhoto')}
                                    component={UploadField}
                                    accept={allowedImageTypes.join(',')}
                                    type="image"
                                    url={url}
                                />
                            )}
                        </FromS3>
                    </Row>
                </form>
            </FormWrapper>
        );
    }
}

export default compose<any>(
    reduxForm({
        form: 'contactForm',
        validate,
    }),
    translate(),
)(ContactForm);
