import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router';
import { selectToken, selectUserRoles } from 'store/modules/auth/selectors';
import { Role } from 'store/modules/ui/constants';
import { Redux, RouteRoles } from 'typings';
import isRouteAllowed from 'utils/rolesAuth';
import Page403 from 'view/components/Page403';
import WithAppInfo from 'view/components/WithAppInfo';
import DefaultTemplate from 'view/templates/DefaultTemplate';

interface Props extends RouteProps {
    token?: string;
    roles?: RouteRoles;
    userRoles?: Role[];
}

class PrivateRoute extends React.Component<Props> {
    render() {
        const { token, component: Component, userRoles, roles, ...rest } = this.props;
        if (!isRouteAllowed(roles, userRoles)) {
            return <Page403 />;
        }

        return (
            <Route
                {...rest}
                render={(props: RouteComponentProps<any>) =>
                    token ? (
                        <WithAppInfo>
                            {() => (
                                <DefaultTemplate userRoles={userRoles}>
                                    <Component {...props} />
                                </DefaultTemplate>
                            )}
                        </WithAppInfo>
                    ) : (
                        <Redirect to="/sign-in" />
                    )
                }
            />
        );
    }
    AnyAction;
}
export default connect(
    (state: Redux) => ({
        token: selectToken(state),
        userRoles: selectUserRoles(state),
    }),
    null,
)(PrivateRoute);
