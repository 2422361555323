import styled from 'styled-components';

export const Text = styled.span`
    margin-bottom: 15px;
    font-size: 42px;
    font-weight: bold;
    text-align: center;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

export const Heart = styled.img`
    width: 60px;
    padding-bottom: 40px;
`;
