import React from 'react';
import { translate } from 'react-i18next';
import { compose } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { ITranslator } from 'typings';
import SignInForm from 'view/components/SignInForm';
import SignInTemplate from 'view/templates/SignInTemplate';
import { Logo, Wrapper } from './styled';
import validate from './validate';

const logo = require('assets/ic_volvista_logo.png');

interface Props extends InjectedFormProps, ITranslator {}

class SignIn extends React.Component<Props> {
    render() {
        return (
            <SignInTemplate>
                <Wrapper>
                    <Logo src={logo} alt="logo" />
                    <SignInForm {...this.props} />
                </Wrapper>
            </SignInTemplate>
        );
    }
}

export default compose<any>(
    reduxForm({
        form: 'signInForm',
        validate,
    }),
    translate(),
)(SignIn);
