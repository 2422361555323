import Storage from 'aws-amplify/lib/Storage';

import { randomizeFilename } from './helpers';

declare const Conf;

export interface S3File {
    file: File;
    name: string;
}

const config = { bucket: Conf.amplify.storage.bucket.service };

const documentsBucket = Conf.amplify.storage.bucket.documents;

const makeUploadFunction = (bucket: string) => ({ file, name }: S3File) => {
    const filename = randomizeFilename(name);

    return Storage.put(filename, file, { bucket });
};

export const uploadAppData = makeUploadFunction(Conf.amplify.storage.bucket.appData);
export const uploadUserDocument = makeUploadFunction(documentsBucket);

export function get(name: string, isVideo: boolean = false) {
    if (isVideo) {
        console.log('Get video', { name, config });
        return Storage.get(name, config);
    }

    return Storage.get(name);
}

export const deleteUserDocument = (s3Key: string) => Storage.remove(s3Key, { bucket: documentsBucket });
