import React from 'react';
import { translate } from 'react-i18next';
import { InjectedFormProps } from 'redux-form';
import { ITranslator } from 'typings';
import ForgottenPasswordForm from 'view/components/ForgottenPasswordForm';
import { Heart, Text, Wrapper } from 'view/routes/ForgottenPasswordConfirm/styled';

const logo_heart = require('assets/ic_heart_volvo.png');

interface Props extends InjectedFormProps, ITranslator {}

class ForgottenPassword extends React.Component<Props> {
    render() {
        const { t } = this.props;
        return (
            <Wrapper>
                <Text>{t('routes.ForgottenPassword.title')}</Text>
                <Heart src={logo_heart} alt="heart" />
                <ForgottenPasswordForm />
            </Wrapper>
        );
    }
}
export default translate()(ForgottenPassword);
