import Button from 'antd/es/button';
import styled from 'styled-components';

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 50%;
`;

export const StyledButton = styled(Button)`
    margin: 10px;
`;
