import i18next from 'i18next';
import XHR from 'i18next-xhr-backend';
import { get } from 'tiny-cookie';

i18next.use(XHR);

declare var __DEVELOPMENT__: boolean;

export const supportedLanguages = ['cs-CZ', 'en-US'];

const selectedLanguage = get('lang') || supportedLanguages[0];

i18next.init({
    lng: selectedLanguage,
    fallbackLng: {
        en: ['en-US'],
        cs: ['cs-CZ'],
        default: supportedLanguages,
    },
    resources: require('../locale/index.locales.js'),
    debug: typeof __DEVELOPMENT__ !== 'undefined' && __DEVELOPMENT__,
    preload: [],
});

export default i18next;
