import { createSelector } from 'reselect';
import { Redux } from 'typings';
import { _get } from 'utils/get';

import { AuthReducer } from './@types.d';

const selectAuthSubState = (state: Redux) => state.auth;

export const selectToken = createSelector(selectAuthSubState, (subState: AuthReducer) => _get(subState, 'session', 'idToken', 'jwtToken'));
export const selectTokenExpiration = createSelector(selectAuthSubState, (subState: AuthReducer) =>
    _get(subState, 'session', 'accessToken', 'payload', 'exp'),
);
export const selectRefreshToken = createSelector(selectAuthSubState, (subState: AuthReducer) =>
    _get(subState, 'session', 'refreshToken', 'token'),
);

export const selectUserRoles = createSelector(selectAuthSubState, (subState: AuthReducer) =>
    _get(subState, 'session', 'accessToken', 'payload', 'cognito:groups'),
);

export const selectUserEmail = createSelector(selectAuthSubState, (subState: AuthReducer) =>
    _get(subState, 'session', 'idToken', 'payload', 'email'),
);
export const selectUser = createSelector(selectAuthSubState, (subState: AuthReducer) => subState.user);

export const selectSignedOutUserEmail = createSelector(selectAuthSubState, (subState: AuthReducer) => subState.signedOutEmail);
