import { SelectProps } from 'antd/es/select';
import { t } from 'i18next';
import React from 'react';
import { ITranslator } from 'typings';

import { StyledSelect, Wrapper } from './styled';

interface Props extends ITranslator {
    defaultValue?: string | number;
    size?: SelectProps['size'];
    mode?: SelectProps['mode'];
    placeholder?: string;
    onOptionSelect?: (event: string) => void;
}

class SelectInput extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.state = { selectedValue: this.props.defaultValue };
    }

    render() {
        const { size, defaultValue, children, onOptionSelect, mode, placeholder } = this.props;

        return (
            <Wrapper>
                <StyledSelect
                    mode={mode}
                    placeholder={placeholder || t('components.SelectInput.placeholder')}
                    size={size}
                    defaultValue={defaultValue}
                    onChange={(event: any) => onOptionSelect(event)}
                >
                    {children}
                </StyledSelect>
            </Wrapper>
        );
    }
}

export default SelectInput;
