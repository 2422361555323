import React, { Component } from 'react';
import ManualForm from 'view/containers/ManualForm';

class AddManual extends Component {
    render() {
        return <ManualForm />;
    }
}

export default AddManual;
