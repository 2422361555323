import find from 'lodash-es/find';
import { createSelector } from 'reselect';
import { Redux } from 'typings';

import { ContactsReducer } from './@types.d';

const selectContactsSubState = (state: Redux) => state.contacts;

const selectContactDetail = createSelector(selectContactsSubState, (contacts: ContactsReducer) => contacts.detail);

const selectContactsList = createSelector(selectContactsSubState, (contacts: ContactsReducer) => contacts.list);

export const selectContacts = createSelector(selectContactsList, (list: ContactsReducer['list']) => list.data);

export const selectContactsLoading = createSelector(selectContactsList, (list: ContactsReducer['list']) => list.loading);

export const selectContactsError = createSelector(selectContactsList, (list: ContactsReducer['list']) => list.error);

export const selectContactsSending = createSelector(selectContactsList, (list: ContactsReducer['list']) => list.sending);

export const selectContact = createSelector(selectContactDetail, (detail: ContactsReducer['detail']) => detail.data);

export const selectContactLoading = createSelector(selectContactDetail, (detail: ContactsReducer['detail']) => detail.loading);

export const selectContactError = createSelector(selectContactDetail, (detail: ContactsReducer['detail']) => detail.error);

export const selectContactSending = createSelector(selectContactDetail, (detail: ContactsReducer['detail']) => detail.sending);

export const selectContactById = (contactId: string) =>
    createSelector(selectContactsList, (list: ContactsReducer['list']) => find(list.data, { _id: contactId }));
