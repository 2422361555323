import { t } from 'i18next';
import { SagaIterator } from 'redux-saga';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { openNotification } from 'utils/notifications';
import { getToken } from 'utils/withToken';

import * as actions from './actions';
import fetcher from './requests';

export function* getAppInfo() {
    yield takeLatest(actions.Types.GET_APP_INFO, function* handle(action: ReturnType<typeof actions.getAppInfo>) {
        try {
            const requests = fetcher(yield getToken());
            const appInfo = yield requests.getAppInfo();
            yield put(actions.getAppInfoSuccess(appInfo));
        } catch (err) {
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
            yield put(actions.getAppInfoFailure(err));
        }
    });
}

export default function* appInfoSage(): SagaIterator {
    yield fork(getAppInfo);
}
