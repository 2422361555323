import find from 'lodash-es/find';
import get from 'lodash-es/get';
import moment from 'moment';
import { Branch } from 'store/modules/appInfo/@types';
import { TicketStateName, TicketTypeName } from 'store/modules/appInfo/constants';
import { Ticket } from 'store/modules/tickets/@types';
import { TicketRow } from 'store/modules/tickets/list/@types';
import translator from 'utils/translator';

const ticketType = translator('routes.Tickets.ticketTypes');

const byId = (branchId: string) => (branch: Branch) => branch.id === branchId;

export const statusTranslation = {
    [TicketStateName.OPEN]: ticketType('open'),
    [TicketStateName.CLOSED]: ticketType('closed'),
    [TicketStateName.PENDING]: ticketType('pending'),
};

export const typeTranslation = {
    [TicketTypeName.SERVICE_APPROVAL]: ticketType('approval'),
    [TicketTypeName.SERVICE_REQUEST]: ticketType('request'),
    [TicketTypeName.SERVICE_REMINDER]: ticketType('reminder'),
    [TicketTypeName.CARD_EXPIRATION]: ticketType('cardExpiration'),
    [TicketTypeName.WARRANTY_EXPIRATION]: ticketType('warrantyExpiration'),
    [TicketTypeName.SERVICE_APPROVAL_CHOICE]: ticketType('approvalChoices'),
    [TicketTypeName.SIGN_UP_HELP]: ticketType('signUpHelp'),
};

export default (tickets: Ticket[], branches: Branch[]): TicketRow[] =>
    tickets &&
    tickets.map((ticket: Ticket, index: number) => {
        const {
            caseId,
            type,
            status,
            caseNumber,
            meta: { branch: branchId },
            updatedAt,
            data: {
                car: { vin },
            },
        } = ticket;
        return {
            key: index,
            caseId,
            updatedAt: moment(updatedAt).format('DD. MM. HH:mm'),
            vin,
            type: typeTranslation[type],
            status: statusTranslation[status],
            branch: get(find(branches, byId(branchId)), 'name'),
            caseNumber,
        };
    });
