import { Auth } from 'aws-amplify';

export async function getToken() {
    const { idToken } = await Auth.currentSession();
    return idToken.jwtToken;
}

export default async function withToken(f: (token: string) => any) {
    const token = await getToken();
    f(token);
}
