import FormItem from 'antd/es/form/FormItem';
import TimePicker from 'antd/es/time-picker';
import get from 'lodash-es/get';
import moment, { Moment } from 'moment';
import React from 'react';
import { translate } from 'react-i18next';
import { WrappedFieldProps } from 'redux-form';
import mapError from 'redux-form-antd/es/maps/mapError';
import { ITranslator } from 'typings';

interface Props extends FormItem, WrappedFieldProps, ITranslator {
    timeFormat?: string;
    minuteStep?: number;
    label?: string;
}

const TimeInput: React.SFC<Props> = (props: Props) => {
    const { label, labelCol, wrapperCol, help, extra, validateStatus, colon, required } = mapError(props);

    const value = get(props.input, 'value');

    return (
        <FormItem
            label={label}
            wrapperCol={wrapperCol}
            labelCol={labelCol}
            help={help}
            hasFeedback={true}
            extra={extra}
            validateStatus={validateStatus}
            colon={colon}
            required={required}
        >
            <TimePicker
                value={value ? moment(value, props.timeFormat) : null}
                onChange={(newValue: Moment) => props.input.onChange(newValue ? newValue.format(props.timeFormat) : null)}
                format={props.timeFormat}
                minuteStep={props.minuteStep}
                placeholder={props.t(`components.TimeInput.placeholder`)}
            />
        </FormItem>
    );
};

export default translate()(TimeInput);
