import PageHeader from 'ant-design-pro/lib/PageHeader';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import Modal from 'antd/es/modal';
import CenteredSpin from 'components/CenteredSpin';
import AddSymbol from 'containers/AddSymbol';
import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { match } from 'react-router';
import { compose } from 'redux';
import { isSubmitting, submit } from 'redux-form';
import { deleteSymbol, getSymbol, updateSymbol } from 'store/modules/symbols/actions';
import { selectSymbol, selectSymbolLoading } from 'store/modules/symbols/selectors';
import { ITranslator, Redux, SelectorType } from 'typings';

const confirm = Modal.confirm;

interface Props extends ITranslator {
    loading?: SelectorType<typeof selectSymbolLoading>;
    symbol?: SelectorType<typeof selectSymbol>;
    getSymbol?: typeof getSymbol;
    submitting?: SelectorType<typeof isSubmitting>;
    match?: match<{ symbolId: string }>;
    deleteSymbol?: typeof deleteSymbol;
    submit?: typeof submit;
}

class Symbol extends React.Component<Props> {
    componentDidMount() {
        const { getSymbol, match } = this.props;
        getSymbol(match.params.symbolId);
    }

    handleDelete = () => {
        const { deleteSymbol, match, t } = this.props;
        confirm({
            title: t('routes.Symbol.deleteWarning'),
            okText: t('routes.Symbol.okText'),
            okType: 'danger',
            cancelText: t('routes.Symbol.cancelText'),
            onOk() {
                deleteSymbol(match.params.symbolId);
            },
        });
    };

    render() {
        const { symbol, loading, t, submit, submitting } = this.props;

        if (!symbol || loading) {
            return <CenteredSpin />;
        }

        const {
            category,
            image: symbolImage,
            name: { cs: nameCs, en: nameEn },
            description: { cs: descriptionCs, en: descriptionEn },
        } = symbol;
        return (
            <>
                <PageHeader
                    itemRender={null}
                    title={t('routes.Symbol.title')}
                    action={
                        <>
                            <Button type="primary" onClick={() => submit('addSymbol')} loading={submitting}>
                                {t('containers.AddSymbol.submit')}
                            </Button>
                            <Button type="danger" loading={submitting} onClick={this.handleDelete}>
                                {t('routes.Symbol.delete')}
                            </Button>
                        </>
                    }
                />
                <Card bordered={false}>
                    <AddSymbol
                        onSubmit={updateSymbol}
                        symbolImage={symbolImage}
                        initialValues={{ category, nameCs, nameEn, descriptionCs, descriptionEn, symbolImage }}
                    />
                </Card>
            </>
        );
    }
}

export default compose<any>(
    connect(
        (state: Redux) => ({
            loading: selectSymbolLoading(state),
            symbol: selectSymbol(state),
            submitting: isSubmitting('addSymbol')(state),
        }),
        { getSymbol, deleteSymbol, submit },
    ),
    translate(),
)(Symbol);
