import { TranslationFunction } from 'i18next';
import uniqBy from 'lodash-es/uniqBy';
import { createSelector } from 'reselect';
import { CarType } from 'store/modules/carTypes/@types';
import { selectTypes } from 'store/modules/carTypes/selectors';
import { Redux } from 'typings';

import { Detail, Manuals, ManualsReducer, SelectOption } from './@types.d';
import getColumns from './utils/columns';
import { createCarOptions, createYearOptions } from './utils/options';
import getRows from './utils/rows';

const selectManualsSubstate = (state: Redux) => state.manuals;
const selectManuals = createSelector(selectManualsSubstate, (manuals: ManualsReducer): Manuals => manuals.manuals);
const selectDetail = createSelector(selectManualsSubstate, (manuals: ManualsReducer): Detail => manuals.detail);

export const selectTableColumns = (t: TranslationFunction) =>
    createSelector(selectManualsSubstate, (subState: ManualsReducer) => getColumns(subState.filters, t));
export const selecPagination = createSelector(selectManualsSubstate, (subState: ManualsReducer) => subState.pagination);

// All manuals
export const selectTableRows = createSelector(selectManuals, (manuals: Manuals) => getRows(manuals.data));
export const selectIsLoading = createSelector(selectManuals, (manuals: Manuals) => manuals.loading);

// Detail
export const selectManual = createSelector(selectDetail, (detail: Detail) => detail.data);
export const selectIsDetailLoading = createSelector(selectDetail, (detail: Detail) => detail.loading);
export const selectIssending = createSelector(selectDetail, (detail: Detail) => detail.sending);

export const selectCarOptions = createSelector(
    selectTypes,
    (carTypes: CarType[]): SelectOption[] => carTypes && uniqBy(carTypes, 'model').map(createCarOptions),
);
export const selectYearOptions = createSelector(
    selectTypes,
    (carTypes: CarType[]): SelectOption[] => carTypes && carTypes.map(createYearOptions),
);
