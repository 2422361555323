import { t } from 'i18next';
import { allCustomerDocumentTypes, CustomerDocumentType } from './customerDocumentTypes';

const pathToTranslation = 'routes.CustomerDetail';

const makeTranslationMap = () =>
    allCustomerDocumentTypes.reduce(
        (acc: Record<CustomerDocumentType, string>, type: CustomerDocumentType) => ({
            ...acc,
            [type]: t(`${pathToTranslation}.${type}`),
        }),
        {} as Record<CustomerDocumentType, string>,
    );

export const translateDocumentType = (type: string) => makeTranslationMap()[type] ?? '';
