import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Choice, VideoData } from 'store/modules/video/@types';
import { getData } from 'store/modules/video/actions';
import { selectData, selectError, selectLoading, selectResponseSent } from 'store/modules/video/selectors';
import { ITranslator, Redux, SelectorType } from 'typings';
import AppDownload from 'view/components/AppDownload';
import CenteredSpin from 'view/components/CenteredSpin';
import ChoicesForm from 'view/components/choicesForm';
import VideoScreen from 'view/components/VideoScreen';
import { getCaseId } from './utils';

interface Props extends ITranslator {
    selectData?: SelectorType<typeof selectData>;
    selectLoading?: SelectorType<typeof selectLoading>;
    error?: SelectorType<typeof selectError>;
    selectResponseSent?: SelectorType<typeof selectResponseSent>;
    responseSent?: boolean;
    data?: VideoData;
    getData?: typeof getData;
    loading?: boolean;
}

const Video = ({ getData, data, loading, responseSent, error }: Props) => {
    const caseId = getCaseId();

    useEffect(() => {
        if (caseId) {
            getData(caseId);
        }
    }, [caseId]);

    if (loading) {
        return (
            <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CenteredSpin />
            </div>
        );
    }

    if (!data || error) {
        return null;
    }

    const initialValues: Choice[] = data.choices.map((choice: Choice) => ({
        name: choice.name,
        price: choice.price,
        approved: choice?.approved === true || choice?.approved === false ? choice.approved : true,
    }));

    return (
        <>
            <VideoScreen data={data} responseSent={responseSent} />
            <ChoicesForm initialValues={{ repairItems: initialValues }} />
            <AppDownload />
        </>
    );
};

export default connect(
    (state: Redux) => ({
        data: selectData(state),
        loading: selectLoading(state),
        error: selectError(state),
        responseSent: selectResponseSent(state),
    }),
    { getData },
)(Video);
