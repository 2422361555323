import { t } from 'i18next';
import { SagaIterator } from 'redux-saga';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { openNotification } from 'utils/notifications';

import * as actions from './actions';
import fetcher from './requests';

function* getData() {
    yield takeLatest(actions.Types.GET_DATA, function* handle(action: ReturnType<typeof actions.getData>) {
        try {
            const { caseId } = action.payload;
            const requests = fetcher();

            const data = yield requests.getData(caseId);
            yield put(actions.getDataSuccess(data));
        } catch (err) {
            yield put(actions.getDataFailure(err));
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
        }
    });
}

function* makeResponse() {
    yield takeLatest(actions.Types.MAKE_RESPONSE, function* handle(action: ReturnType<typeof actions.makeResponse>) {
        try {
            const { caseId, response } = action.payload;
            const requests = fetcher();

            const data = yield requests.postResponse(caseId, response);
            yield put(actions.makeResponseSuccess(data));
        } catch (err) {
            yield put(actions.makeResponseFailure(err));
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
        }
    });
}

export default function* videoSaga(): SagaIterator {
    yield fork(getData);
    yield fork(makeResponse);
}
