import createReducer from 'utils/createReducer';

import { AppInfoReducer } from './@types';
import { Types } from './actions';
import * as actions from './actions';

export const initialState: AppInfoReducer = {
    loading: false,
    error: null,
    data: null,
};

export default createReducer(initialState, {
    [Types.GET_APP_INFO]: (state: AppInfoReducer, action: ReturnType<typeof actions.getAppInfo>): AppInfoReducer => {
        return {
            ...state,
            loading: true,
        };
    },
    [Types.GET_APP_INFO_SUCCESS]: (state: AppInfoReducer, action: ReturnType<typeof actions.getAppInfoSuccess>): AppInfoReducer => {
        return {
            ...state,
            loading: false,
            error: null,
            data: action.payload.appInfo,
        };
    },
    [Types.GET_APP_INFO_FAILURE]: (state: AppInfoReducer, action: ReturnType<typeof actions.getAppInfoFailure>): AppInfoReducer => {
        return {
            ...state,
            error: action.payload.error,
            loading: false,
        };
    },
});
