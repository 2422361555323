import { Component } from 'react';
import { connect } from 'react-redux';
import { getFile } from 'store/modules/s3files/actions';
import { selectFile } from 'store/modules/s3files/selectors';
import { Redux } from 'typings';

interface Props {
    children?: (file: string) => any;
    name?: string;
    file?: string;
    getFile?: typeof getFile;
    isVideo?: boolean;
}

class FromS3 extends Component<Props> {
    componentDidMount() {
        const { name, getFile, file, isVideo } = this.props;

        if (!file) {
            getFile(name, isVideo);
        }
    }

    render() {
        const { children, file } = this.props;
        if (!file) {
            return null;
        }
        return children(file);
    }
}

export default connect(
    (state: Redux, props: Props) => ({
        file: selectFile(props.name)(state),
    }),
    { getFile },
)(FromS3);
