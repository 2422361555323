import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router';
import { selectToken } from 'store/modules/auth/selectors';
import { Redux } from 'typings';

interface Props extends RouteProps {
    token?: string;
}

class UnsignedRoute extends React.Component<Props> {
    render() {
        const { token, component: Component, ...rest } = this.props;
        return <Route {...rest} render={(props: RouteComponentProps<any>) => (!token ? <Component {...props} /> : <Redirect to="/" />)} />;
    }
    AnyAction;
}
export default connect(
    (state: Redux) => ({
        token: selectToken(state),
    }),
    null,
)(UnsignedRoute);
