import Button from 'antd/es/button';
import styled from 'styled-components';
import { ThemeProps } from 'stylesheets/GlobalTheme';

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media screen and (min-width: ${(props: ThemeProps) => props.theme.resolution.screenMd}) {
        justify-content: flex-start;
    }
`;

export const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const LoadMore = styled(Button)`
    width: 300px;
`;
