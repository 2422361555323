import CenteredSpin from 'components/CenteredSpin';
import uniqBy from 'lodash-es/uniqBy';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { match } from 'react-router';
import { Cartype } from 'store/modules/manuals/@types';
import { getManual } from 'store/modules/manuals/actions';
import { selectIsDetailLoading, selectManual } from 'store/modules/manuals/selectors';
import { Redux } from 'typings';
import ManualForm from 'view/containers/ManualForm';

interface Props {
    getManual?: typeof getManual;
    manual?: any;
    loading?: boolean;
    match?: match<{ manualId: string }>;
}
class ManualDetail extends Component<Props> {
    componentDidMount() {
        const { match, getManual } = this.props;
        getManual(match.params.manualId);
    }

    getCarTypes = (carType: any) => {
        const { manual } = this.props;
        const years = manual.cartypes.filter((types: Cartype) => types.model === carType.model).map((year: Cartype) => year.cartype);

        return {
            name: carType.model,
            years,
        };
    };

    render() {
        const { loading, manual } = this.props;

        if (!manual || loading) {
            return <CenteredSpin />;
        }

        const cartypes = uniqBy(manual.cartypes, 'model').map(this.getCarTypes);

        const initialValues = { ...manual, cartypes };
        return <ManualForm initialValues={initialValues} manualId={manual.manualId} />;
    }
}

export default connect(
    (state: Redux) => ({
        manual: selectManual(state),
        loading: selectIsDetailLoading(state),
    }),
    { getManual },
)(ManualDetail);
