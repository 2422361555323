import Button from 'antd/es/button';
import moment from 'moment';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Events } from 'store/modules/appInfo/constants';
import { Event, Ticket } from 'store/modules/tickets/@types';
import { NotificationType } from 'store/modules/tickets/constants';
import { cancelNotification } from 'store/modules/tickets/detail/actions';
import { selectCaseId, selectTicket } from 'store/modules/tickets/detail/selectors';
import { Redux } from 'typings';
import { StyledCard, StyledDate, StyledHeader } from './styled';
import { Props, ScheduledData } from './types';

const sentFinder = (eventId: string) => (event: Event) =>
    (Events.Notification.CANCELLED === event.eventName || Events.Notification.SENT === event.eventName) &&
    event.meta.notificationId === eventId;

interface ScheduledProps extends Props<ScheduledData> {
    cancelNotification?: typeof cancelNotification;
    ticket?: Ticket;
    caseId: string;
    meta: {
        notificationId: string;
    };
}

const bodyWithDate = (text: string, time: string, dateFormat: string = 'DD. MM. YYYY'): string => `
    ${text}: ${moment(time).format(dateFormat)}`;

class Scheduled extends Component<ScheduledProps> {
    handleCancelNotification = () => {
        const { cancelNotification, caseId, eventId } = this.props;
        cancelNotification(eventId, caseId);
    };

    renderTitle = () => {
        const {
            data,
            timestamp,
            events,
            t,
            meta: { notificationId },
        } = this.props;

        const { data: notificationData } = data;
        const alreadySent = sentFinder(notificationId);
        const shouldDisplayCancel = !events.find(alreadySent);

        switch (notificationData.type) {
            case (notificationData.type = NotificationType.SERVICE_REMINDER_CONFIRM):
                return (
                    <>
                        <StyledHeader>{t('containers.EventTimeline.eventTypes.scheduled')}</StyledHeader>
                        <StyledDate>{moment(timestamp).format('DD. MM. YYYY - HH:mm')}</StyledDate>
                        {shouldDisplayCancel && (
                            <Button icon="delete" type="danger" size="small" onClick={this.handleCancelNotification}>
                                {t('containers.EventTimeline.cancelNotification')}
                            </Button>
                        )}
                    </>
                );
            case (notificationData.type = NotificationType.SERVICE_STK_REMINDER_PROBE):
                return (
                    <>
                        <StyledHeader>{t('containers.EventTimeline.eventTypes.scheduled')}</StyledHeader>
                        <StyledDate>{moment(timestamp).format('DD. MM. YYYY - HH:mm')}</StyledDate>
                    </>
                );
            case (notificationData.type = NotificationType.CARD_EXPIRE_NOTICE):
                return (
                    <>
                        <StyledHeader>{t('containers.EventTimeline.eventTypes.scheduled')}</StyledHeader>
                        <StyledDate> {moment(timestamp).format('DD. MM. YYYY - HH:mm')}</StyledDate>
                    </>
                );
            case (notificationData.type = NotificationType.SERVICE_REMINDER_PROBE):
                return (
                    <>
                        <StyledHeader>{t('containers.EventTimeline.eventTypes.scheduled')}</StyledHeader>
                        <StyledDate>{moment(timestamp).format('DD. MM. YYYY - HH:mm')}</StyledDate>
                        {shouldDisplayCancel && (
                            <Button icon="delete" type="danger" size="small" onClick={this.handleCancelNotification}>
                                {t('containers.EventTimeline.cancelNotification')}
                            </Button>
                        )}
                    </>
                );
            case (notificationData.type = NotificationType.WARRANTY_EXPIRE_NOTICE):
                return (
                    <>
                        <StyledHeader>{t('containers.EventTimeline.eventTypes.scheduled')}</StyledHeader>
                        <StyledDate>{moment(timestamp).format('DD. MM. YYYY - HH:mm')}</StyledDate>
                    </>
                );
            default:
                return null;
        }
    };
    renderBody = () => {
        const { data, ticket, t } = this.props;
        const { data: notificationData } = data;
        const { note } = ticket.data;

        switch (notificationData.type) {
            case (notificationData.type = NotificationType.SERVICE_REMINDER_CONFIRM):
                return (
                    <p>
                        {bodyWithDate(
                            t('containers.EventTimeline.eventTypes.servisConfirmation'),
                            notificationData.date,
                            'DD. MM. YYYY - HH:mm',
                        )}
                    </p>
                );
            case (notificationData.type = NotificationType.SERVICE_STK_REMINDER_PROBE):
                return <p>{bodyWithDate(t('containers.EventTimeline.dataParams.stkExpiration'), notificationData.date)}</p>;
            case (notificationData.type = NotificationType.SERVICE_REMINDER_PROBE):
                return (
                    <>
                        <p>{bodyWithDate(t('containers.EventTimeline.eventTypes.servisReminder'), notificationData.date)}</p>
                        <p>{!!note && t('routes.Tickets.columns.note', { note })}</p>
                    </>
                );
            case (notificationData.type = NotificationType.CARD_EXPIRE_NOTICE):
                return <p>{bodyWithDate(t('containers.EventTimeline.dataParams.cardExpirationNote'), notificationData.date)}</p>;
            case (notificationData.type = NotificationType.WARRANTY_EXPIRE_NOTICE):
                return <p>{bodyWithDate(t('containers.EventTimeline.dataParams.warrantyExpirationNote'), notificationData.date)}</p>;
            default:
                return null;
        }
    };
    render() {
        return (
            <StyledCard type="inner" title={this.renderTitle()}>
                {this.renderBody()}
            </StyledCard>
        );
    }
}

export default compose<any>(
    connect(
        (state: Redux) => ({
            caseId: selectCaseId(state),
            ticket: selectTicket(state),
        }),
        { cancelNotification },
    ),
    translate(),
)(Scheduled);
