import { api } from 'utils/request';

import { getModelsParams, getYearsParams } from './utils/urlParams';

declare const Conf;

const fetcher = (token: string) => ({
    getCarTypes: (models?: string[], years?: string[]) =>
        api(`apps/${Conf.APP_ID}/cartypes?limit=999${getModelsParams(models)}${getYearsParams(years)}`, {
            method: 'GET',
            headers: {
                authorization: token,
            },
        }),
    updateCarType: (carTypeId: string, image: string) =>
        api(`apps/${Conf.APP_ID}/cartypes/${carTypeId}`, {
            method: 'PATCH',
            headers: {
                authorization: token,
            },
            body: {
                image,
            },
        }),
});

export default fetcher;
