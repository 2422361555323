import { t } from 'i18next';
import uniq from 'lodash-es/uniq';
import { push } from 'react-router-redux';
import { SagaIterator } from 'redux-saga';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { Routes } from 'store/modules/ui/constants';
import { openNotification } from 'utils/notifications';
import { uploadAppData } from 'utils/s3';
import { getToken } from 'utils/withToken';

import { FormCartype } from './@types';
import * as actions from './actions';
import fetcher from './requests';

function normalizeCartypes(cartypes: FormCartype[]): string[] {
    return uniq(cartypes.reduce((acc: string[], cartype: { years: string[] }) => [...acc, ...cartype.years], []));
}

export function* getFilters() {
    yield takeLatest(actions.Types.GET_FILTERS, function* handle(action: ReturnType<typeof actions.getFilters>) {
        try {
            const requests = fetcher(yield getToken());

            const filters = yield Promise.all([requests.getFilters('model'), requests.getFilters('year')]);
            yield put(actions.getFiltersSuccess(filters));
        } catch (err) {
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
            yield put(actions.getFiltersFailure(err));
        }
    });
}

export function* getManuals() {
    yield takeLatest(actions.Types.GET_MANUALS, function* handle(action: ReturnType<typeof actions.getManuals>) {
        try {
            const requests = fetcher(yield getToken());

            const manuals = yield requests.getManuals(action.payload.params);
            const { docs, ...pagination } = manuals;

            yield put(actions.getManualsSuccess(docs, pagination));
        } catch (err) {
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
            yield put(actions.getManualsFailure(err));
        }
    });
}

export function* getManual() {
    yield takeLatest(actions.Types.GET_MANUAL, function* handle(action: ReturnType<typeof actions.getManual>) {
        try {
            const requests = fetcher(yield getToken());

            const manual = yield requests.getManual(action.payload.manualId);

            yield put(actions.getManualSuccess(manual));
        } catch (err) {
            yield put(actions.getManualFailure(err));
            yield put(push(Routes.MANUALS.path));
            if (err.response.data.type === 'resource_not_found') {
                return yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.manualNotFound'));
            }
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
        }
    });
}

export function* deleteManual() {
    yield takeLatest(actions.Types.DELETE_MANUAL, function* handle(action: ReturnType<typeof actions.deleteManual>) {
        try {
            const requests = fetcher(yield getToken());

            const manual = yield requests.deleteManual(action.payload.manualId);

            yield put(actions.deleteManualSuccess(manual));
            yield call(openNotification, 'success', t('apiRequest.success.title'), t('apiRequest.success.manualDeleted'));
            yield put(push(Routes.MANUALS.path));
        } catch (err) {
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
            yield put(actions.deleteManualFailure(err));
        }
    });
}

function* createManual() {
    yield takeLatest(actions.createManual.REQUEST, function* handle(action: ReturnType<typeof actions.createManual>) {
        try {
            const { payload } = action;
            const { name, cartypes, description } = payload;

            if (!name) {
                yield call(openNotification, 'error', t('validation.title'), t('validation.csManualMissing'));
                return yield put(actions.createManual.failure(t('validation.csManualMissing')));
            }

            if (!name.cs) {
                yield call(openNotification, 'error', t('validation.title'), t('validation.csManualMissing'));
                return yield put(actions.createManual.failure(t('validation.csManualMissing')));
            }

            if (!cartypes) {
                yield call(openNotification, 'error', t('validation.title'), t('validation.carTypeMissing'));
                return yield put(actions.createManual.failure(t('validation.carTypeMissing')));
            }

            const requests = fetcher(yield getToken());
            // Upload files to S3
            const fileNames = { cs: '', en: '' };
            fileNames.cs = (yield uploadAppData(name.cs)).key;

            if (name.en) {
                fileNames.en = (yield uploadAppData(name.en)).key;
            }

            const body = {
                description,
                cartypes: normalizeCartypes(cartypes),
                name: fileNames,
            };

            const manual = yield requests.createManual(body);
            yield put(actions.createManual.success(manual));
            yield call(openNotification, 'success', t('apiRequest.success.title'), t('apiRequest.success.manualAdded'));
            yield put(push(Routes.MANUALS.path));
        } catch (err) {
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
            yield put(actions.createManual.failure(err));
        }
    });
}

function* updateManual() {
    yield takeLatest(actions.updateManual.REQUEST, function* handle(action: ReturnType<typeof actions.updateManual>) {
        try {
            const { payload } = action;
            const { name, cartypes, manualId, description } = payload;

            if (!name.cs) {
                yield call(openNotification, 'error', t('validation.title'), t('validation.csManualMissing'));
                return yield put(actions.updateManual.failure(t('validation.csManualMissing')));
            }

            if (!cartypes.length) {
                yield call(openNotification, 'error', t('validation.title'), t('validation.carTypeMissing'));
                return yield put(actions.updateManual.failure(t('validation.carTypeMissing')));
            }

            const requests = fetcher(yield getToken());
            // Upload files to S3
            const fileNames = { cs: '', en: '' };

            if (name.cs.file) {
                fileNames.cs = (yield uploadAppData(name.cs)).key;
            } else {
                fileNames.cs = name.cs;
            }

            if (name.en) {
                if (name.en.file) {
                    fileNames.en = (yield uploadAppData(name.en)).key;
                } else {
                    fileNames.en = name.en;
                }
            }

            const body = {
                description,
                cartypes: normalizeCartypes(cartypes),
                name: fileNames,
            };

            const manual = yield requests.updateManual(manualId, body);
            yield put(actions.updateManual.success(manual));
            yield call(openNotification, 'success', t('apiRequest.success.title'), t('apiRequest.success.manualUpdated'));
            yield put(push(Routes.MANUALS.path));
        } catch (err) {
            yield put(actions.updateManual.failure(err));
        }
    });
}

export default function* manualsSage(): SagaIterator {
    yield fork(getFilters);
    yield fork(getManuals);
    yield fork(getManual);
    yield fork(createManual);
    yield fork(updateManual);
    yield fork(deleteManual);
}
