import Auth from 'aws-amplify/lib/Auth';
import { t } from 'i18next';
import isEmpty from 'lodash-es/isEmpty';
import { push } from 'react-router-redux';
import { SagaIterator } from 'redux-saga';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { selectSignedOutUserEmail, selectUser } from 'store/modules/auth/selectors';
import { Routes } from 'store/modules/ui/constants';
import { _get } from 'utils/get';
import { openNotification } from 'utils/notifications';

import * as actions from './actions';

function* signIn() {
    yield takeLatest(actions.signIn.REQUEST, function* handle(action: ReturnType<typeof actions.signIn>) {
        const { username, password } = action.payload;
        try {
            const user = yield Auth.signIn(username, password);
            if (_get(user, 'challengeName') === 'NEW_PASSWORD_REQUIRED') {
                yield put(push(Routes.PASSWORD_CHANGE.path));
                yield call(openNotification, 'warning', t('apiRequest.warning.title'), t('apiRequest.warning.passwordChange'));
                yield put(actions.signInSuccess(user));
            } else if (!isEmpty(_get(user, 'signInUserSession', 'accessToken', 'payload', 'cognito:groups'))) {
                const session = yield Auth.currentSession();
                yield put(actions.signInSuccess(user, session));
                yield put(push(Routes.TICKETS.path));
            } else {
                yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.noGroups'));
                yield put(actions.signInFailure());
                yield put(actions.signOut());
            }
        } catch (err) {
            if (err.code === 'UserNotFoundException' || err.code === 'NotAuthorizedException') {
                if (err.message === 'User is disabled') {
                    yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.userDisabled'));
                } else {
                    yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.signIn'));
                }
            } else {
                yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
            }
            yield put(actions.signInFailure(err));
        }
    });
}

function* changePassword() {
    yield takeLatest(actions.changePassword.REQUEST, function* handle(action: ReturnType<typeof actions.changePassword>) {
        const { newPassword } = action.payload;
        try {
            const user = yield select(selectUser);
            const email = user.challengeParam.userAttributes.email;
            yield Auth.completeNewPassword(user, newPassword, { email });
            yield put(actions.changePasswordSuccess());
            yield put(push(Routes.SIGNIN.path));
            yield call(openNotification, 'success', t('apiRequest.success.title'), t('apiRequest.success.passwordChanged'));
        } catch (err) {
            yield put(actions.changePasswordFailure(err));
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.passwordChanged'));
        }
    });
}

function* resetPassword() {
    yield takeLatest(actions.resetPassword.REQUEST, function* handle(action: ReturnType<typeof actions.resetPassword>) {
        const { email } = action.payload;
        try {
            yield Auth.forgotPassword(email);

            yield put(actions.resetPasswordSuccess(email));
            yield put(push(Routes.FORGOTTEN_PASSWORD_CONFIRM.path));
            yield call(openNotification, 'success', t('apiRequest.success.title'), t('apiRequest.success.resetPassword'));
        } catch (err) {
            if (err.code === 'UserNotFoundException') {
                yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.emailNotFound'));
            } else {
                yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
            }
            yield put(actions.resetPasswordFailure(err));
        }
    });
}

function* resetPasswordConfirm() {
    yield takeLatest(actions.resetPasswordConfirm.REQUEST, function* handle(action: ReturnType<typeof actions.resetPasswordConfirm>) {
        const { code, newPassword } = action.payload;
        const email = yield select(selectSignedOutUserEmail);
        try {
            yield Auth.forgotPasswordSubmit(email, code, newPassword);

            yield put(actions.resetPasswordConfirmSuccess());
            yield put(push(Routes.SIGNIN.path));
            yield call(openNotification, 'success', t('apiRequest.success.title'), t('apiRequest.success.resetPasswordConfirm'));
        } catch (err) {
            if (err.code === 'CodeMismatchException') {
                yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.invalidCode'));
            } else {
                yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
            }
            yield put(actions.resetPasswordConfirmFailure(err));
        }
    });
}

function* signOut() {
    yield takeLatest(actions.Types.SIGN_OUT, function* handle(action: ReturnType<typeof actions.signIn>) {
        yield Auth.signOut();
        yield put(actions.signOutSuccess());
        yield put(push(Routes.SIGNIN.path));
    });
}

// function* refreshToken() {
//     yield takeLatest(actions.Types.REFRESH_TOKE, function* handle(action: ReturnType<typeof actions.signIn>) {
//         const { username, password } = action.payload;
//         try {
//             const user = yield Auth.signIn(username, password);
//             const session = yield Auth.currentSession();
//             yield put(actions.signInSuccess(user, session));
//             yield put(push('/'));
//         } catch (err) {
//             if (err.code === 'UserNotFoundException') {
//                 yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.signIn'));
//             } else {
//                 yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
//             }
//             yield put(actions.signInFailure(err));
//         }
//     });
// }

export default function* authSaga(): SagaIterator {
    yield fork(signIn);
    yield fork(signOut);
    yield fork(changePassword);
    yield fork(resetPassword);
    yield fork(resetPasswordConfirm);
    // yield fork(refreshToken);
}
