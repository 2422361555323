import { t } from 'i18next';
import { push } from 'react-router-redux';
import { SagaIterator } from 'redux-saga';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { selectSymbolId, selectVariantId } from 'store/modules/symbols/selectors';
import { Routes } from 'store/modules/ui/constants';
import { openNotification } from 'utils/notifications';
import { uploadAppData } from 'utils/s3';
import { getToken } from 'utils/withToken';

import * as actions from './actions';
import { allowedImageTypes, FILE_SIZE } from './constants';
import * as ApiService from './requests';

function* getSymbols() {
    yield takeLatest(actions.Types.GET_SYMBOLS, function* handle() {
        try {
            const token = yield getToken();
            const getSymbolsResponse = yield call(ApiService.getSymbols, token);
            yield put(actions.getSymbolsSuccess(getSymbolsResponse));
        } catch (err) {
            yield put(actions.getSymbolsFailure(err));
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
        }
    });
}

function* createSymbol() {
    yield takeLatest(actions.createSymbol.REQUEST, function* handle(action: ReturnType<typeof actions.createSymbol>) {
        try {
            const token = yield getToken();
            const { nameCs, descriptionCs, nameEn, descriptionEn, symbolImage, ...rest } = action.payload;

            if (!symbolImage) {
                yield call(openNotification, 'error', t('validation.title'), t('validation.symbolImageMissing'));
                return yield put(actions.createSymbol.failure(t('validation.symbolImageMissing')));
            }

            if (symbolImage.file.size > FILE_SIZE || !allowedImageTypes.includes(symbolImage.file.type)) {
                yield call(openNotification, 'error', t('validation.title'), t('validation.symbolImageInvalid'));
                return yield put(actions.createSymbol.failure(t('validation.symbolImageInvalid')));
            }

            symbolImage.name = (yield uploadAppData(symbolImage)).key;

            const modelForServer = {
                ...rest,
                image: symbolImage.name,
                name: { cs: nameCs, en: nameEn },
                description: { cs: descriptionCs, en: descriptionEn },
            };
            const createSymbolResponse = yield call(ApiService.createSymbol, modelForServer, token);
            yield put(push(Routes.SYMBOLS.path));
            yield put(actions.createSymbolSuccess(createSymbolResponse));
            yield call(openNotification, 'success', t('apiRequest.success.title'), t('apiRequest.success.symbolCreated'));
        } catch (err) {
            yield put(actions.createSymbolFailure(err));
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
        }
    });
}

function* getSymbol() {
    yield takeLatest(actions.Types.GET_SYMBOL, function* handle(action: ReturnType<typeof actions.getSymbol>) {
        try {
            const { symbolId } = action.payload;
            const token = yield getToken();
            const symbol = yield call(ApiService.getSymbol, symbolId, token);
            yield put(actions.getSymbolSuccess(symbol));
        } catch (err) {
            yield put(actions.getSymbolFailure(err));
        }
    });
}

function* updateSymbol() {
    yield takeLatest(actions.updateSymbol.REQUEST, function* handle(action: ReturnType<typeof actions.updateSymbol>) {
        try {
            const token = yield getToken();
            const symbolId = yield select(selectSymbolId);
            const variantId = yield select(selectVariantId);
            const { nameCs, descriptionCs, nameEn, descriptionEn, symbolImage, ...rest } = action.payload;

            if (!symbolImage?.file) {
                yield call(openNotification, 'error', t('validation.title'), t('validation.symbolImageMissing'));
                return yield put(actions.updateSymbol.failure(t('validation.symbolImageMissing')));
            }

            if (symbolImage.file) {
                if (symbolImage.file.size > FILE_SIZE || !allowedImageTypes.includes(symbolImage.file.type)) {
                    yield call(openNotification, 'error', t('validation.title'), t('validation.symbolImageInvalid'));
                    return yield put(actions.updateSymbol.failure(t('validation.symbolImageInvalid')));
                }
            }

            symbolImage.name = (yield uploadAppData(symbolImage)).key;

            const modelForServer = {
                ...rest,
                image: symbolImage.name || symbolImage,
                variantId,
                name: { cs: nameCs, en: nameEn },
                description: { cs: descriptionCs, en: descriptionEn },
            };
            yield call(ApiService.updateSymbol, modelForServer, symbolId, token);
            yield put(push(Routes.SYMBOLS.path));
            yield call(openNotification, 'success', t('apiRequest.success.title'), t('apiRequest.success.symbolUpdated'));
            yield put(actions.updateSymbol.success());
        } catch (err) {
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
            yield put(actions.updateSymbol.failure());
        }
    });
}

function* deleteSymbol() {
    yield takeLatest(actions.Types.DELETE_SYMBOL, function* handle(action: ReturnType<typeof actions.deleteSymbol>) {
        try {
            const { symbolId } = action.payload;
            const token = yield getToken();
            yield call(ApiService.deleteSymbol, symbolId, token);
            yield put(push(Routes.SYMBOLS.path));
            yield call(openNotification, 'success', t('apiRequest.success.title'), t('apiRequest.success.symbolDeleted'));
            yield put(actions.deleteSymbolSuccess());
        } catch (err) {
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
            yield put(actions.deleteSymbolFailure(err));
        }
    });
}

export default function* authSaga(): SagaIterator {
    yield fork(getSymbols);
    yield fork(createSymbol);
    yield fork(getSymbol);
    yield fork(updateSymbol);
    yield fork(deleteSymbol);
}
