import React from 'react';
import { ITranslator } from 'typings';
import FromS3 from 'view/components/FromS3';

import { ContentWrapper, IconWrapper, StyledImg, TextWrapper, Wrapper } from './styled';

interface Props extends ITranslator {
    onOpenSymbolDetail: () => void;
    name: { cs: string; en: string };
    image: string;
}

class SymbolCard extends React.Component<Props> {
    render() {
        const { onOpenSymbolDetail, name, image } = this.props;
        return (
            <Wrapper onClick={onOpenSymbolDetail}>
                <IconWrapper>
                    <FromS3 name={image}>{(url: string) => <StyledImg src={url} alt="icon" />}</FromS3>
                </IconWrapper>
                <ContentWrapper>
                    <TextWrapper>{name.cs}</TextWrapper>
                    <TextWrapper>{name.en}</TextWrapper>
                </ContentWrapper>
            </Wrapper>
        );
    }
}

export default SymbolCard;
