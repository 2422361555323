import { api } from 'utils/request';

import { Contact } from './@types';

declare const Conf;
const APP_ID: string = Conf.APP_ID;

export default class ApiRequests {
    public static async getContacts(token: string) {
        return api(`/apps/${APP_ID}/contacts`, {
            method: 'GET',
            headers: {
                authorization: token,
            },
        });
    }
    public static async getContact(contactId: string, token: string) {
        return api(`/apps/${APP_ID}/contacts/${contactId}`, {
            method: 'GET',
            headers: {
                authorization: token,
            },
        });
    }
    public static async createContact(contact: Contact, token: string) {
        return api(`/apps/${APP_ID}/contacts`, {
            method: 'POST',
            headers: {
                authorization: token,
            },
            body: contact,
        });
    }
    public static async updateContact(contact: Contact, contactId: Contact['_id'], token: string) {
        return api(`/apps/${APP_ID}/contacts/${contactId}`, {
            method: 'PUT',
            headers: {
                authorization: token,
            },
            body: contact,
        });
    }
    public static async deleteContact(contactId: Contact['_id'], token: string) {
        return api(`/apps/${APP_ID}/contacts/${contactId}`, {
            method: 'DELETE',
            headers: {
                authorization: token,
            },
        });
    }
}
