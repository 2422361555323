import Breadcrumb from 'antd/es/breadcrumb';
import Layout from 'antd/es/layout';
import styled from 'styled-components';
import { ThemeProps } from 'stylesheets/GlobalTheme';

export const Title = styled.h1`
    color: ${(props: ThemeProps) => props.theme.colors.white};
    font-size: 15px;
    text-align: center;
`;

export const StyledBreadcrumb = styled(Breadcrumb)`
    margin: 16px 0;
`;

export const StyledHeader = styled(Layout.Header)`
    background: ${(props: ThemeProps) => props.theme.colors.white};
    padding: 0;
`;

export const StyledContent = styled(Layout.Content)`
    margin: 0 16px;
`;

export const StyledLayout = styled(Layout)`
    min-height: 100vh;
`;

export const InnerContent = styled.div`
    margin: 24px 24px 0;
`;

export const User = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
`;

export const ScanCodeWrap = styled.div`
    width: 100%;
    padding-left: 38px;
    padding-right: 38px;
    padding-top: 12px;
`;

export const Icon = styled.img`
    margin: 0 20px;
    width: 32px;
`;

export const Email = styled.span`
    line-height: normal;
`;

export const Signout = styled.span`
    line-height: normal;
    width: max-content;
    :hover {
        font-weight: bold;
        cursor: pointer;
    }
`;

export const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
    height: 100%;
`;
