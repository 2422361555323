import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
`;

export const Logo = styled.img`
    margin: 0 auto;
    width: 280px;
    margin-bottom: 40px;
`;
