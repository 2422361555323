import React from 'react';
import { TranslationFunction } from 'i18next';
import { translate } from 'react-i18next';

import { UserDocument } from 'store/modules/customers/@types';
import { ITranslator } from 'typings';
import { StyledLinkOutlined, StyledTable } from './styled';
import { Button, Card, Popconfirm } from 'antd';
import DocumentTypeTag from './DocumentTypeTag';
import PageHeader from 'ant-design-pro/lib/PageHeader';
import { translateDocumentType } from 'utils/translate';
import { allCustomerDocumentTypes } from 'utils/customerDocumentTypes';

const toDataSource = ({ docId, fileUrl, name, type, s3Key }: UserDocument) => ({
    fileUrl,
    name,
    type,
    s3Key,
    docId,
    key: docId,
    nameColumnKey: `${docId}-name`,
    typeColumnKey: `${docId}-type`,
    fileUrlColumnKey: `${docId}-fileUrl`,
    deleteColumnKey: `${docId}-delete`,
});

type RowType = ReturnType<typeof toDataSource>;

const toFilter = (documentType: string) => ({ value: documentType, text: translateDocumentType(documentType) });

const makeColumns = (t: TranslationFunction, onDelete: Props['onDelete']) => [
    {
        title: t('routes.CustomerDetail.customerDocumentsTable.documentType'),
        dataIndex: 'type',
        key: 'typeColumnKey',
        filters: allCustomerDocumentTypes.map(toFilter),
        onFilter: (value: string, { type }: RowType) => value === type,
        render: (_: string, row: RowType) => <DocumentTypeTag type={row.type} />,
    },
    {
        title: t('routes.CustomerDetail.customerDocumentsTable.documentName'),
        dataIndex: 'name',
        key: 'nameColumnKey',
    },
    {
        key: 'fileUrlColumnKey',
        render: (_: string, row: RowType) => (
            <a href={row.fileUrl}>
                <StyledLinkOutlined /> {row.s3Key}
            </a>
        ),
    },
    {
        key: 'deleteColumnKey',
        render: (_: string, { docId }: RowType) => (
            <Popconfirm
                title={t('routes.CustomerDetail.customerDocumentsTable.deleteWarning')}
                okText={t('routes.CustomerDetail.customerDocumentsTable.confirmDelete')}
                cancelText={t('routes.CustomerDetail.customerDocumentsTable.cancelDelete')}
                onConfirm={() => onDelete(docId)}
            >
                <Button type="danger">{t('routes.CustomerDetail.customerDocumentsTable.delete')}</Button>
            </Popconfirm>
        ),
    },
];

interface Props extends ITranslator {
    documents: UserDocument[];
    onDelete: (docId: string) => void;
}

const CustomerDocumentsTable = ({ documents, onDelete, t }: Props) => {
    const dataSource = documents.map(toDataSource);
    const columns = makeColumns(t, onDelete);

    return (
        <>
            <PageHeader itemRender={null} title={t('routes.CustomerDetail.customerDocumentsTable.title')} />
            <Card style={{ marginBottom: '50px' }} bordered={false}>
                <StyledTable pagination={false} columns={columns} dataSource={dataSource} />
            </Card>
        </>
    );
};

export default translate()(CustomerDocumentsTable);
