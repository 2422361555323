import { api } from 'utils/request';
import { Choice } from './@types';

const fetcher = () => ({
    getData: (caseId: string) =>
        api(`public/approval/${caseId}`, {
            method: 'GET',
        }),
    postResponse: (caseId: string, response: Choice[]) =>
        api(`public/approval/${caseId}/respond`, {
            method: 'POST',
            body: {
                choices: response,
            },
        }),
});

export default fetcher;
