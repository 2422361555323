import { AxiosError } from 'axios';

export enum Types {
    // Get files
    GET_FILE = 'file/GET_FILE',
    GET_FILE_SUCCESS = 'file/GET_FILE_SUCCESS',
    GET_FILE_FAILURE = 'file/GET_FILE_FAILURE',
}

export const getFile = (name: string, isVideo?: boolean) => ({
    type: Types.GET_FILE,
    payload: {
        name,
        isVideo,
    },
});

export const getFileSuccess = (url: string, name: string) => ({
    type: Types.GET_FILE_SUCCESS,
    payload: {
        url,
        name,
    },
});

export const getFileFailure = (error: AxiosError) => ({
    type: Types.GET_FILE_FAILURE,
    payload: { error },
});
