import styled from 'styled-components';
import { ThemeProps } from 'stylesheets/GlobalTheme';

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 300px;
    margin: 20px;
    border: 1px solid ${(props: ThemeProps) => props.theme.colors.lightGrey};
    border-radius: 5%;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
`;

export const TextWrapper = styled.span`
    padding: 0 15px;
    text-align: center;
    font-weight: bold;
    font-size: ${(props: ThemeProps) => props.theme.fontSize.medium};
    :first-child {
        margin-bottom: 10px;
    }
    :last-child {
        margin-bottom: 20px;
    }
`;

export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
`;

export const StyledImg = styled.img`
    height: 60px;
    width: auto;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 15px;
`;
