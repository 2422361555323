import * as React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { ITranslator } from 'typings';
import FromS3 from 'view/components/FromS3';

interface Props extends ITranslator {}

interface State {}

class Files extends React.Component<Props, State> {
    render() {
        return (
            <div>
                {/* These are examples of rendering files from S3 */}
                <FromS3 name="manuals/image.png">{(url: string) => <img src={url} alt="manuals/image.png" />}</FromS3>
                <FromS3 name="manuals/someNonExisting.png">{(url: string) => <img src={url} alt="manuals/image.png" />}</FromS3>
                <FromS3 name="idonotexist.txt">{(url: string) => <p>{url}</p>}</FromS3>
            </div>
        );
    }
}

export default compose<any>(connect(), translate(), withRouter)(Files);
