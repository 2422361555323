import styled from 'styled-components';

export const TextWrapper = styled.span`
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
    font-size: 42px;
    font-weight: bold;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

export const Images = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const HeartWrapper = styled.img`
    width: 60px;
    padding-bottom: 80px;
`;
