import { createGlobalStyle } from 'styled-components';

const screenXll = 1600;
const screenXl = 1200;
const screenLg = 992;
const screenMd = 768;
const screenSm = 576;
const screenXs = 480;

const theme = {
    colors: {
        white: '#fff',
        black: '#000',
        lightGrey: '#bdbfc1',
        lighterGrey: '#f9f9f9',
        antdGrey: '#aaa',
        antdFilter: '#108ee9',
        success: '#52c41a',
        grey: '#e8e8e8',
        primary: '#1590FF',
        failure: '#f5222d',
        warning: '#faad14',
        highlight: '#F69494',
    },
    resolution: {
        screenXs: screenXs + 'px',
        screenXsMin: screenXs + 'px',
        screenXsMax: screenSm - 1 + 'px',
        screenSm: screenSm + 'px',
        screenSmMin: screenSm + 'px',
        screenSmMax: screenMd - 1 + 'px',
        screenMd: screenMd + 'px',
        screenMdMin: screenMd + 'px',
        screenMdMax: screenLg - 1 + 'px',
        screenLg: screenLg + 'px',
        screenLgMin: screenLg + 'px',
        screenLgMax: screenXl - 1 + 'px',
        screenXl: screenXl + 'px',
        screenXlMin: screenXl + 'px',
        screenXlMax: screenXll - 1 + 'px',
        screenXll: screenXll + 'px',
        screenXllMin: screenXll + 'px',
    },
    fontFamily: {
        global: 'SF',
    },
    fontSize: {
        xsmall: '1rem',
        small: '1.2rem',
        medium: '1.4rem',
        large: '1.6rem',
    },
};

export type ThemeProps = { theme: Readonly<typeof theme> };

export const GlobalStyle = createGlobalStyle`
    *, :after, :before {
        box-sizing: border-box;
    }

    html {
        font-size: 10px;
    }

    .antd-pro-page-header-pageHeader {
        padding: 16px 24px 0 24px;
    }

    .antd-pro-page-header-action {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0 !important;
    }

    .ant-time-picker {
        width: unset;
    }

    .ant-form-item-control-wrapper {
        width: 100%;
        float: left;

        @media (min-width: ${theme.resolution.screenSm}){
            width: 50%;
        }

        @media (min-width: ${theme.resolution.screenMd}){
            width: 41.66666667%;
        }

        .ant-form-item-children, .ant-calendar-picker, .ant-time-picker{
            display: block;
        }
    }

    .anticon {
      vertical-align: 0.2rem;
    }
    
    .ant-form-item-label {
        text-align: left;
        float: left;


        @media (min-width: ${theme.resolution.screenSm}){
        text-align: right;
            width: 29.16666667%;
        }
    }

    .ant-table-row:hover {
        cursor: pointer;
    }
    @media (max-width: ${theme.resolution.screenXl}) {
        .antd-pro-description-list-detail {
            min-width: 200px;
        }
        form {
            .ant-form-item-children {
                min-width: 200px;
            }
        }
    }
`;

export default theme;
