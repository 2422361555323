import DescriptionList from 'ant-design-pro/lib/DescriptionList';
import Icon from 'antd/es/icon';
import styled from 'styled-components';

const { Description } = DescriptionList;

export const InfoIcon = styled(Icon)`
    margin-right: 1rem;
`;

export const StyledDescription = styled(Description)`
    .antd-pro-description-list-detail {
        min-width: 200px;
    }
`;
