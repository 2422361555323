import React from 'react';

import { AppStore, StyledLink, Wrapper } from './styled';

const appleStore = require('assets/appleStore.svg');
const googleStore = require('assets/googleStore.svg');

declare const Conf;

export default () => {
    return (
        <Wrapper>
            <div>
                <StyledLink href={Conf.APP_LINKS.APPLE} target="_blank">
                    <AppStore src={appleStore} alt="apple-app-store" />
                </StyledLink>
                <StyledLink href={Conf.APP_LINKS.ANDROID} target="_blank">
                    <AppStore src={googleStore} alt="google-play-store" />
                </StyledLink>
            </div>
        </Wrapper>
    );
};
