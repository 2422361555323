import { t } from 'i18next';
import moment from 'moment';
import { NotificationType } from 'store/modules/tickets/constants';

import { EventDataProp } from '../types';

const dataParamsTranslation = 'containers.EventTimeline.dataParams';
const translate = (key: string): string => t(`${dataParamsTranslation}.${key}`);

export default (data: any): EventDataProp => {
    if (!data) {
        return null;
    }
    if (data.type === NotificationType.SERVICE_REMINDER_PROBE) {
        const value = translate(data.response ? 'accepts' : 'rejects');
        return { value };
    } else if (data.type === NotificationType.SERVICE_REMINDER_CONFIRM) {
        const label = translate(data.response ? 'confirms' : 'refuses');
        return { label, value: moment(data.serviceDate).format('DD. MM. YYYY - HH:mm') };
    } else if (data.type === NotificationType.CARD_EXPIRE_NOTICE) {
        const value = translate(data.response ? 'cardExpirationConfirmed' : 'cardExpirationNotConfirmed');
        return { value };
    } else if (data.type === NotificationType.SERVICE_STK_REMINDER_PROBE) {
        const value = translate(data.response ? 'stkConfirmed' : 'stkNotConfirmed');
        return { value };
    } else if (data.type === NotificationType.WARRANTY_EXPIRE_NOTICE) {
        const value = translate(data.response ? 'warrantyExpirationConfirmed' : 'warrantyExpirationNotConfirmed');
        return { value };
    }

    const value = data.note;
    const label = translate('note');
    return label && value ? { label, value } : null;
};
