import Badge from 'antd/es/badge';
import { UserType } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import React from 'react';

interface Props extends UserType {
    text: string;
    verified?: boolean;
}

const Name: React.SFC<Props> = ({ text, Enabled, verified, UserStatus }: Props) => {
    function getBadgeStatus(): 'success' | 'processing' | 'default' | 'error' | 'warning' {
        if (!Enabled) {
            return 'error';
        } else if (!verified || UserStatus !== 'CONFIRMED') {
            return 'warning';
        }
        return 'success';
    }

    return (
        <Badge dot status={getBadgeStatus()}>
            {text}
        </Badge>
    );
};

export default Name;
