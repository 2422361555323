import * as React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router';
import { compose } from 'redux';
import { selectCurrentPage, selectFlipAnimation } from 'store/modules/ui/selectors';
import { ITranslator, Redux } from 'typings';

interface Props extends ITranslator {}

interface State {}

class Homepage extends React.Component<Props, State> {
    render() {
        /* FIXME: In the future */
        return <Redirect to="/tickets" />;
    }
}

export default compose<any>(
    connect(
        (state: Redux) => ({
            currentPage: selectCurrentPage(state),
            flipAnimation: selectFlipAnimation(state),
        }),
        {},
    ),
    translate(),
    withRouter,
)(Homepage);
