import { SagaIterator } from 'redux-saga';
import { fork, put, takeEvery } from 'redux-saga/effects';
import { get } from 'utils/s3';

import * as actions from './actions';

export function* getFile() {
    yield takeEvery(actions.Types.GET_FILE, function* handle(action: ReturnType<typeof actions.getFile>) {
        try {
            const { name, isVideo } = action.payload;
            const url = yield get(name, isVideo);
            yield put(actions.getFileSuccess(url, name));
        } catch (err) {
            yield put(actions.getFileFailure(err));
        }
    });
}

export default function* manualsSage(): SagaIterator {
    yield fork(getFile);
}
