import Icon from 'antd/es/icon';
import Input from 'antd/es/input';
import styled from 'styled-components';
import theme, { ThemeProps } from 'stylesheets/GlobalTheme';

import AntdTable from 'antd/es/table';

export const SearchInput = styled(Input)`
    width: 130px;
`;

export const FilterIcon = styled(Icon)`
    color: ${(props: any) => (props.filtered === 'true' ? `${theme.colors.antdFilter} !important` : `${theme.colors.antdGrey} !important`)};
`;

export const StyledContainer = styled.div`
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
    background-color: ${(props: ThemeProps) => props.theme.colors.white};
`;

export const Table = styled(AntdTable)`
    .highlight {
        background-color: ${(props: ThemeProps) => props.theme.colors.highlight};
    }
`;
