import React, { Component } from 'react';
import { compose } from 'redux';
import { reduxForm, Form, InjectedFormProps, Field, submit } from 'redux-form';
import { translate } from 'react-i18next';
import { TextField, SelectField } from 'redux-form-antd';
import { ITranslator, Redux } from 'typings';
import { required } from 'utils/validation';
import UploadField from 'view/components/UploadField';
import PageHeader from 'ant-design-pro/lib/PageHeader';
import { Button, Card } from 'antd';
import { DocumentFormContent, FieldContainer } from './styled';
import { connect } from 'react-redux';
import { selectCustomerUploading } from 'store/modules/customers/selectors';
import { allCustomerDocumentTypes, CustomerDocumentType } from 'utils/customerDocumentTypes';
import { translateDocumentType } from 'utils/translate';

const toOption = (type: CustomerDocumentType) => ({ value: type, label: translateDocumentType(type) });

interface Props extends ITranslator, InjectedFormProps {
    submit: typeof submit;
    uploading: boolean;
}

class DocumentForm extends Component<Props> {
    render() {
        const { uploading, handleSubmit, t, submit } = this.props;
        const documentTypeOptions = allCustomerDocumentTypes.map(toOption);

        return (
            <>
                <PageHeader
                    itemRender={null}
                    title={t('containers.DocumentForm.title')}
                    action={
                        <Button type="primary" loading={uploading} onClick={() => submit('documentForm')}>
                            {t('containers.DocumentForm.saveDocument')}
                        </Button>
                    }
                />
                <Card style={{ marginBottom: '50px' }} bordered={false}>
                    <Form onSubmit={handleSubmit}>
                        <DocumentFormContent>
                            <FieldContainer>
                                <Field
                                    label={t('containers.DocumentForm.documentName')}
                                    name="documentName"
                                    hasFeedback={false}
                                    component={TextField}
                                    validate={[required]}
                                />
                                <Field
                                    placeholder={t('containers.DocumentForm.selectDocumentType')}
                                    options={documentTypeOptions}
                                    label={t('containers.DocumentForm.documentType')}
                                    name="documentType"
                                    hasFeedback={false}
                                    component={SelectField}
                                    validate={[required]}
                                />
                            </FieldContainer>
                            <Field
                                label={t('containers.DocumentForm.uploadDocument')}
                                awsFolder="documents"
                                name="document"
                                accept=".pdf"
                                type="button"
                                component={UploadField}
                            />
                        </DocumentFormContent>
                    </Form>
                </Card>
            </>
        );
    }
}

export default compose<any>(
    reduxForm({
        form: 'documentForm',
    }),
    translate(),
    connect(
        (state: Redux) => ({
            uploading: selectCustomerUploading(state),
        }),
        { submit },
    ),
)(DocumentForm);
