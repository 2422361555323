import { Component } from 'react';
import { connect } from 'react-redux';
import { App } from 'store/modules/appInfo/@types';
import { getAppInfo } from 'store/modules/appInfo/actions';
import { selectAppData } from 'store/modules/appInfo/selectors';
import { Redux } from 'typings';

interface Props {
    children?: () => any;
    getAppInfo: typeof getAppInfo;
    appInfo: App;
}

class WithAppInfo extends Component<Props> {
    componentDidMount() {
        const { appInfo, getAppInfo } = this.props;
        if (!appInfo) {
            getAppInfo();
        }
    }

    render() {
        const { children } = this.props;
        return children();
    }
}

export default connect(
    (state: Redux) => ({
        appInfo: selectAppData(state),
    }),
    { getAppInfo },
)(WithAppInfo);
