import PageHeader from 'ant-design-pro/lib/PageHeader';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import Modal from 'antd/es/modal';
import CenteredSpin from 'components/CenteredSpin';
import find from 'lodash-es/find';
import isEqual from 'lodash-es/isEqual';
import some from 'lodash-es/some';
import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { match } from 'react-router';
import { compose } from 'redux';
import { ExtendedUserType } from 'store/modules/users/@types';
import { getUser, getUserGroups, userAction } from 'store/modules/users/actions';
import { UserAction } from 'store/modules/users/constants';
import {
    selectUser,
    selectUserError,
    selectUserGroups,
    selectUserLoading,
    selectUserSending,
    selectUsersInGroups,
} from 'store/modules/users/selectors';
import { ITranslator, Redux, SelectorType } from 'typings';
import AddUser from 'view/containers/AddUser';

const confirm = Modal.confirm;

interface Props extends ITranslator {
    loading?: SelectorType<typeof selectUserLoading>;
    user?: ExtendedUserType;
    userGroups?: SelectorType<typeof selectUserGroups>;
    usersInGroups?: SelectorType<typeof selectUsersInGroups>;
    error?: SelectorType<typeof selectUserError>;
    sending?: SelectorType<typeof selectUserSending>;
    getUser?: typeof getUser;
    getUserGroups?: typeof getUserGroups;
    match?: match<{ userId: string }>;
    userAction?: typeof userAction;
}

class User extends React.Component<Props> {
    componentDidMount() {
        const { getUser, match, getUserGroups, usersInGroups } = this.props;
        if (!usersInGroups.length) {
            getUserGroups();
        } else {
            getUser(match.params.userId);
        }
    }

    componentDidUpdate(prevProps: Props) {
        const { usersInGroups, getUser, match } = this.props;

        if (!isEqual(prevProps.usersInGroups, usersInGroups) && some(usersInGroups, { Username: match.params.userId })) {
            getUser(match.params.userId);
        }
    }

    handleDelete = () => {
        const { userAction, match, t } = this.props;
        confirm({
            title: t('routes.User.deleteWarning'),
            okText: t('routes.User.okText'),
            okType: 'danger',
            cancelText: t('routes.User.cancelText'),
            onOk() {
                userAction(UserAction.DELETE, match.params.userId);
            },
        });
    };

    render() {
        const { user, loading, userAction, sending, userGroups, t } = this.props;

        if (!user || loading) {
            return <CenteredSpin />;
        }

        const _user = user as any;
        const attributesObject = _user.Attributes || _user.UserAttributes;
        const email = find(attributesObject, (attribute: { Name: string; Value: string }) => attribute.Name === 'email').Value;
        const userGroup = t(`userGroups.${_user.userGroup}`);
        const uId = find(attributesObject, (attribute: { Name: string; Value: string }) => attribute.Name === 'sub').Value;

        return (
            <>
                <PageHeader
                    itemRender={null}
                    title={email}
                    action={
                        <>
                            {user.Enabled ? (
                                <Button loading={sending} onClick={() => userAction(UserAction.DISABLE, user.Username)}>
                                    {t('routes.User.deactivate')}
                                </Button>
                            ) : (
                                <Button type="primary" loading={sending} onClick={() => userAction(UserAction.ENABLE, user.Username)}>
                                    {t('routes.User.activate')}
                                </Button>
                            )}
                            <Button type="danger" loading={sending} onClick={this.handleDelete}>
                                {t('routes.User.delete')}
                            </Button>
                        </>
                    }
                />
                <Card bordered={false}>
                    <AddUser edit userGroups={userGroups} initialValues={{ email, username: email, userGroup, uId }} />
                </Card>
            </>
        );
    }
}

export default compose<any>(
    connect(
        (state: Redux) => ({
            loading: selectUserLoading(state),
            user: selectUser(state),
            error: selectUserError(state),
            sending: selectUserSending(state),
            userGroups: selectUserGroups(state),
            usersInGroups: selectUsersInGroups(state),
        }),
        { getUser, getUserGroups, userAction },
    ),
    translate(),
)(User);
