import { AxiosError } from 'axios';
import { createSelector } from 'reselect';
import { Branch } from 'store/modules/appInfo/@types';
import { selectBranches } from 'store/modules/appInfo/selectors';
import { Ticket, TicketsReducer } from 'store/modules/tickets/@types';
import { selectTicketsSubState } from 'store/modules/tickets/selectors';

import { Filter, ListReducer, Pagination, TicketRow } from './@types.d';
import createRows from './utils/createRows';

const selectList = createSelector(selectTicketsSubState, (tickets: TicketsReducer): ListReducer => tickets.list);

export const selectTickets = createSelector(selectList, (list: ListReducer): Ticket[] => list.data);
export const selectRows = createSelector([selectTickets, selectBranches], (tickets: Ticket[], branches: Branch[]): TicketRow[] =>
    createRows(tickets, branches),
);
export const selectPagination = createSelector(selectList, (list: ListReducer): Pagination => list.pagination);

export const selectLoading = createSelector(selectList, (list: ListReducer): boolean => list.loading);
export const selectError = createSelector(selectList, (list: ListReducer): AxiosError => list.error);
export const selectFilter = createSelector(selectList, (list: ListReducer): Filter => list.filter);
