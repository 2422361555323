import { t } from 'i18next';
import moment from 'moment';
import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { get } from 'lodash';

import { Customer, Filter } from 'store/modules/customers/@types';
import { getCustomers, setFilter, setFilters, unassignedCar } from 'store/modules/customers/actions';
import {
    selectCustomers,
    selectCustomersError,
    selectCustomersLoading,
    selectCustomersRawCustomer,
    selectFilter,
} from 'store/modules/customers/selectors';
import { ITranslator, Redux, SelectorType } from 'typings';
import { CapitalizedSpan, StyledTable, FilterIcon } from './styled';
import { createDataSource } from './utils';
import SearchFilter from './SearchFilter';
import Tag from 'containers/customersList/Tag';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { Routes } from 'store/modules/ui/constants';

interface Props extends ITranslator, RouteComponentProps {
    getCustomers?: typeof getCustomers;
    setFilter?: typeof setFilter;
    setFilters?: typeof setFilters;
    unassignedCar?: typeof unassignedCar;
    loading?: SelectorType<typeof selectCustomersLoading>;
    customers?: SelectorType<typeof selectCustomers>;
    error?: SelectorType<typeof selectCustomersError>;
    dataSource?: Customer[];
    rawCustomers?: SelectorType<typeof selectCustomersRawCustomer>;
    filter?: Filter;
}

declare const Conf;
const PAGE_LIMIT: number = Conf.PAGE_LIMIT;
const sortByDate = (customers: Customer[]) =>
    customers.sort((a: Customer, b: Customer) => (new Date(b.createdAt) >= new Date(a.createdAt) ? 1 : -1));

class CustomersList extends React.Component<Props> {
    componentDidMount() {
        const initialPage = 1;
        this.handleFetchCustomers(initialPage);
    }

    handleFetchCustomers = (pagination?: any, filter?: Filter) => {
        const { getCustomers } = this.props;
        const current = get(pagination, 'current');
        getCustomers(current, filter ?? this.props.filter);
    };

    handleTableChange = (pagination: any, filter: Filter) => {
        const { setFilters } = this.props;
        setFilters(filter);
        this.handleFetchCustomers(pagination);
    };

    handlePadding(page: number) {
        this.handleFetchCustomers(page);
    }

    handleFilterChange = (name: string, value: string | string[]) => {
        const { setFilter } = this.props;
        setFilter(name, value);
    };

    handleFilterReset = (name: string) => {
        const { filter, setFilter } = this.props;
        setFilter(name, '');

        const newFilter = { ...filter, [name]: '' };
        this.handleFetchCustomers(undefined, newFilter);
    };

    createColumns = () => {
        const { filter } = this.props;

        return [
            {
                title: t('containers.CustomersList.createdAt'),
                width: 150,
                key: 'createdAt',
                render: (text: string, record: Customer) => record.createdAt && moment(record.createdAt).format('DD. MM. YYYY'),
            },
            {
                title: t('containers.CustomersList.name'),
                width: 200,
                key: 'name',
                render: (text: string, record: Customer) =>
                    record.name
                        ?.split(',')
                        .map((n: any) => n.trim())
                        .reduce((acc: string, curr: any) => `${acc ? `${acc}, ` : ''}${curr}`, '') || '',
            },
            {
                title: t('containers.CustomersList.email'),
                width: 250,
                key: 'email',
                render: (text: string, record: Customer) => record.email || '',
                filterDropdown: () => (
                    <SearchFilter
                        name="email"
                        value={filter?.email}
                        onChange={this.handleFilterChange}
                        onReset={this.handleFilterReset}
                        onSearch={this.handleFetchCustomers}
                    />
                ),
                filterIcon: () => <FilterIcon type="filter" filtered={`${!!filter?.email}`} />,
            },
            {
                title: t('containers.CustomersList.vin'),
                dataIndex: 'vin',
                key: 'vin',
                width: 300,
                filterDropdown: () => (
                    <SearchFilter
                        name="vin"
                        value={filter?.vin}
                        onChange={this.handleFilterChange}
                        onReset={this.handleFilterReset}
                        onSearch={this.handleFetchCustomers}
                    />
                ),
                filterIcon: () => <FilterIcon type="filter" filtered={`${!!filter?.vin}`} />,
                render: (text: string, record: Customer) =>
                    record.cars &&
                    record.cars.map((vin: string) => {
                        return (
                            <Tag
                                {...{
                                    vin,
                                    userId: record.userId,
                                    unassignedCar: this.props.unassignedCar,
                                }}
                            />
                        );
                    }),
            },
            {
                title: t('containers.CustomersList.phoneNumber'),
                key: 'phoneNumber',
                render: (text: string, record: Customer) =>
                    [...(record.phoneNumbers || []), record.phone].filter(Boolean).map((phone: string) => <p>{phone}</p>),
            },
            {
                title: t('containers.CustomersList.branch'),
                key: 'branch',
                render: (text: string, record: Customer) => <CapitalizedSpan>{record.branch || ''}</CapitalizedSpan>,
            },
        ];
    };

    render() {
        const {
            loading,
            t,
            dataSource,
            rawCustomers,
            history: { push },
        } = this.props;
        const columns = this.createColumns();
        return (
            <>
                <StyledTable
                    pagination={{
                        pageSize: PAGE_LIMIT,
                        onChange: (page: number) => this.handlePadding(page),
                        hideOnSinglePage: true,
                        defaultCurrent: 1,
                        total: rawCustomers.total,
                    }}
                    loading={loading}
                    onChange={this.handleTableChange}
                    dataSource={sortByDate(dataSource)}
                    columns={columns}
                    locale={{ emptyText: t('containers.CustomersList.noCustomers') }}
                    rowKey={(record: Customer) => record.userId}
                    onRow={({ userId }: Customer) => ({ onClick: () => push(`${Routes.CUSTOMERS.path}/${userId}`) })}
                />
            </>
        );
    }
}

export default compose<any>(
    translate(),
    withRouter,
    connect(
        (state: Redux) => ({
            loading: selectCustomersLoading(state),
            rawCustomers: selectCustomersRawCustomer(state),
            error: selectCustomersError(state),
            dataSource: createDataSource(selectCustomers(state)),
            filter: selectFilter(state),
        }),
        { getCustomers, setFilter, setFilters, unassignedCar },
    ),
)(CustomersList);
