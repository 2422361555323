import { api } from 'utils/request';
declare const Conf;

const fetcher = (token: string) => ({
    getPresence: () =>
        api(`apps/${Conf.APP_ID}/presence`, {
            method: 'GET',
            headers: {
                authorization: token,
            },
        }),
    getUser: (id: string) =>
        api(`apps/${Conf.APP_ID}/users/${id}`, {
            method: 'GET',
            headers: {
                authorization: token,
            },
        }),
});

export default fetcher;
