import { api } from 'utils/request';

import { Branch } from './@types';

export async function getBranches(token: string) {
    return api(`/apps/volvista/store/branches`, {
        method: 'GET',
        headers: {
            authorization: token,
        },
    });
}

export async function updateBranches(branches: Branch[], token: string) {
    return api(`/apps/volvista/store/branches`, {
        method: 'PUT',
        headers: {
            authorization: token,
        },
        body: branches,
    });
}
