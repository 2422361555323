import React from 'react';

import { StyledButton } from './styled';

interface Props {
    onClick?: () => void;
}

const CTAButton: React.SFC<Props> = ({ onClick }: Props) => {
    return <StyledButton type="primary" shape="circle" icon="plus" size="large" onClick={onClick} />;
};

export default CTAButton;
