import { createSelector } from 'reselect';
import { Redux } from 'typings';

import { CustomersReducer, Filter } from './@types.d';

const selectCustomersSubstate = (state: Redux) => state.customers;
const selectCustomerSubstate = (state: Redux) => state.customers.detail;

export const selectCustomersRawCustomer = createSelector(selectCustomersSubstate, (subState: CustomersReducer) => subState.data);
export const selectCustomers = createSelector(selectCustomersSubstate, (subState: CustomersReducer) => subState.data.docs);
export const selectCustomersError = createSelector(selectCustomersSubstate, (subState: CustomersReducer) => subState.error);
export const selectCustomersLoading = createSelector(selectCustomersSubstate, (subState: CustomersReducer) => subState.loading);
export const selectFilter = createSelector(selectCustomersSubstate, (list: CustomersReducer): Filter => list.data.filter);
export const selectCustomer = createSelector(selectCustomerSubstate, (subState: CustomersReducer['detail']) => subState.customer);
export const selectDocs = createSelector(selectCustomerSubstate, (subState: CustomersReducer['detail']) => subState.docs);
export const selectCustomerLoading = createSelector(selectCustomerSubstate, (subState: CustomersReducer['detail']) => subState.loading);
export const selectCustomerError = createSelector(selectCustomerSubstate, (subState: CustomersReducer['detail']) => subState.error);
export const selectCustomerUploading = createSelector(selectCustomerSubstate, (subState: CustomersReducer['detail']) => subState.uploading);
