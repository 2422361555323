import PageHeader from 'ant-design-pro/lib/PageHeader';
import Card from 'antd/es/card';
import { AxiosError } from 'axios';
import get from 'lodash-es/get';
import isEqual from 'lodash-es/isEqual';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'redux';
import { Branch } from 'store/modules/appInfo/@types';
import { selectBranches } from 'store/modules/appInfo/selectors';
import { Filter, Pagination, TicketRow } from 'store/modules/tickets/list/@types';
import { getTickets, setFilter, setFilters } from 'store/modules/tickets/list/actions';
import { selectError, selectFilter, selectLoading, selectPagination, selectRows } from 'store/modules/tickets/list/selectors';
import { Routes } from 'store/modules/ui/constants';
import { ITranslator, Redux } from 'typings';
import translator from 'utils/translator';
import SearchFilter from './SearchFilter';
import { FilterIcon, Table } from './styled';

const ticketsTranslation = 'routes.Tickets';
const translateColumns = translator(`${ticketsTranslation}.columns`);
const translateTicketType = translator(`${ticketsTranslation}.ticketTypes`);

interface Props extends ITranslator {
    getTickets?: typeof getTickets;
    setFilter?: typeof setFilter;
    setFilters?: typeof setFilters;
    push?: typeof push;
    branches: Branch[];
    rows: TicketRow[];
    pagination: Pagination;
    loading: boolean;
    error: AxiosError;
    filter: Filter;
}

const typeFilters = ['reminder', 'request', 'cardExpiration', 'warrantyExpiration', 'approvalChoices', 'signUpHelp'];
const statusFilters = ['open', 'closed', 'pending'];
const reactiveFilters = ['branch', 'type', 'status'];

const createFilter = (filter: string) => ({ text: translateTicketType(filter), value: translateTicketType(filter) });
const highlightRow = (record: TicketRow) =>
    record.status === translateTicketType('open') && record.type === translateTicketType('request') && 'highlight';

class Tickets extends Component<Props> {
    componentDidMount() {
        this.handleFetchTickets();
    }

    componentDidUpdate(prevProps: Props) {
        const { filter } = this.props;
        const shouldFetchTickets = reactiveFilters.some((filterName: string) => !isEqual(prevProps.filter[filterName], filter[filterName]));
        if (shouldFetchTickets) {
            this.handleFetchTickets();
        }
    }

    handleFetchTickets = (pagination?: Pagination) => {
        const { filter, getTickets } = this.props;
        const current = get(pagination, 'current');

        getTickets({ ...filter, page: current });
    };

    handleTableChange = (pagination: Pagination, filter: Filter) => {
        const { setFilters } = this.props;
        setFilters(filter);
        this.handleFetchTickets(pagination);
    };

    handleFilterChange = (name: string, value: string | string[]) => {
        const { setFilter } = this.props;
        setFilter(name, value);
    };

    handleFilterReset = (name: string) => {
        const { setFilter } = this.props;
        setFilter(name, null);
    };

    createColumns = () => {
        const { filter, branches } = this.props;

        return [
            {
                key: 'caseNumber',
                title: translateColumns('caseNumber'),
                dataIndex: 'caseNumber',
                filterDropdown: () => (
                    <SearchFilter
                        name="caseNumber"
                        value={filter.caseNumber}
                        onChange={this.handleFilterChange}
                        onReset={this.handleFilterReset}
                        onSearch={this.handleFetchTickets}
                    />
                ),
                filterIcon: () => <FilterIcon type="filter" filtered={`${!!filter.caseNumber}`} />,
                onFilterDropdownVisibleChange: (isVisible: boolean) => {
                    if (!isVisible) {
                        this.handleFetchTickets();
                    }
                },
            },
            {
                key: 'type',
                title: translateColumns('type'),
                dataIndex: 'type',
                filterMultiple: false,
                filteredValue: filter.type,
                filters: typeFilters.map(createFilter),
            },
            {
                key: 'updatedAt',
                title: translateColumns('updatedAt'),
                dataIndex: 'updatedAt',
            },
            {
                key: 'vin',
                title: translateColumns('vin'),
                dataIndex: 'vin',
                filterDropdown: () => (
                    <SearchFilter
                        name="vin"
                        value={filter.vin}
                        onChange={this.handleFilterChange}
                        onReset={this.handleFilterReset}
                        onSearch={this.handleFetchTickets}
                    />
                ),
                filterIcon: () => <FilterIcon type="filter" filtered={`${!!filter.vin}`} />,
                onFilterDropdownVisibleChange: (isVisible: boolean) => {
                    if (!isVisible) {
                        this.handleFetchTickets();
                    }
                },
            },
            {
                key: 'branch',
                title: translateColumns('branch'),
                dataIndex: 'branch',
                filterMultiple: false,
                filteredValue: filter.branch,
                filters: branches ? branches.map((branch: Branch) => ({ text: branch.name, value: branch.id })) : [],
            },
            {
                key: 'status',
                title: translateColumns('status'),
                dataIndex: 'status',
                filterMultiple: false,
                filteredValue: filter.status,
                filters: statusFilters.map(createFilter),
            },
        ];
    };

    render() {
        const { t, rows, loading, push, pagination } = this.props;
        const columns = this.createColumns();
        return (
            <>
                <PageHeader itemRender={null} title={`${t('routes.Tickets.title')} (${pagination?.total ? pagination.total : 0})`} />
                <Card bordered={false}>
                    <Table
                        rowClassName={(record: TicketRow) => highlightRow(record)}
                        pagination={pagination}
                        dataSource={rows}
                        columns={columns}
                        onChange={this.handleTableChange}
                        onRow={(record: TicketRow) => {
                            return { onClick: () => push(`${Routes.TICKETS.path}/${record.caseId}`) };
                        }}
                        loading={loading}
                    />
                </Card>
            </>
        );
    }
}

export default compose<any>(
    translate(),
    connect(
        (state: Redux) => ({
            rows: selectRows(state),
            loading: selectLoading(state),
            error: selectError(state),
            branches: selectBranches(state),
            pagination: selectPagination(state),
            filter: selectFilter(state),
        }),
        { getTickets, push, setFilter, setFilters },
    ),
)(Tickets);
