import createReducer from 'utils/createReducer';
import { Types } from './actions';
import * as actions from './actions';
import { ScanCodeReducer } from 'store/modules/scanCode/@types';

export const initialState: ScanCodeReducer = {
    list: null,
    loading: true,
};

const reducer = createReducer(initialState, {
    [Types.GET_PRESENCE]: (state: ScanCodeReducer, action: ReturnType<typeof actions.getPresence>): ScanCodeReducer => {
        return state;
    },
    [Types.GET_PRESENCE_SUCCESS]: (state: ScanCodeReducer, action: ReturnType<typeof actions.getPresenceSuccess>): ScanCodeReducer => {
        const data = action.payload.data;

        return {
            list: data,
            loading: false,
        };
    },
});

export default reducer;
