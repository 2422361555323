import PageHeader from 'ant-design-pro/lib/PageHeader';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import CenteredSpin from 'components/CenteredSpin';
import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { match } from 'react-router';
import { compose } from 'redux';
import { isSubmitting, submit } from 'redux-form';
import { Branch as IBranch } from 'store/modules/branches/@types';
import { getBranches, updateBranch } from 'store/modules/branches/actions';
import { selectBranchById, selectBranchesError, selectBranchesLoading, selectBranchesSending } from 'store/modules/branches/selectors';
import { ITranslator, Redux, SelectorType } from 'typings';
import BranchForm from 'view/containers/BranchForm';
import { Error } from './styled';

interface Props extends ITranslator {
    branch?: SelectorType<typeof selectBranchById>;
    loading?: SelectorType<typeof selectBranchesLoading>;
    sending?: SelectorType<typeof selectBranchesSending>;
    submitting?: SelectorType<typeof isSubmitting>;
    error?: SelectorType<typeof selectBranchesError>;
    submit?: typeof submit;
    match?: match<{ branchId: string }>;
    getBranches?: typeof getBranches;
    updateBranch?: typeof updateBranch;
}

class Branch extends React.Component<Props> {
    componentDidMount() {
        const { getBranches } = this.props;
        getBranches();
    }

    handleSubmit = (values: IBranch) => {
        const { updateBranch } = this.props;
        updateBranch(values);
    };

    render() {
        const { loading, sending, submitting, error, t, branch, submit } = this.props;

        if (error) {
            return (
                <Card bordered={false}>
                    <Error>{t('routes.Branch.error')}</Error>
                </Card>
            );
        }

        if (!branch || loading) {
            return <CenteredSpin />;
        }

        return (
            <>
                <PageHeader
                    itemRender={null}
                    title={t('routes.Branches.detail')}
                    action={
                        <Button type="primary" onClick={() => submit('branchForm')} loading={submitting || sending}>
                            {t('routes.Branch.save')}
                        </Button>
                    }
                />
                <Card bordered={false}>
                    <BranchForm initialValues={branch} onSubmit={this.handleSubmit} />
                </Card>
            </>
        );
    }
}

export default compose<any>(
    connect(
        (state: Redux, props: Props) => ({
            branch: selectBranchById(props.match.params.branchId)(state),
            loading: selectBranchesLoading(state),
            sending: selectBranchesSending(state),
            submitting: isSubmitting('branchForm')(state),
            error: selectBranchesError(state),
        }),
        { getBranches, updateBranch, submit },
    ),
    translate(),
)(Branch);
