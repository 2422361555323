import { AxiosError } from 'axios';
import { Ticket } from 'store/modules/tickets/@types';

import { Filter, ResponsePagination } from './@types';

export enum Types {
    GET_TICKETS = 'tickets/GET_TICKETS',
    GET_TICKETS_SUCCESS = 'tickets/GET_TICKETS_SUCCESS',
    GET_TICKETS_FAILURE = 'tickets/GET_TICKETS_FAILURE',
    SET_FILTER = 'tickets/SET_FILTER',
    SET_FILTERS = 'tickets/SET_FILTERS',
}

export const getTickets = (params?: Filter) => ({
    type: Types.GET_TICKETS,
    payload: { params },
});

export const getTicketsSuccess = (tickets: Ticket[], pagination: ResponsePagination) => ({
    type: Types.GET_TICKETS_SUCCESS,
    payload: { tickets, pagination },
});

export const getTicketsFailure = (error: AxiosError) => ({
    type: Types.GET_TICKETS_FAILURE,
    payload: { error },
});

export const setFilter = (key: string, value: string | string[]) => ({
    type: Types.SET_FILTER,
    payload: { key, value },
});

export const setFilters = (filters: Filter) => ({
    type: Types.SET_FILTERS,
    payload: { filters },
});
