import { createValidator, email, minLength, required } from 'utils/validation';

const ContactFormValidation = createValidator({
    email: [required, email],
    branch: [required],
    department_position: [required],
    phone: [minLength(9), required],
    name: [required],
});

export default ContactFormValidation;
