import React from 'react';
import { ITranslator } from 'typings';
import { Tag } from 'antd';

import { translateDocumentType } from '../../../utils/translate';
import { allCustomerDocumentTypes, CustomerDocumentType } from 'utils/customerDocumentTypes';

interface Props extends ITranslator {
    type: string;
}

const tagColor = (type: string) =>
    ({
        invoice: 'blue',
        offer: 'green',
        protocol: 'red',
        order: 'orange',
        other: 'purple',
    }[type] ?? 'black');

const isKnownType = (type: string) => allCustomerDocumentTypes.includes(type as CustomerDocumentType);

const DocumentTypeTag = ({ type }: Props) => {
    if (!isKnownType(type)) {
        return null;
    }

    return <Tag color={tagColor(type)}>{translateDocumentType(type)}</Tag>;
};

export default DocumentTypeTag;
