import { t } from 'i18next';

export enum BranchEnum {
    DEJVICE = 'dejvice',
    PRUHONICE = 'pruhonice',
    CARDION = 'cardion',
    STODULKY = 'stodulky',
}

export enum PositionEnum {
    ACCEPTING_TECHNICIAN = 'acceptingtech',
    ADMINISTRATOR_INSURANCE = 'admininsurance',
    HOTLINE = 'hotline',
    MAIN_GUARANTEE_TECH = 'mainguaranteetech',
    PARTS_SALES_LEADER = 'salesdir',
    RECEPTION = 'reception',
    RECEPTIONIST = 'receptionist',
    SALES_ASSISSTANT = 'salesass',
    SALES_CONSULTANT = 'salescons',
    SALES_LEADER = 'saleshead',
    SALES_LEADER_REPR = 'salesheadrepr',
    SALE_NEW_CARS = 'newcarsales',
    SALE_USED_CARS = 'usedcarsales',
    SERVICE = 'service',
    SERVICE_ADVISOR = 'serviceadvisor',
    SERVICE_LEADER = 'headservice',
    SERVICE_SUPPORT = 'servicesupport',
    SPARE_PARTS = 'partsales',
    STORAGE = 'warehouse',
    STORAGE_LEADER = 'warehousedir',
    TECHNICIAN_INSURANCE = 'acceptingtechinsurance',
    TECHNICIAN_PICK_UP = 'acceptingtechpickup',
    VPS_PERSONAL_TECH = 'VPSpersonaltech',
}

export enum UserGroups {
    ADMIN = 'admin',
    BACKOFFICE = 'backoffice',
    TECH = 'tech',
}

export const DEPARTMENTS_FILTER = {
    SERVICE: t('containers.ContactForm.positions.SERVICE'),
    HOTLINE: t('containers.ContactForm.positions.HOTLINE'),
    RECEPTION: t('containers.ContactForm.positions.RECEPTION'),
    SALE_USED_CARS: t('containers.ContactForm.positions.SALE_USED_CARS'),
    SALE_NEW_CARS: t('containers.ContactForm.positions.SALE_NEW_CARS'),
    SPARE_PARTS: t('containers.ContactForm.positions.SPARE_PARTS'),
};

export const BRANCH_FILTER = {
    DEJVICE: t('containers.ContactForm.branches.dejvice'),
    PRUHONICE: t('containers.ContactForm.branches.pruhonice'),
    CARDION: t('containers.ContactForm.branches.cardion'),
    STODULKY: t('containers.ContactForm.branches.stodulky'),
};

export const FILE_SIZE = 2097152;
export const allowedImageTypes = ['image/jpg', 'image/png', 'image/jpeg'];
