import styled from 'styled-components';

export const Wrapper = styled.div`
    .ant-form-item-control-wrapper {
        width: 100%;
    }

    .ant-form-item-label {
        text-align: left;
        float: left;
    }
`;

export const ImageWrapper = styled.div`
    width: auto;
    height: 100vh;
    flex-grow: 1;
    background-position: center;
    background-image: url(${require('assets/volvo.jpg')});
    background-repeat: no-repeat;
    background-size: cover;
`;

export const FormWrapper = styled.div`
    width: 540px;
    height: 100vh;
    padding: 0 60px;
    display: flex;
    flex-direction: column;
    justify-content: ${(props: any) => (props.isPasswordChange ? 'center' : 'flex-start')};
`;
