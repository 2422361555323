import concat from 'lodash-es/concat';
import filter from 'lodash-es/filter';
import findIndex from 'lodash-es/findIndex';
import { Branch } from 'store/modules/branches/@types';

function hashString(s: string) {
    let a = 1,
        c = 0,
        h,
        o;
    if (s) {
        a = 0;
        /*jshint plusplus:false bitwise:false*/
        for (h = s.length - 1; h >= 0; h--) {
            o = s.charCodeAt(h);
            a = ((a << 6) & 268435455) + o + (o << 14);
            c = a & 266338304;
            a = c !== 0 ? a ^ (c >> 21) : a;
        }
    }

    return String(a);
}

export function createBranch(branches: Branch[], branch: Branch) {
    branch['id'] = hashString(branch.name);
    return concat(branches, branch);
}

export function updateBranch(branches: Branch[], branch: Branch) {
    const index = findIndex(branches, { id: branch.id });
    branches[index] = branch;
    return branches;
}

export function deleteBranch(branches: Branch[], branchId: Branch['id']) {
    return filter(branches, (item: Branch) => {
        return branchId !== item.id;
    });
}
