import { combineReducers } from 'redux';
import { reducer as appInfo } from 'store/modules/appInfo';
import { reducer as auth } from 'store/modules/auth';
import { reducer as branches } from 'store/modules/branches';
import { reducer as carTypes } from 'store/modules/carTypes';
import { reducer as contacts } from 'store/modules/contacts';
import { reducer as customers } from 'store/modules/customers';
import { reducer as manuals } from 'store/modules/manuals';
import { reducer as files } from 'store/modules/s3files';
import { reducer as symbols } from 'store/modules/symbols';
import { reducer as tickets } from 'store/modules/tickets';
import { reducer as ui } from 'store/modules/ui';
import { reducer as users } from 'store/modules/users';
import { reducer as video } from 'store/modules/video';
import { reducer as scanCode } from 'store/modules/scanCode';
import { reducer as form } from 'store/modules/reduxForm';

export default combineReducers({
    auth,
    ui,
    users,
    symbols,
    branches,
    manuals,
    files,
    tickets,
    contacts,
    appInfo,
    customers,
    carTypes,
    video,
    scanCode,
    form,
});
