import { ContactsReducer } from 'store/modules/contacts/@types';
import createReducer from 'utils/createReducer';

import { Types } from './actions';
import * as actions from './actions';

export const initialState: ContactsReducer = {
    list: {
        data: null,
        loading: false,
        error: null,
        sending: false,
    },
    detail: {
        data: null,
        loading: false,
        error: null,
        sending: false,
    },
};

export default createReducer(initialState, {
    [Types.GET_CONTACT]: (state: ContactsReducer): ContactsReducer => {
        return {
            ...initialState,
            detail: {
                ...state.detail,
                loading: true,
            },
        };
    },
    [Types.GET_CONTACT_SUCCESS]: (state: ContactsReducer, action: ReturnType<typeof actions.getContactSuccess>): ContactsReducer => {
        const { contact } = action.payload;
        return {
            ...state,
            detail: {
                ...state.detail,
                data: contact,
                loading: false,
            },
        };
    },
    [Types.GET_CONTACT_FAILURE]: (state: ContactsReducer, action: ReturnType<typeof actions.getContactFailure>): ContactsReducer => {
        const { error } = action.payload;
        return {
            ...state,
            detail: {
                ...state.detail,
                loading: false,
                error,
            },
        };
    },
    [Types.GET_CONTACTS]: (state: ContactsReducer): ContactsReducer => {
        return {
            ...initialState,
            list: {
                ...state.list,
                loading: true,
            },
        };
    },
    [Types.GET_CONTACTS_SUCCESS]: (state: ContactsReducer, action: ReturnType<typeof actions.getContactsSuccess>): ContactsReducer => {
        const { contacts } = action.payload;
        return {
            ...state,
            list: {
                ...state.list,
                data: contacts,
                loading: false,
            },
        };
    },
    [Types.GET_CONTACTS_FAILURE]: (state: ContactsReducer, action: ReturnType<typeof actions.getContactsFailure>): ContactsReducer => {
        const { error } = action.payload;
        return {
            ...state,
            list: {
                ...state.list,
                error,
                loading: false,
            },
        };
    },
    [Types.DELETE_CONTACT]: (state: ContactsReducer): ContactsReducer => {
        return {
            ...state,
            detail: {
                ...state.detail,
                sending: true,
            },
        };
    },
    [Types.DELETE_CONTACT_SUCCESS]: (state: ContactsReducer): ContactsReducer => {
        return {
            ...state,
            detail: {
                ...state.detail,
                sending: false,
            },
        };
    },
    [Types.DELETE_CONTACT_FAILURE]: (state: ContactsReducer, action: ReturnType<typeof actions.deleteContactFailure>): ContactsReducer => {
        const { error } = action.payload;
        return {
            ...state,
            detail: {
                ...state.detail,
                sending: false,
                error,
            },
        };
    },
    [Types.UPDATE_CONTACT_FAILURE]: (state: ContactsReducer, action: ReturnType<typeof actions.updateContactFailure>): ContactsReducer => {
        const { error } = action.payload;
        return {
            ...state,
            detail: {
                ...state.detail,
                error,
            },
        };
    },
    [Types.CREATE_CONTACT_FAILURE]: (state: ContactsReducer, action: ReturnType<typeof actions.createContactFailure>): ContactsReducer => {
        const { error } = action.payload;
        return {
            ...state,
            detail: {
                ...state.detail,
                error,
            },
        };
    },
});
