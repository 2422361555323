import concat from 'lodash-es/concat';
import createReducer from 'utils/createReducer';

import { ExtendedUserType, UsersReducer } from './@types.d';
import { Types } from './actions';
import * as actions from './actions';
import { UserAction } from './constants';

export const initialState: UsersReducer = {
    detail: {
        loading: false,
        data: null,
        error: null,
        sending: false,
    },
    userGroups: {
        loading: false,
        limit: 0,
        error: null,
        data: null,
        paginationToken: null,
    },
    usersInGroups: {
        loading: false,
        error: null,
        data: [],
    },
};

export default createReducer(initialState, {
    [Types.CREATE_USER_SUCCESS]: (state: UsersReducer, action: ReturnType<typeof actions.createUserSuccess>): UsersReducer => {
        const { user } = action.payload;
        return {
            ...state,
            detail: {
                ...state.detail,
                data: user,
            },
        };
    },
    [Types.GET_USER]: (state: UsersReducer, action: ReturnType<typeof actions.getUser>): UsersReducer => {
        return {
            ...state,
            detail: {
                ...state.detail,
                loading: true,
            },
        };
    },
    [Types.GET_USER_SUCCESS]: (state: UsersReducer, action: ReturnType<typeof actions.getUserSuccess>): UsersReducer => {
        const { user } = action.payload;
        return {
            ...state,
            detail: {
                ...state.detail,
                loading: false,
                data: user,
            },
        };
    },
    [Types.GET_USER_FAILURE]: (state: UsersReducer, action: ReturnType<typeof actions.getUserFailure>): UsersReducer => {
        const { error } = action.payload;
        return {
            ...state,
            detail: {
                ...state.detail,
                loading: false,
                error,
            },
        };
    },
    [Types.USER_ACTION]: (state: UsersReducer, action: ReturnType<typeof actions.userAction>): UsersReducer => {
        return {
            ...state,
            detail: {
                ...state.detail,
                sending: true,
            },
        };
    },
    [Types.USER_ACTION_SUCCESS]: (state: UsersReducer, action: ReturnType<typeof actions.userActionSuccess>): UsersReducer => {
        const { userAction } = action.payload;
        return {
            ...state,
            detail: {
                ...state.detail,
                sending: false,
                data: {
                    ...state.detail.data,
                    Enabled: userAction === UserAction.ENABLE,
                },
            },
        };
    },
    [Types.USER_ACTION_FAILURE]: (state: UsersReducer, action: ReturnType<typeof actions.userActionFailure>): UsersReducer => {
        const { error } = action.payload;
        return {
            ...state,
            detail: {
                ...state.detail,
                sending: false,
                error,
            },
        };
    },
    [Types.GET_USER_GROUPS]: (state: UsersReducer, action: ReturnType<typeof actions.getUserGroups>): UsersReducer => {
        return {
            ...state,
            userGroups: {
                ...initialState.userGroups,
                loading: true,
                error: null,
            },
        };
    },
    [Types.GET_USER_GROUPS_SUCCESS]: (state: UsersReducer, action: ReturnType<typeof actions.getUserGroupsSuccess>): UsersReducer => {
        const { userGroups, paginationToken } = action.payload;
        return {
            ...state,
            userGroups: {
                ...state.userGroups,
                loading: false,
                data: userGroups,
                paginationToken,
            },
        };
    },
    [Types.GET_USER_GROUPS_FAILURE]: (state: UsersReducer, action: ReturnType<typeof actions.getUserGroupsFailure>): UsersReducer => {
        const { error } = action.payload;
        return {
            ...state,
            userGroups: {
                ...state.userGroups,
                loading: false,
                error,
            },
        };
    },
    [Types.GET_USERS_IN_GROUPS]: (state: UsersReducer, action: ReturnType<typeof actions.getUsersInGroups>): UsersReducer => {
        return {
            ...state,
            usersInGroups: {
                ...initialState.usersInGroups,
                loading: true,
                error: null,
            },
        };
    },
    [Types.GET_USERS_IN_GROUPS_SUCCESS]: (
        state: UsersReducer,
        action: ReturnType<typeof actions.getUsersInGroupsSuccess>,
    ): UsersReducer => {
        const { userGroup, users } = action.payload;
        return {
            ...state,
            usersInGroups: {
                ...state.usersInGroups,
                loading: false,
                data: concat(
                    users.map((user: ExtendedUserType) => {
                        return { ...user, userGroup };
                    }),
                    state.usersInGroups.data,
                ) as ExtendedUserType[],
            },
        };
    },
    [Types.GET_USERS_IN_GROUPS_FAILURE]: (
        state: UsersReducer,
        action: ReturnType<typeof actions.getUsersInGroupsFailure>,
    ): UsersReducer => {
        const { error } = action.payload;
        return {
            ...state,
            usersInGroups: {
                ...state.usersInGroups,
                loading: false,
                error,
            },
        };
    },
});
