import PageHeader from 'ant-design-pro/lib/PageHeader';
import Button from 'antd/es/button';
import Col from 'antd/es/col';
import Divider from 'antd/es/divider';
import Modal from 'antd/es/modal';
import Row from 'antd/es/row';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, FieldArray, FieldArrayFieldsProps, Form, InjectedFormProps, reduxForm, submit } from 'redux-form';
import { TextField } from 'redux-form-antd';
import { getAllCarTypes } from 'store/modules/carTypes/actions';
import { SelectOption } from 'store/modules/manuals/@types';
import { createManual, deleteManual, updateManual } from 'store/modules/manuals/actions';
import { selectCarOptions, selectIssending, selectYearOptions } from 'store/modules/manuals/selectors';
import { ITranslator, Redux } from 'typings';
import { required } from 'utils/validation';
import FromS3 from 'view/components/FromS3';
import UploadField from 'view/components/UploadField';
import CarRow from './CarRow';
import { StyledCard, StyledRow } from './styled';
import validate from './validate';

const { confirm } = Modal;

interface Props extends ITranslator, InjectedFormProps {
    loading?: boolean;
    sending?: boolean;
    deleteManual?: typeof deleteManual;
    getAllCarTypes?: typeof getAllCarTypes;
    manualId?: string;
    initialValues: any;
    carOptions: SelectOption[];
    yearOptions: SelectOption[];
    submit?: typeof submit;
}

class ManualForm extends Component<Props> {
    componentDidMount() {
        const { carOptions, getAllCarTypes } = this.props;
        !carOptions && getAllCarTypes();
    }

    handleDelete = () => {
        const { manualId, t, deleteManual } = this.props;
        confirm({
            title: t('containers.ManualForm.deleteWarning'),
            okText: t('containers.ManualForm.okText'),
            okType: 'danger',
            cancelText: t('containers.ManualForm.cancelText'),
            onOk() {
                deleteManual(manualId);
            },
        });
    };

    renderCarTypes = ({ fields, carOptions, yearOptions, t }) => {
        return (
            <StyledRow>
                {fields.map((field: string, index: number, fields: FieldArrayFieldsProps<any>) => {
                    return (
                        <CarRow
                            key={index}
                            field={field}
                            onRemove={() => fields.remove(index)}
                            index={index}
                            selectedModel={fields.get(index).name}
                            carOptions={carOptions}
                            yearOptions={yearOptions}
                        />
                    );
                })}

                <Button type="primary" onClick={() => fields.push({})}>
                    {t('containers.ManualForm.addCar')}
                </Button>
            </StyledRow>
        );
    };

    render() {
        const { manualId, t, handleSubmit, sending, initialValues, submit, submitting, carOptions, yearOptions } = this.props;
        return (
            <>
                <PageHeader
                    itemRender={null}
                    action={
                        <>
                            <Button type="primary" onClick={() => submit('manualForm')} loading={submitting || sending}>
                                {t(manualId ? 'containers.ManualForm.save' : 'containers.ManualForm.add')}
                            </Button>
                            {manualId && (
                                <Button onClick={this.handleDelete} loading={submitting || sending} type="danger">
                                    {t('containers.ManualForm.delete')}
                                </Button>
                            )}
                        </>
                    }
                    title={manualId ? t('containers.ManualForm.detail') : t('containers.ManualForm.addManual')}
                />
                <StyledCard bordered={false}>
                    <Form onSubmit={handleSubmit(manualId ? updateManual : createManual)}>
                        <Row gutter={10}>
                            <Col xs={4} sm={8} md={12} lg={17} xl={32}>
                                <Field
                                    label={t('containers.ManualForm.labels.enDescription')}
                                    name="description.en"
                                    hasFeedback={false}
                                    component={TextField}
                                />
                                <Field
                                    label={t('containers.ManualForm.labels.csDescription')}
                                    name="description.cs"
                                    hasFeedback={false}
                                    validate={[required]}
                                    component={TextField}
                                />
                                <Divider />
                                <FieldArray name="cartypes" component={this.renderCarTypes} props={{ carOptions, yearOptions, t }} />
                            </Col>
                            <Col span={5}>
                                <FromS3 name={initialValues && initialValues.name.cs}>
                                    {(url: string) => (
                                        <Field
                                            label={t('containers.ManualForm.labels.csFile')}
                                            awsFolder="manuals"
                                            name="name.cs"
                                            accept=".pdf"
                                            url={url}
                                            type="button"
                                            component={UploadField}
                                        />
                                    )}
                                </FromS3>
                                <FromS3 name={initialValues && initialValues.name.en}>
                                    {(url: string) => (
                                        <Field
                                            label={t('containers.ManualForm.labels.enFile')}
                                            awsFolder="manuals"
                                            name="name.en"
                                            accept=".pdf"
                                            url={url}
                                            type="button"
                                            component={UploadField}
                                        />
                                    )}
                                </FromS3>
                            </Col>
                        </Row>
                    </Form>
                </StyledCard>
            </>
        );
    }
}

export default compose<any>(
    reduxForm({
        form: 'manualForm',
        validate,
    }),
    translate(),
    connect(
        (state: Redux) => ({
            sending: selectIssending(state),
            carOptions: selectCarOptions(state),
            yearOptions: selectYearOptions(state),
        }),
        { submit, deleteManual, getAllCarTypes },
    ),
)(ManualForm);
