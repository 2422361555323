import Button from 'antd/es/button';
import isEqual from 'lodash-es/isEqual';
import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { getCarType, updateCarType } from 'store/modules/carTypes/actions';
import { selectCarType, selectDetailError, selectDetailLoading } from 'store/modules/carTypes/selectors';
import { ITranslator, Redux } from 'typings';

import FromS3 from '../FromS3';
import UploadField from '../UploadField';
import { ButtonWrapper, ContentWrapper, Form, IconWrapper, TextWrapper } from './styled';

interface Props extends ITranslator, InjectedFormProps {
    model: string;
    year: number;
    id: string;
    carTypeImage?: string;
    getCarType?: typeof getCarType;
    onDeselect: () => void;
}

class CarCardSelected extends React.Component<Props> {
    componentDidMount() {
        const { getCarType, id } = this.props;
        getCarType(id);
    }

    componentDidUpdate(prevProps: Props) {
        const { submitSucceeded, onDeselect } = this.props;
        if (!isEqual(submitSucceeded, prevProps.submitSucceeded)) {
            onDeselect();
        }
    }

    render() {
        const { t, handleSubmit, onDeselect, model, year, submitting, carTypeImage } = this.props;
        return (
            <Form onSubmit={handleSubmit(updateCarType)}>
                <IconWrapper>
                    <FromS3 name={carTypeImage}>
                        {(url: string) => {
                            return (
                                <Field
                                    name="carTypeImage"
                                    awsFolder="cars"
                                    //@ts-ignore
                                    component={UploadField}
                                    placeholder={t('containers.CarForm.uploadImage')}
                                    type="image"
                                    url={url}
                                />
                            );
                        }}
                    </FromS3>
                </IconWrapper>
                <ContentWrapper>
                    <TextWrapper>{model}</TextWrapper>
                    <TextWrapper>{year}</TextWrapper>
                </ContentWrapper>
                <ButtonWrapper>
                    <Button type="primary" htmlType="submit" loading={submitting}>
                        {t('containers.CarForm.submit')}
                    </Button>
                    <Button type="danger" loading={submitting} onClick={onDeselect}>
                        {t('containers.CarForm.cancel')}
                    </Button>
                </ButtonWrapper>
            </Form>
        );
    }
}

export default compose<any>(
    reduxForm({
        form: 'carForm',
        enableReinitialize: true,
        destroyOnUnmount: false,
    }),
    translate(),
    connect(
        (state: Redux) => ({
            loading: selectDetailLoading(state),
            error: selectDetailError(state),
            carType: selectCarType(state),
        }),
        { getCarType },
    ),
)(CarCardSelected);
