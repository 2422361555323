import { t } from 'i18next';
import { BranchEnum, PositionEnum } from 'store/modules/contacts/constants';

export const dropdownOptions = {
    branch: [
        {
            label: '',
            value: '',
        },
        {
            label: 'Dejvice',
            value: BranchEnum.DEJVICE,
        },
        {
            label: 'Cardion',
            value: BranchEnum.CARDION,
        },
        {
            label: 'Průhonice',
            value: BranchEnum.PRUHONICE,
        },
        {
            label: 'Stodůlky',
            value: BranchEnum.STODULKY,
        },
    ],
};

export const cascaderOptions = [
    {
        value: PositionEnum.RECEPTION,
        label: t('containers.ContactForm.positions.RECEPTION'),
        children: [
            {
                value: PositionEnum.RECEPTIONIST,
                label: t('containers.ContactForm.positions.RECEPTIONIST'),
            },
        ],
    },
    {
        value: PositionEnum.HOTLINE,
        label: t('containers.ContactForm.positions.HOTLINE'),
        children: [
            {
                value: PositionEnum.HOTLINE,
                label: t('containers.ContactForm.positions.HOTLINE'),
            },
        ],
    },
    {
        value: PositionEnum.SERVICE,
        label: t('containers.ContactForm.positions.SERVICE'),
        children: [
            {
                value: PositionEnum.SERVICE_LEADER,
                label: t('containers.ContactForm.positions.SERVICE_LEADER'),
            },
            {
                value: PositionEnum.RECEPTIONIST,
                label: t('containers.ContactForm.positions.RECEPTIONIST'),
            },
            {
                value: PositionEnum.ACCEPTING_TECHNICIAN,
                label: t('containers.ContactForm.positions.ACCEPTING_TECHNICIAN'),
            },
            {
                value: PositionEnum.TECHNICIAN_INSURANCE,
                label: t('containers.ContactForm.positions.ACCEPTING_TECHNICIAN_INSURANCE'),
            },
            {
                value: PositionEnum.TECHNICIAN_PICK_UP,
                label: t('containers.ContactForm.positions.TECHNICIAN_PICK_UP'),
            },
            {
                value: PositionEnum.ADMINISTRATOR_INSURANCE,
                label: t('containers.ContactForm.positions.ADMINISTRATOR_INSURANCE'),
            },
            {
                value: PositionEnum.MAIN_GUARANTEE_TECH,
                label: t('containers.ContactForm.positions.MAIN_GUARANTEE_TECH'),
            },
            {
                value: PositionEnum.VPS_PERSONAL_TECH,
                label: t('containers.ContactForm.positions.VPS_PERSONAL_TECH'),
            },
            {
                value: PositionEnum.SERVICE_ADVISOR,
                label: t('containers.ContactForm.positions.SERVICE_ADVISOR'),
            },
            {
                value: PositionEnum.SERVICE_SUPPORT,
                label: t('containers.ContactForm.positions.SERVICE_SUPPORT'),
            },
        ],
    },
    {
        value: PositionEnum.SALE_NEW_CARS,
        label: t('containers.ContactForm.positions.SALE_NEW_CARS'),
        children: [
            {
                value: PositionEnum.SALES_LEADER,
                label: t('containers.ContactForm.positions.SALES_LEADER'),
            },
            {
                value: PositionEnum.SALES_LEADER_REPR,
                label: t('containers.ContactForm.positions.SALES_LEADER_REPR'),
            },
            {
                value: PositionEnum.SALES_CONSULTANT,
                label: t('containers.ContactForm.positions.SALES_CONSULTANT'),
            },
            {
                value: PositionEnum.SALES_ASSISSTANT,
                label: t('containers.ContactForm.positions.SALES_ASSISSTANT'),
            },
        ],
    },
    {
        value: PositionEnum.SALE_USED_CARS,
        label: t('containers.ContactForm.positions.SALE_USED_CARS'),
        children: [
            {
                value: PositionEnum.SALES_LEADER,
                label: t('containers.ContactForm.positions.SALES_USED_CARS_LEADER'),
            },
            {
                value: PositionEnum.SALES_CONSULTANT,
                label: t('containers.ContactForm.positions.SALES_USED_CARS_CONSULTANT'),
            },
            {
                value: PositionEnum.SALES_ASSISSTANT,
                label: t('containers.ContactForm.positions.SALES_ASSISSTANT'),
            },
        ],
    },
    {
        value: PositionEnum.SPARE_PARTS,
        label: t('containers.ContactForm.positions.SPARE_PARTS'),
        children: [
            {
                value: PositionEnum.STORAGE,
                label: t('containers.ContactForm.positions.STORAGE'),
            },
            {
                value: PositionEnum.STORAGE_LEADER,
                label: t('containers.ContactForm.positions.STORAGE_LEADER'),
            },
            {
                value: PositionEnum.PARTS_SALES_LEADER,
                label: t('containers.ContactForm.positions.PARTS_SALES_LEADER'),
            },
        ],
    },
];
