import { v4 as uuid } from 'uuid';

export const numberWithSpaces = (x: number | string) => {
    if (x) {
        return x
            .toString()
            .replace(/\s/g, '')
            .replace(/\B(?=(\d{3})+(?!\d))/g, String.fromCharCode(160));
    }

    return null;
};

export const normalizeNumber = (value: string, previousValue: string, maxVal?: number, maxLength?: number): string => {
    if (!value || value.length <= 0) {
        return value;
    }
    const numericValue = value.replace(/\D/g, '');
    const intValue = parseInt(numericValue);
    if ((maxVal && intValue > maxVal) || (maxLength && numericValue.length > maxLength)) {
        return previousValue;
    }
    return numericValue;
};

export const formatPrice = (price: number) => {
    return price.toLocaleString('cs-CZ', {
        style: 'currency',
        currency: 'CZK',
        minimumFractionDigits: 0,
    });
};

const splitLast = (delimiter: string) => (str: string): [string, string] => {
    const split = str.split(delimiter);

    const last = split[split.length - 1];
    const rest = split.slice(0, split.length - 1).join(delimiter);

    return [last, rest];
};

const splitBasename = splitLast('/');

const splitExtension = splitLast('.');

export const randomizeFilename = (filename: string) => {
    const [basename, path] = splitBasename(filename);
    const [extension] = splitExtension(basename);

    const randomName = uuid();

    return `${path}/${randomName}.${extension}`;
};
