import Button from 'antd/es/button';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { ThemeProps } from 'stylesheets/GlobalTheme';

export const StyledPlayer = styled(ReactPlayer)``;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const VideoWrapper = styled.div`
    @media screen and (max-width: ${(props: ThemeProps) => props.theme.resolution.screenMd}) {
        max-width: 200px;
    }

    @media screen and (min-width: ${(props: ThemeProps) => props.theme.resolution.screenMd}) {
        max-width: 300px;
    }

    @media screen and (min-width: ${(props: ThemeProps) => props.theme.resolution.screenLg}) {
        max-width: 400px;
    }
`;

export const Span = styled.span`
    text-align: center;
    margin: 10px;
    font-weight: bolder;
    font-size: ${(props: ThemeProps) => props.theme.fontSize.large};
`;

export const BoldSpan = styled.span`
    text-align: center;
    margin: 10px 0;
    font-size: ${(props: ThemeProps) => props.theme.fontSize.large};
    font-weight: bold;
`;

export const StyledButton = styled(Button)`
    margin: 10px;
    min-width: 100px;
`;

export const Logo = styled.img`
    margin: 0 auto;
    width: 240px;
`;
