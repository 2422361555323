import createReducer from 'utils/createReducer';

import { S3FilesReducer } from './@types';
import { Types } from './actions';
import * as actions from './actions';

export const initialState: S3FilesReducer = {};

export default createReducer(initialState, {
    [Types.GET_FILE_SUCCESS]: (state: S3FilesReducer, action: ReturnType<typeof actions.getFileSuccess>): S3FilesReducer => {
        return {
            ...state,
            [action.payload.name]: action.payload.url,
        };
    },
});
