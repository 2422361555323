import React from 'react';
import { translate } from 'react-i18next';
import { compose } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { changePassword } from 'store/modules/auth/actions';
import { ITranslator } from 'typings';
import PasswordChangeForm from 'view/components/PasswordChangeForm';
import SignInTemplate from 'view/templates/SignInTemplate';
import { HeartWrapper, Images, TextWrapper } from './styled';
import validate from './validate';

const logo_heart = require('assets/ic_heart_volvo.png');

interface Props extends InjectedFormProps, ITranslator {
    changePassword: typeof changePassword;
}

class PasswordChange extends React.Component<Props> {
    render() {
        const { t } = this.props;
        return (
            <SignInTemplate>
                <Images>
                    <TextWrapper>{t('routes.PasswordChange.title')}</TextWrapper>
                    <HeartWrapper src={logo_heart} alt="heart" />
                </Images>
                <PasswordChangeForm {...this.props} />
            </SignInTemplate>
        );
    }
}
export default compose<any>(
    reduxForm({
        form: 'passwordChangeForm',
        validate,
    }),
    translate(),
)(PasswordChange);
