import { api } from 'utils/request';

import { EventFormData, ScheduleNotificationParams } from './@types';

declare const Conf;

const fetcher = (token: string) => ({
    getTicket: (ticketId: string) =>
        api(`apps/${Conf.APP_ID}/tickets/${ticketId}`, {
            method: 'GET',
            headers: {
                authorization: token,
            },
        }),
    closeTicket: (ticketId: string) =>
        api(`apps/${Conf.APP_ID}/tickets/${ticketId}/close`, {
            method: 'PUT',
            headers: {
                authorization: token,
            },
        }),
    contactFail: (event: EventFormData, ticketId: string) =>
        api(`apps/${Conf.APP_ID}/tickets/${ticketId}/contact-fail`, {
            method: 'POST',
            headers: {
                authorization: token,
            },
            body: event,
        }),
    contactSuccess: (event: EventFormData, ticketId: string) =>
        api(`apps/${Conf.APP_ID}/tickets/${ticketId}/contact-success`, {
            method: 'POST',
            headers: {
                authorization: token,
            },
            body: event,
        }),
    cancelNotification: (ticketId: string, eventId: string) =>
        api(`apps/${Conf.APP_ID}/tickets/${ticketId}/notifications/${eventId}/cancel`, {
            method: 'POST',
            headers: {
                authorization: token,
            },
        }),
    scheduleNotification: (event: ScheduleNotificationParams, ticketId: string) =>
        api(`apps/${Conf.APP_ID}/tickets/${ticketId}/notifications`, {
            method: 'POST',
            headers: {
                authorization: token,
            },
            body: event,
        }),
});

export default fetcher;
