export enum Types {
    GET_PRESENCE = 'scanCode/GET_PRESENCE',
    GET_PRESENCE_SUCCESS = 'scanCode/GET_PRESENCE_SUCCESS',
}

export function getPresence() {
    return {
        type: Types.GET_PRESENCE,
    };
}

export function getPresenceSuccess(data: any) {
    return {
        type: Types.GET_PRESENCE_SUCCESS,
        payload: {
            data,
        },
    };
}
