import Card from 'antd/es/card';
import Table from 'antd/es/table';
import { t } from 'i18next';
import find from 'lodash-es/find';
import isEqual from 'lodash-es/isEqual';
import map from 'lodash-es/map';
import * as React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
import { compose } from 'redux';
import { TranslationLanguage } from 'store/modules/appInfo/constants';
import { selectBranches, selectDepartments } from 'store/modules/appInfo/selectors';
import { Contact } from 'store/modules/contacts/@types';
import { getContacts } from 'store/modules/contacts/actions';
import { BRANCH_FILTER, DEPARTMENTS_FILTER } from 'store/modules/contacts/constants';
import { selectContacts, selectContactsError, selectContactsLoading } from 'store/modules/contacts/selectors';
import { Routes } from 'store/modules/ui/constants';
import { ITranslator, Redux, SelectorType } from 'typings';
import { _get } from 'utils/get';

import { Error } from './styled';

const columns = [
    {
        title: t('containers.ContactForm.name'),
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: t('containers.ContactForm.branch'),
        dataIndex: 'branch',
        key: 'branch',
        filters: [
            {
                text: t('containers.ContactForm.branches.dejvice'),
                value: BRANCH_FILTER.DEJVICE,
            },
            {
                text: t('containers.ContactForm.branches.cardion'),
                value: BRANCH_FILTER.CARDION,
            },
            {
                text: t('containers.ContactForm.branches.pruhonice'),
                value: BRANCH_FILTER.PRUHONICE,
            },
            {
                text: t('containers.ContactForm.branches.stodulky'),
                value: BRANCH_FILTER.STODULKY,
            },
        ],
        onFilter: (value: string, record: Contact) => record.branch === value,
    },
    {
        title: t('containers.ContactForm.position'),
        dataIndex: 'department',
        key: 'department',
        filters: [
            {
                text: t('containers.ContactForm.positions.SERVICE'),
                value: DEPARTMENTS_FILTER.SERVICE,
            },
            {
                text: t('containers.ContactForm.positions.HOTLINE'),
                value: DEPARTMENTS_FILTER.HOTLINE,
            },
            {
                text: t('containers.ContactForm.positions.RECEPTION'),
                value: DEPARTMENTS_FILTER.RECEPTION,
            },
            {
                text: t('containers.ContactForm.positions.SALE_NEW_CARS'),
                value: DEPARTMENTS_FILTER.SALE_NEW_CARS,
            },
            {
                text: t('containers.ContactForm.positions.SALE_USED_CARS'),
                value: DEPARTMENTS_FILTER.SALE_USED_CARS,
            },
            {
                text: t('containers.ContactForm.positions.SPARE_PARTS'),
                value: DEPARTMENTS_FILTER.SPARE_PARTS,
            },
        ],
        onFilter: (value: string, record: Contact) => record.department === value,
        render: (value: string, record: Contact) => `${record.department} / ${record.position}`,
    },
    {
        title: t('containers.ContactForm.email'),
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: t('containers.ContactForm.phone'),
        dataIndex: 'phone',
        key: 'phone',
    },
];

interface Props extends ITranslator {
    getContacts?: typeof getContacts;
    contacts: SelectorType<typeof selectContacts>;
    loading: boolean;
    error: SelectorType<typeof selectContactsError>;
    push: typeof push;
    departments: SelectorType<typeof selectDepartments>;
    branches: SelectorType<typeof selectBranches>;
}

interface State {
    dataSource: Contact[];
}

class TableContent extends React.Component<Props, State> {
    state = {
        dataSource: null,
    };

    componentDidMount() {
        const { getContacts, contacts } = this.props;
        getContacts();
        contacts && this.createTableData(contacts);
    }

    componentDidUpdate(prevProps: Props) {
        const { departments } = this.props;
        const { contacts } = this.props;
        if ((!isEqual(prevProps.contacts, contacts) && departments) || (!isEqual(prevProps.departments, departments) && contacts)) {
            this.createTableData(contacts);
        }
    }

    createTableData = (contacts: Contact[]) => {
        const { departments, branches } = this.props;
        const dataSource = map(contacts, (contact: Props['contacts'][0]) => {
            const department = find(departments, (department: Props['departments'][0]) => department.id === contact.department);
            const position = find(
                department.positions,
                (position: Props['departments'][0]['positions'][0]) => position.id === contact.position,
            );
            const branch = find(branches, (branch: Props['branches'][0]) => branch.id === contact.branch);
            return {
                ...contact,
                department: _get(department, 'translation', TranslationLanguage.cs),
                position: _get(position, 'translation', TranslationLanguage.cs),
                branch: _get(branch, 'name'),
            };
        });
        this.setState({ dataSource });
    };

    render() {
        const { loading, error, t, push } = this.props;
        const { dataSource } = this.state;
        if (error) {
            return (
                <Card bordered={false}>
                    <Error>{t('routes.Contacts.error')}</Error>
                </Card>
            );
        }
        return (
            <Table
                loading={loading}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                locale={{ emptyText: t('routes.Contacts.noData') }}
                rowKey={(record: Props['contacts'][0]) => record._id}
                onRow={(record: Props['contacts'][0]) => {
                    return {
                        onClick: () => push(`${Routes.CONTACTS.path}/${record._id}`),
                    };
                }}
            />
        );
    }
}

export default compose<any>(
    connect(
        (state: Redux) => {
            return {
                contacts: selectContacts(state),
                loading: selectContactsLoading(state),
                error: selectContactsError(state),
                departments: selectDepartments(state),
                branches: selectBranches(state),
            };
        },
        {
            getContacts,
            push,
        },
    ),
    translate(),
    withRouter,
)(TableContent);
