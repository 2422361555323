import createReducer from 'utils/createReducer';

import { Manual, ManualsReducer } from './@types';
import { Types } from './actions';
import * as actions from './actions';

export const initialState: ManualsReducer = {
    filters: {
        data: {
            model: null,
            year: null,
        },
        loading: false,
        error: null,
    },
    manuals: {
        data: null,
        loading: false,
        error: null,
    },
    detail: {
        data: null,
        loading: false,
        sending: false,
        error: null,
    },
    pagination: {
        total: null,
        pageSize: 10,
        current: 1,
    },
};

export default createReducer(initialState, {
    [Types.GET_FILTERS]: (state: ManualsReducer, action: ReturnType<typeof actions.getFilters>): ManualsReducer => {
        return {
            ...state,
            filters: {
                ...state.filters,
                loading: true,
            },
        };
    },
    [Types.GET_FILTERS_SUCCESS]: (state: ManualsReducer, action: ReturnType<typeof actions.getFiltersSuccess>): ManualsReducer => {
        const [model, year] = action.payload.filters;
        return {
            ...state,
            filters: {
                ...state.filters,
                data: {
                    model,
                    year,
                },
                error: null,
                loading: false,
            },
        };
    },
    [Types.GET_FILTERS_FAILURE]: (state: ManualsReducer, action: ReturnType<typeof actions.getFiltersFailure>): ManualsReducer => {
        return {
            ...state,
            filters: {
                ...state.filters,
                error: action.payload.error,
                loading: false,
            },
        };
    },
    [Types.GET_MANUALS]: (state: ManualsReducer, action: ReturnType<typeof actions.getManuals>): ManualsReducer => {
        return {
            ...state,
            manuals: {
                ...state.manuals,
                loading: true,
            },
        };
    },
    [Types.GET_MANUALS_SUCCESS]: (state: ManualsReducer, action: ReturnType<typeof actions.getManualsSuccess>): ManualsReducer => {
        const manuals: Manual[] = action.payload.manuals;
        const { limit, total, page } = action.payload.pagination;
        return {
            ...state,
            manuals: {
                ...state.manuals,
                data: manuals,
                loading: false,
                error: null,
            },
            pagination: {
                total,
                pageSize: limit,
                current: page,
            },
        };
    },
    [Types.GET_MANUALS_FAILURE]: (state: ManualsReducer, action: ReturnType<typeof actions.updateManual.success>): ManualsReducer => {
        return {
            ...state,
            manuals: {
                ...state.manuals,
                error: action.payload,
                loading: false,
            },
        };
    },
    [Types.GET_MANUAL]: (state: ManualsReducer, action: ReturnType<typeof actions.getManual>): ManualsReducer => {
        return {
            ...state,
            detail: {
                ...state.detail,
                loading: true,
            },
        };
    },
    [Types.GET_MANUAL_SUCCESS]: (state: ManualsReducer, action: ReturnType<typeof actions.getManualSuccess>): ManualsReducer => {
        const manual: Manual = action.payload.manual;
        return {
            ...state,
            detail: {
                ...state.detail,
                data: manual,
                error: null,
                loading: false,
            },
        };
    },
    [Types.GET_MANUAL_FAILURE]: (state: ManualsReducer, action: ReturnType<typeof actions.updateManual.success>): ManualsReducer => {
        return {
            ...state,
            detail: {
                ...state.detail,
                error: action.payload,
                loading: false,
            },
        };
    },
    [Types.CREATE_MANUAL_SUCCESS]: (state: ManualsReducer, action: ReturnType<typeof actions.createManual.success>): ManualsReducer => {
        const manual: Manual = action.payload;
        return {
            ...state,
            detail: {
                ...state.detail,
                data: manual,
                sending: false,
                error: null,
            },
        };
    },
    [Types.CREATE_MANUAL_FAILURE]: (state: ManualsReducer, action: ReturnType<typeof actions.updateManual.success>): ManualsReducer => {
        return {
            ...state,
            detail: {
                ...state.detail,
                error: action.payload,
                sending: false,
            },
        };
    },
    [Types.UPDATE_MANUAL_SUCCESS]: (state: ManualsReducer, action: ReturnType<typeof actions.updateManual.success>): ManualsReducer => {
        const manual: Manual = action.payload;
        return {
            ...state,
            detail: {
                ...state.detail,
                data: manual,
                sending: false,
                error: null,
            },
        };
    },
    [Types.UPDATE_MANUAL_FAILURE]: (state: ManualsReducer, action: ReturnType<typeof actions.updateManual.request>): ManualsReducer => {
        return {
            ...state,
            detail: {
                ...state.detail,
                error: action.payload,
                sending: false,
            },
        };
    },
    [Types.DELETE_MANUAL]: (state: ManualsReducer, action: ReturnType<typeof actions.updateManual.request>): ManualsReducer => {
        return {
            ...state,
            detail: {
                ...state.detail,
                sending: true,
            },
        };
    },
    [Types.DELETE_MANUAL_SUCCESS]: (state: ManualsReducer, action: ReturnType<typeof actions.updateManual.success>): ManualsReducer => {
        return {
            ...state,
            detail: {
                ...state.detail,
                sending: false,
                error: null,
            },
        };
    },
    [Types.DELETE_MANUAL_FAILURE]: (state: ManualsReducer, action: ReturnType<typeof actions.updateManual.failure>): ManualsReducer => {
        return {
            ...state,
            detail: {
                ...state.detail,
                sending: false,
                error: action.payload.error,
            },
        };
    },
    [Types.UPDATE_MANUAL_FAILURE]: (state: ManualsReducer, action: ReturnType<typeof actions.updateManual.request>): ManualsReducer => {
        return {
            ...state,
            detail: {
                ...state.detail,
                error: action.payload,
                sending: false,
            },
        };
    },
});
