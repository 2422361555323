import { Role } from 'store/modules/ui/constants';
import { RouteRoles } from 'typings';

export default function isRouteAllowed(roles: RouteRoles, userRoles: Role[]) {
    if (roles && userRoles) {
        const { blacklist, whitelist } = roles;

        if (blacklist && blacklist.some((role: Role) => userRoles.includes(role))) {
            return false;
        }

        if (whitelist && !whitelist.some((role: Role) => userRoles.includes(role))) {
            return false;
        }
    }
    return true;
}
