import { Button } from 'antd';
import { Form as F } from 'redux-form';
import styled from 'styled-components';
import { ThemeProps } from 'stylesheets/GlobalTheme';

export const Form = styled(F)`
    display: flex;
    flex-direction: column;
`;

export const Wrapper = styled.div`
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Span = styled.span`
    text-align: center;
    margin: 10px;
    font-size: ${(props: ThemeProps) => props.theme.fontSize.large};
`;

export const StyledButton = styled(Button)`
    margin: 10px;
    width: 190px;

    .anticon-spin {
        margin-top: -7px !important;
    }
`;

export const Label = styled.label`
    font-size: ${(props: ThemeProps) => props.theme.fontSize.medium};
    width: 100%;
    text-overflow: ellipsis;
`;

export const ItemWrapper = styled.div`
    display: grid;
    grid-template-columns: 60% 30% 10%;
    justify-content: space-between;
    column-gap: 5px;
    align-items: center;
    margin-bottom: 10px;
    width: 300px;
`;

export const ChoicesTitle = styled.div`
    font-size: ${(props: ThemeProps) => props.theme.fontSize.large};
    font-weight: bold;
    margin: 15px 0;
`;
