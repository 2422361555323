import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { getToken } from 'utils/withToken';
import * as actions from './actions';
import fetcher from './requests';
import { Car, User } from 'store/modules/scanCode/@types';

function* getPresence() {
    yield takeLatest(actions.Types.GET_PRESENCE, function* handle(action: ReturnType<typeof actions.getPresence>) {
        try {
            const requests = fetcher(yield getToken());
            const cars = yield requests.getPresence();
            const users = yield all(cars.map((car: Car) => requests.getUser(car.userId)));

            const result = cars.map((car: Car) => {
                return {
                    car,
                    user: users.find((user: User) => user.id === car.userId),
                };
            });

            yield put(actions.getPresenceSuccess(result));
        } catch (err) {
            console.log('Error, presence', err);
        }
    });
}

export default function* scanCodeSaga() {
    yield fork(getPresence);
}
