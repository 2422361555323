import Row from 'antd/es/row';
import { History } from 'history';
import React, { ReactNode } from 'react';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { Routes } from 'store/modules/ui/constants';

import { FormWrapper, ImageWrapper, Wrapper } from './styled';

interface Props {
    children: ReactNode;
    history: History;
}

class SignInTemplate extends React.Component<Props> {
    render() {
        const { children, history } = this.props;
        return (
            <Wrapper>
                <Row type="flex">
                    <ImageWrapper />
                    <FormWrapper isPasswordChange={history.location.pathname === Routes.PASSWORD_CHANGE.path ? true : false}>
                        {children}
                    </FormWrapper>
                </Row>
            </Wrapper>
        );
    }
}
export default compose<any>(withRouter)(SignInTemplate);
