import { SymbolType } from 'store/modules/symbols/@types';
import { api } from 'utils/request';

declare const Conf;

export async function createSymbol(symbol: SymbolType, token: string) {
    return api(`/apps/${Conf.APP_ID}/symbols`, {
        method: 'POST',
        headers: {
            authorization: token,
        },
        body: symbol,
    });
}

export async function getSymbols(token: string) {
    return api(`/apps/${Conf.APP_ID}/symbols`, {
        method: 'GET',
        headers: {
            authorization: token,
        },
    });
}

export async function getSymbol(symbolId: string, token: string) {
    return api(`/apps/${Conf.APP_ID}/symbols/${symbolId}`, {
        method: 'GET',
        headers: {
            authorization: token,
        },
    });
}

export async function updateSymbol(symbol: SymbolType, symbolId: string, token: string) {
    return api(`/apps/${Conf.APP_ID}/symbols/${symbolId}`, {
        method: 'PUT',
        headers: {
            authorization: token,
        },
        body: symbol,
    });
}

export async function deleteSymbol(symbolId: string, token: string) {
    return api(`/apps/${Conf.APP_ID}/symbols/${symbolId}`, {
        method: 'DELETE',
        headers: {
            authorization: token,
        },
    });
}
