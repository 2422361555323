import { Ticket } from 'store/modules/tickets/@types';
import createReducer from 'utils/createReducer';

import { DetailReducer } from './@types';
import { Types } from './actions';
import * as actions from './actions';

export const initialState: DetailReducer = {
    data: null,
    error: null,
    loading: false,
};

export default createReducer(initialState, {
    [Types.GET_TICKET]: (state: DetailReducer): DetailReducer => {
        return {
            ...state,
            loading: true,
        };
    },
    [Types.GET_TICKET_SUCCESS]: (state: DetailReducer, action: ReturnType<typeof actions.getTicketSuccess>): DetailReducer => {
        const data: Ticket = action.payload.ticket;
        return {
            ...state,
            data,
            loading: false,
            error: null,
        };
    },
    [Types.GET_TICKET_FAILURE]: (state: DetailReducer, action: ReturnType<typeof actions.getTicketFailure>): DetailReducer => {
        return {
            ...state,
            error: action.payload.error,
            loading: false,
        };
    },
    [Types.CLOSE_TICKET]: (state: DetailReducer): DetailReducer => {
        return {
            ...state,
            loading: true,
        };
    },
    [Types.CLOSE_TICKET_SUCCESS]: (state: DetailReducer, action: ReturnType<typeof actions.closeTicketSuccess>): DetailReducer => {
        const data: Ticket = action.payload.ticket;
        return {
            ...state,
            data,
            loading: false,
            error: null,
        };
    },
    [Types.CLOSE_TICKET_FAILURE]: (state: DetailReducer, action: ReturnType<typeof actions.closeTicketFailure>): DetailReducer => {
        return {
            ...state,
            error: action.payload.error,
            loading: false,
        };
    },
    [Types.UPDATE_TICKET]: (state: DetailReducer): DetailReducer => {
        return {
            ...state,
            loading: true,
        };
    },
    [Types.UPDATE_TICKET_SUCCESS]: (state: DetailReducer, action: ReturnType<typeof actions.updateTicketSuccess>): DetailReducer => {
        const data: Ticket = action.payload.ticket;
        return {
            ...state,
            data,
            loading: false,
            error: null,
        };
    },
    [Types.UPDATE_TICKET_FAILURE]: (state: DetailReducer, action: ReturnType<typeof actions.updateTicketFailure>): DetailReducer => {
        return {
            ...state,
            error: action.payload.error,
            loading: false,
        };
    },
    [Types.UPDATE_TICKET]: (state: DetailReducer): DetailReducer => {
        return {
            ...state,
            loading: true,
        };
    },
    [Types.UPDATE_TICKET_SUCCESS]: (state: DetailReducer, action: ReturnType<typeof actions.updateTicketSuccess>): DetailReducer => {
        const data: Ticket = action.payload.ticket;
        return {
            ...state,
            data,
            loading: false,
            error: null,
        };
    },
    [Types.UPDATE_TICKET_FAILURE]: (state: DetailReducer, action: ReturnType<typeof actions.updateTicketFailure>): DetailReducer => {
        return {
            ...state,
            error: action.payload.error,
            loading: false,
        };
    },
    [Types.SCHEDULE_NOTIFICATION]: (state: DetailReducer): DetailReducer => {
        return {
            ...state,
            loading: true,
        };
    },
    [Types.SCHEDULE_NOTIFICATION_SUCCESS]: (
        state: DetailReducer,
        action: ReturnType<typeof actions.scheduleNotificationSuccess>,
    ): DetailReducer => {
        const data: Ticket = action.payload.ticket;
        return {
            ...state,
            data,
            loading: false,
            error: null,
        };
    },
    [Types.SCHEDULE_NOTIFICATION_FAILURE]: (
        state: DetailReducer,
        action: ReturnType<typeof actions.scheduleNotificationFailure>,
    ): DetailReducer => {
        return {
            ...state,
            error: action.payload.error,
            loading: false,
        };
    },
    [Types.CANCEL_NOTIFICATION_SUCCESS]: (
        state: DetailReducer,
        action: ReturnType<typeof actions.scheduleNotificationSuccess>,
    ): DetailReducer => {
        const data: Ticket = action.payload.ticket;
        return {
            ...state,
            data,
            loading: false,
            error: null,
        };
    },
    [Types.CANCEL_NOTIFICATION_FAILURE]: (
        state: DetailReducer,
        action: ReturnType<typeof actions.scheduleNotificationFailure>,
    ): DetailReducer => {
        return {
            ...state,
            error: action.payload.error,
            loading: false,
        };
    },
});
