import createReducer from 'utils/createReducer';

import { AuthReducer } from './@types.d';
import { Types } from './actions';
import * as actions from './actions';

export const initialState: AuthReducer = {
    user: null,
    session: null,
    error: null,
    signedOutEmail: null,
};

export default createReducer(initialState, {
    [Types.SIGN_IN_SUCCESS]: (state: AuthReducer, action: ReturnType<typeof actions.signInSuccess>): AuthReducer => {
        const { user, session } = action.payload;
        return {
            ...initialState,
            user,
            session,
        };
    },
    [Types.SIGN_IN_FAILURE]: (state: AuthReducer, action: ReturnType<typeof actions.signInFailure>): AuthReducer => {
        const { error } = action.payload;
        return {
            ...state,
            error,
        };
    },
    [Types.SIGN_OUT_SUCCESS]: (): AuthReducer => {
        return {
            ...initialState,
        };
    },
    [Types.CHANGE_PASSWORD_SUCCESS]: (state: AuthReducer): AuthReducer => {
        return {
            ...state,
            user: null,
        };
    },
    [Types.CHANGE_PASSWORD_FAILURE]: (state: AuthReducer, action: ReturnType<typeof actions.changePasswordFailure>): AuthReducer => {
        const { error } = action.payload;
        return {
            ...state,
            error,
        };
    },
    [Types.RESET_PASSWORD_SUCCESS]: (state: AuthReducer, action: ReturnType<typeof actions.resetPasswordSuccess>): AuthReducer => {
        const { signedOutEmail } = action.payload;
        return {
            ...state,
            signedOutEmail,
        };
    },
    [Types.RESET_PASSWORD_FAILURE]: (state: AuthReducer, action: ReturnType<typeof actions.resetPasswordFailure>): AuthReducer => {
        const { error } = action.payload;
        return {
            ...state,
            error,
        };
    },
    [Types.RESET_PASSWORD_CONFIRM_SUCCESS]: (state: AuthReducer): AuthReducer => {
        return {
            ...initialState,
        };
    },
    [Types.RESET_PASSWORD_CONFIRM_FAILURE]: (
        state: AuthReducer,
        action: ReturnType<typeof actions.resetPasswordConfirmFailure>,
    ): AuthReducer => {
        const { error } = action.payload;
        return {
            ...state,
            error,
        };
    },
});
