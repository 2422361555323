import { t } from 'i18next';
import head from 'lodash-es/head';
import { SagaIterator } from 'redux-saga';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { TicketStateName, TicketTypeName } from 'store/modules/appInfo/constants';
import { openNotification } from 'utils/notifications';
import translator from 'utils/translator';
import { getToken } from 'utils/withToken';

import { Filter, GetTicketsParams } from './@types';
import * as actions from './actions';
import fetcher from './requests';

const translate = translator('routes.Tickets.ticketTypes');

const states = {
    [translate('closed')]: TicketStateName.CLOSED,
    [translate('open')]: TicketStateName.OPEN,
    [translate('pending')]: TicketStateName.PENDING,
};

const types = {
    [translate('approval')]: TicketTypeName.SERVICE_APPROVAL,
    [translate('reminder')]: TicketTypeName.SERVICE_REMINDER,
    [translate('request')]: TicketTypeName.SERVICE_REQUEST,
    [translate('cardExpiration')]: TicketTypeName.CARD_EXPIRATION,
    [translate('warrantyExpiration')]: TicketTypeName.WARRANTY_EXPIRATION,
    [translate('approvalChoices')]: TicketTypeName.SERVICE_APPROVAL_CHOICE,
    [translate('signUpHelp')]: TicketTypeName.SIGN_UP_HELP,
};

const normalizeFilterParams = (params: Filter): GetTicketsParams => {
    const { branch, status, type } = params;
    return Object.assign({}, params, branch && { branch }, status && { status: states[head(status)] }, type && { type: types[head(type)] });
};

export function* getTickets() {
    yield takeLatest(actions.Types.GET_TICKETS, function* handle(action: ReturnType<typeof actions.getTickets>) {
        try {
            const {
                payload: { params },
            } = action;
            const reqParams = params && normalizeFilterParams(params);

            const requests = fetcher(yield getToken());
            const { docs: tickets, ...pagination } = yield requests.getTickets(reqParams);
            yield put(actions.getTicketsSuccess(tickets, pagination));
        } catch (err) {
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
            yield put(actions.getTicketsFailure(err));
        }
    });
}

export default function* ticketsSage(): SagaIterator {
    yield fork(getTickets);
}
