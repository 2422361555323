import { t } from 'i18next';
import React from 'react';
import { translate } from 'react-i18next';
import { compose } from 'redux';
import { Field, FieldArray, FormSection, InjectedFormProps, reduxForm } from 'redux-form';
import TextField from 'redux-form-antd/es/components/TextField';
import { Branch } from 'store/modules/branches/@types';
import { ITranslator } from 'typings';
import { phoneNumber, required } from 'utils/validation';
import FormTimeRange from 'view/components/FormTimeRange';
import { FormSectionLabel } from 'view/containers/BranchForm/styled';

export enum WeekdaysEnum {
    MONDAY = 'monday',
    TUESDAY = 'tuesday',
    WEDNESDAY = 'wednesday',
    THURSDAY = 'thursday',
    FRIDAY = 'friday',
    SATURDAY = 'saturday',
    SUNDAY = 'sunday',
}

const weekdays = [
    WeekdaysEnum.MONDAY,
    WeekdaysEnum.TUESDAY,
    WeekdaysEnum.WEDNESDAY,
    WeekdaysEnum.THURSDAY,
    WeekdaysEnum.FRIDAY,
    WeekdaysEnum.SATURDAY,
    WeekdaysEnum.SUNDAY,
];

interface Props extends InjectedFormProps, ITranslator {
    onSubmit: (branch: Branch) => void;
}

interface State {}

class BranchForm extends React.Component<Props, State> {
    renderOpeningHoursFields = (props: any) => {
        return (
            <>
                {weekdays.map((day: string, index: number) => (
                    <Field
                        key={day}
                        type="text"
                        name={`openingHours[${index}]`}
                        component={FormTimeRange}
                        label={t(`containers.BranchForm.days.${day}`)}
                        timeFormat={'HH:mm'}
                        minuteStep={30}
                    />
                ))}
            </>
        );
    };

    render() {
        const { handleSubmit, onSubmit } = this.props;

        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <Field
                    label={t('containers.BranchForm.labels.name')}
                    name="name"
                    component={TextField}
                    validate={[required]}
                    placeholder={t('containers.BranchForm.placeholders.name')}
                />
                <FormSection name="address">
                    <Field
                        label={t('containers.BranchForm.labels.street')}
                        name="street"
                        component={TextField}
                        validate={[required]}
                        placeholder={t('containers.BranchForm.placeholders.street')}
                    />
                    <Field
                        label={t('containers.BranchForm.labels.city')}
                        name="city"
                        component={TextField}
                        validate={[required]}
                        placeholder={t('containers.BranchForm.placeholders.city')}
                    />
                </FormSection>
                <FormSectionLabel>{t('containers.BranchForm.openingHours')}</FormSectionLabel>
                <FieldArray name="openingHours" component={this.renderOpeningHoursFields} />
                <FormSectionLabel>{t('containers.BranchForm.phoneNumbers')}</FormSectionLabel>
                <FormSection name="phoneNumbers">
                    <Field
                        name="assistance"
                        label={t('containers.BranchForm.labels.assistance')}
                        validate={[phoneNumber, required]}
                        component={TextField}
                        placeholder={t('containers.BranchForm.placeholders.phoneNumber')}
                    />
                    <Field
                        name="towing"
                        label={t('containers.BranchForm.labels.towing')}
                        validate={[phoneNumber, required]}
                        component={TextField}
                        placeholder={t('containers.BranchForm.placeholders.phoneNumber')}
                    />
                </FormSection>
                <FormSectionLabel>{t('containers.BranchForm.links')}</FormSectionLabel>
                <FormSection name="links">
                    <Field
                        name="facebook"
                        component={TextField}
                        validate={[required]}
                        label={t('containers.BranchForm.labels.facebook')}
                        placeholder={t('containers.BranchForm.placeholders.link')}
                    />
                    <Field
                        name="web"
                        component={TextField}
                        validate={[required]}
                        label={t('containers.BranchForm.labels.web')}
                        placeholder={t('containers.BranchForm.placeholders.link')}
                    />
                </FormSection>
            </form>
        );
    }
}

export default compose<any>(
    reduxForm({
        form: 'branchForm',
    }),
    translate(),
)(BranchForm);
