import styled from 'styled-components';
import { ThemeProps } from 'stylesheets/GlobalTheme';

export const FilterForm = styled.form`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${(props: ThemeProps) => props.theme.resolution.screenMd}) {
        flex-direction: row;
    }
`;

export const SelectWrapper = styled.div`
    width: 100%;
    margin: 10px;

    @media screen and (min-width: ${(props: ThemeProps) => props.theme.resolution.screenMd}) {
        width: 50%;
        margin: 0 10px;
    }

    @media screen and (min-width: ${(props: ThemeProps) => props.theme.resolution.screenLg}) {
        width: 25%;
    }

    .ant-form-item-control-wrapper {
        width: 100%;
    }
`;
