import { confirmPassword, createValidator, email, minLength, required } from 'utils/validation';

const ForgottenPasswordFormValidation = createValidator({
    email: [required, email],
    newPassword: [required, minLength(6)],
    passwordConfirmation: [required, confirmPassword, minLength(6)],
    code: [required],
});

export default ForgottenPasswordFormValidation;
