import { AxiosError } from 'axios';
import { createSelector } from 'reselect';
import { Redux } from 'typings';
import { _get } from 'utils/get';

import { App, AppInfoReducer, Branch, Contacts, Department, Tickets, TicketState, TicketType, TranslatedProperty } from './@types.d';

export const selectAppInfoRedcuer = (state: Redux): AppInfoReducer => state.appInfo;

// Root selectors
export const selectAppData = createSelector(selectAppInfoRedcuer, (state: AppInfoReducer): App => state.data);

export const selectContacts = createSelector(selectAppData, (app: App): Contacts => _get(app, 'contacts'));
export const selectTickets = createSelector(selectAppData, (app: App): Tickets => _get(app, 'tickets'));
export const selectSymbols = createSelector(selectAppData, (app: App): TranslatedProperty[] => _get(app, 'symbols'));
export const selectBranches = createSelector(selectAppData, (app: App): Branch[] => _get(app, 'branches'));

// Lifecycle selectors
export const selectLoading = createSelector(selectAppInfoRedcuer, (state: AppInfoReducer): boolean => state.loading);
export const selectError = createSelector(selectAppInfoRedcuer, (state: AppInfoReducer): AxiosError => state.error);

//Tickets
export const selectTicketTypes = createSelector(selectTickets, (tickets: Tickets): TicketType[] => _get(tickets, 'types'));
export const selectTicketStates = createSelector(selectTickets, (tickets: Tickets): TicketState[] => _get(tickets, 'states'));

//Contacts
export const selectDepartments = createSelector(selectContacts, (contacts: Contacts): Department[] => _get(contacts, 'departments'));
