import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import FromS3 from '../FromS3';
import { ContentWrapper, IconWrapper, TextWrapper, Wrapper } from './styled';

interface Props {
    model: string;
    year: number;
    onSelect: () => void;
    carTypeImage?: string;
}

class CarCard extends React.Component<Props> {
    render() {
        const { model, year, carTypeImage, onSelect } = this.props;
        return (
            <Wrapper onClick={onSelect}>
                <IconWrapper>
                    <FromS3 name={carTypeImage || 'cars/volvo-logo.png'}>
                        {(url: string) => <LazyLoadImage src={url} alt="icon" height={60} width={'auto'} />}
                    </FromS3>
                </IconWrapper>
                <ContentWrapper>
                    <TextWrapper>{model}</TextWrapper>
                    <TextWrapper>{year}</TextWrapper>
                </ContentWrapper>
            </Wrapper>
        );
    }
}

export default CarCard;
