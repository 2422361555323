import { t } from 'i18next';
import { getFormValues } from 'redux-form';
import { SagaIterator } from 'redux-saga';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { openNotification } from 'utils/notifications';
import { uploadAppData } from 'utils/s3';
import { getToken } from 'utils/withToken';

import * as actions from './actions';
import { allowedImageTypes, FILE_SIZE } from './constants';
import fetcher from './requests';
import { selectCarTypeId, selectTypeById } from './selectors';

export function* getAllCarTypes() {
    yield takeLatest(actions.Types.GET_ALL_CAR_TYPES, function* handle(action: ReturnType<typeof actions.getAllCarTypes>) {
        try {
            const requests = fetcher(yield getToken());
            const carTypes = yield requests.getCarTypes();
            yield put(actions.getAllCarTypesSuccess(carTypes.docs));
        } catch (err) {
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
            yield put(actions.getAllCarTypesFailure(err));
        }
    });
}

export function* getFilteredCarTypes() {
    yield takeLatest(actions.getFilteredCarTypes.REQUEST, function* handle(action: ReturnType<typeof actions.getFilteredCarTypes>) {
        try {
            const { model, year } = action.payload;
            const requests = fetcher(yield getToken());
            const carTypes = yield requests.getCarTypes(model, year);
            yield put(actions.getFilteredCarTypesSuccess(carTypes.docs));
        } catch (err) {
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
            yield put(actions.getFilteredCarTypesFailure(err));
        }
    });
}

export function* getCarType() {
    yield takeLatest(actions.Types.GET_CAR_TYPE, function* handle(action: ReturnType<typeof actions.getCarType>) {
        const { carId } = action.payload;
        const [car] = yield select(selectTypeById(carId));
        if (car) {
            yield put(actions.getCarTypeSuccess(car));
        } else {
            yield put(actions.getCarTypeFailure(t('containers.CarForm.notFound')));
        }
    });
}

export function* updateCarType() {
    yield takeLatest(actions.updateCarType.REQUEST, function* handle(action: ReturnType<typeof actions.updateCarType>) {
        try {
            const { carTypeImage } = action.payload;
            const requests = fetcher(yield getToken());
            const carTypeId = yield select(selectCarTypeId);
            const filters = yield select(getFormValues('carTypesFilters'));

            if (!carTypeImage?.file) {
                yield call(openNotification, 'error', t('validation.title'), t('validation.carTypeImageMissing'));
                return yield put(actions.updateCarType.failure(t('validation.carTypeImageMissing')));
            }

            if (carTypeImage.file.size > FILE_SIZE || !allowedImageTypes.includes(carTypeImage.file.type)) {
                yield call(openNotification, 'error', t('validation.title'), t('validation.carTypeImageInvalid'));
                return yield put(actions.updateCarType.failure(t('validation.carTypeImageInvalid')));
            }
            const { key } = yield uploadAppData(carTypeImage);

            yield requests.updateCarType(carTypeId, key);
            yield call(openNotification, 'success', t('apiRequest.success.title'), t('apiRequest.success.carTypeImageSaved'));
            yield put(actions.updateCarTypeSuccess());

            if (filters) {
                yield put(actions.refreshFilteredCarTypes(filters));
            } else {
                yield put(actions.getAllCarTypes());
            }
        } catch (err) {
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
            yield put(actions.updateCarTypeFailure(err));
        }
    });
}

export default function* carTypesSage(): SagaIterator {
    yield fork(getAllCarTypes);
    yield fork(getFilteredCarTypes);
    yield fork(getCarType);
    yield fork(updateCarType);
}
