import React from 'react';
import { Field, WrappedFieldArrayProps } from 'redux-form';
import { Choice, VideoData } from 'store/modules/video/@types';
import { formatPrice } from 'utils/helpers';
import { ItemWrapper, Label } from './styled';

interface Props {
    formValues: WrappedFieldArrayProps<Choice[]>;
    data: VideoData;
    disabled: boolean;
}

const Items = ({ formValues, data, disabled }: Props) => {
    return (
        <>
            {formValues.fields.map((item: any, index: number) => (
                <ItemWrapper key={index}>
                    <Label>{data.choices[index].name}</Label>
                    <Label>{formatPrice(data.choices[index].price)}</Label>
                    <Field name={`${item}.approved`} component="input" type="checkbox" disabled={disabled} />
                </ItemWrapper>
            ))}
        </>
    );
};

export default Items;
