import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import SelectField from 'redux-form-antd/es/components/SelectField';
import TextField from 'redux-form-antd/es/components/TextField';
import { SelectOption } from 'store/modules/manuals/@types';
import { UserGroup } from 'store/modules/users/@types';
import { createUser, updateUser } from 'store/modules/users/actions';
import { selectUserGroupsLoading } from 'store/modules/users/selectors';
import { ITranslator, Redux, SelectorType } from 'typings';
import { StyledButton } from './styled';
import validate from './validate';

interface Props extends InjectedFormProps<{ userGroup: string }>, ITranslator {
    edit?: boolean;
    userGroups: UserGroup[];
    userGroupsLoading: SelectorType<typeof selectUserGroupsLoading>;
}

const initialState = {
    userGroupsOptions: null as SelectOption[],
};

type State = Readonly<typeof initialState>;

class AddUser extends React.Component<Props, State> {
    state = initialState;

    componentDidMount() {
        const { userGroups, t } = this.props;
        if (userGroups) {
            this.setState({
                userGroupsOptions: userGroups.map((userGroup: UserGroup) => ({
                    value: userGroup.GroupName,
                    label: t(`userGroups.${userGroup.GroupName}`),
                })),
            });
        }
    }

    handleUpdateUser = (data: Record<string, string>, dispatch: any) => {
        const { initialValues } = this.props;

        updateUser({ ...data, fromGroup: initialValues.userGroup }, dispatch);
    };

    render() {
        const { handleSubmit, t, submitting, edit, userGroupsLoading } = this.props;
        const { userGroupsOptions } = this.state;

        return (
            <div>
                <form onSubmit={handleSubmit(edit ? this.handleUpdateUser : createUser)}>
                    <Field name="email" component={TextField} label={t('containers.AddUser.email')} required />
                    <Field
                        name="userGroup"
                        component={SelectField}
                        options={userGroupsOptions}
                        label={t('containers.AddUser.userGroup')}
                        loading={userGroupsLoading}
                        required
                    />
                    {!edit && <Field name="password" component={TextField} type="password" label={t('containers.AddUser.password')} />}
                    <StyledButton type="primary" htmlType="submit" loading={submitting}>
                        {t('containers.AddUser.submit')}
                    </StyledButton>
                </form>
            </div>
        );
    }
}
export default compose<any>(
    reduxForm({
        form: 'addUser',
        validate,
        enableReinitialize: true,
    }),
    connect((state: Redux) => ({
        userGroupsLoading: selectUserGroupsLoading(state),
    })),
    translate(),
)(AddUser);
