import { AxiosError } from 'axios';
import filter from 'lodash-es/filter';
import sortBy from 'lodash-es/sortBy';
import uniqBy from 'lodash-es/uniqBy';
import { createSelector } from 'reselect';
import { Redux } from 'typings';

import { SelectOption } from '../manuals/@types';
import { createCarOptions, createYearOptions } from '../manuals/utils/options';
import { CarType, CarTypeReducer, Data, FilteredData } from './@types.d';

export const selectCarTypesReducer = (state: Redux): CarTypeReducer => state.carTypes;

// Root selectors
export const selectData = createSelector(selectCarTypesReducer, (state: CarTypeReducer): Data => state.data);
export const selectFilteredData = createSelector(selectCarTypesReducer, (state: CarTypeReducer): FilteredData => state.filteredData);

// Car types
export const selectTypes = createSelector(selectData, (data: Data): CarType[] => data.allCarTypes);
export const selectFilteredTypes = createSelector(
    selectFilteredData,
    (filteredData: FilteredData): CarType[] => filteredData.filteredCarTypes,
);
export const selectTypeById = (carTypeId: string) => createSelector(selectTypes, (data: CarType[]) => filter(data, { id: carTypeId }));

export const selectCarType = createSelector(selectCarTypesReducer, (state: CarTypeReducer): CarType => state.detail.data);
export const selectCarTypeId = createSelector(selectCarType, (data: CarType): string => data.id);

// Lifecycle selectors
export const selectLoading = createSelector(selectCarTypesReducer, (state: CarTypeReducer): boolean => state.loading);
export const selectError = createSelector(selectCarTypesReducer, (state: CarTypeReducer): AxiosError => state.error);
export const selectDetailLoading = createSelector(selectCarTypesReducer, (state: CarTypeReducer): boolean => state.detail.loading);
export const selectDetailError = createSelector(selectCarTypesReducer, (state: CarTypeReducer): string => state.detail.error);

// Filters
export const selectCarOptions = createSelector(
    selectTypes,
    (carTypes: CarType[]): SelectOption[] => carTypes && uniqBy(carTypes, 'model').map(createCarOptions),
);

export const selectYearOptions = createSelector(selectTypes, (carTypes: CarType[]): SelectOption[] => {
    let adjustedYearOptions = carTypes && carTypes.map(createYearOptions);
    adjustedYearOptions = uniqBy(adjustedYearOptions, 'label');
    adjustedYearOptions = sortBy(adjustedYearOptions, 'label');
    return adjustedYearOptions;
});
