import { Manual, TableRow } from '../@types';

const getTableRows = (manuals: Manual[]): TableRow[] =>
    manuals &&
    manuals.map((manual: Manual, index: number) => ({
        ...manual,
        key: index,
    }));

export default getTableRows;
