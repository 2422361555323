import CognitoIdentityServiceProvider, { UserType } from 'aws-sdk/clients/cognitoidentityserviceprovider';

export const listUsersInGroup = async (userPoolId: string, userGroup: string): Promise<UserType[]> => {
    const cognito = new CognitoIdentityServiceProvider();

    const params = {
        UserPoolId: userPoolId,
        GroupName: userGroup,
        Limit: 60,
    };

    const users: UserType[] = [];
    let response = await cognito.listUsersInGroup(params).promise();

    users.push(...(response.Users || []));

    while (response.NextToken) {
        response = await cognito.listUsersInGroup(params).promise();

        users.push(...(response.Users || []));
    }

    return users;
};
