import PageHeader from 'ant-design-pro/lib/PageHeader';
import Card from 'antd/es/card';
import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { SelectField } from 'redux-form-antd';
import { CarType } from 'store/modules/carTypes/@types';
import { getAllCarTypes, getFilteredCarTypes } from 'store/modules/carTypes/actions';
import { selectCarOptions, selectFilteredTypes, selectTypes, selectYearOptions } from 'store/modules/carTypes/selectors';
import { SelectOption } from 'store/modules/manuals/@types';
import { ITranslator, Redux, SelectorType } from 'typings';
import CarTypesList from 'view/containers/CarTypesList';
import { FilterForm, SelectWrapper } from './styled';

interface Props extends ITranslator, InjectedFormProps {
    carOptions: SelectOption[];
    yearOptions: SelectOption[];
    selectCarOptions?: SelectorType<typeof selectCarOptions>;
    selectYearOptions?: SelectorType<typeof selectYearOptions>;
    getAllCarTypes?: typeof getAllCarTypes;
    getFilteredCarTypes?: typeof getFilteredCarTypes;
    carTypes: CarType[];
    filteredCarTypes?: CarType[];
}

class CarTypes extends React.Component<Props> {
    componentDidMount() {
        const { getAllCarTypes } = this.props;
        getAllCarTypes();
    }

    renderCarOptions = () => {
        const { carOptions } = this.props;

        let adjustedCarOptions;
        if (carOptions) {
            adjustedCarOptions = carOptions.map((option: SelectOption) => {
                return { label: option.label, value: option.value };
            });
        }

        return adjustedCarOptions;
    };

    renderYearOptions = () => {
        const { yearOptions } = this.props;
        return yearOptions.map((option: SelectOption) => {
            return { label: option.label, value: option.label };
        });
    };

    renderContent = () => {
        const { t, handleSubmit } = this.props;
        return (
            <FilterForm>
                <SelectWrapper>
                    <Field
                        name="model"
                        component={SelectField}
                        options={this.renderCarOptions()}
                        mode="multiple"
                        placeholder={t('routes.CarTypes.filterModel')}
                        onChange={() => setTimeout(handleSubmit(getFilteredCarTypes))}
                        hasFeedback={false}
                    />
                </SelectWrapper>
                <SelectWrapper>
                    <Field
                        name="year"
                        component={SelectField}
                        options={this.renderYearOptions()}
                        mode="multiple"
                        placeholder={t('routes.CarTypes.filterYear')}
                        onChange={() => setTimeout(handleSubmit(getFilteredCarTypes))}
                        hasFeedback={false}
                    />
                </SelectWrapper>
            </FilterForm>
        );
    };

    render() {
        const { t, carTypes, filteredCarTypes } = this.props;

        return (
            <>
                <PageHeader itemRender={null} title={t('routes.CarTypes.title')} content={this.renderContent()} />
                <Card bordered={false}>
                    <CarTypesList carTypes={filteredCarTypes || carTypes} />
                </Card>
            </>
        );
    }
}

export default compose<any>(
    reduxForm({
        form: 'carTypesFilters',
    }),
    connect(
        (state: Redux) => ({
            carOptions: selectCarOptions(state),
            yearOptions: selectYearOptions(state),
            carTypes: selectTypes(state),
            filteredCarTypes: selectFilteredTypes(state),
        }),
        { getAllCarTypes, getFilteredCarTypes },
    ),
    translate(),
)(CarTypes);
