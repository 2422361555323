import { api } from 'utils/request';

import { GetTicketsParams } from './@types';

declare const Conf;

const fetcher = (token: string) => ({
    getTickets: (params: GetTicketsParams) =>
        api(
            `apps/${Conf.APP_ID}/tickets`,
            {
                method: 'GET',
                headers: {
                    authorization: token,
                },
            },
            params,
        ),
});

export default fetcher;
