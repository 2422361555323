import { AxiosError } from 'axios';
import { App } from 'store/modules/appInfo/@types';

export enum Types {
    GET_APP_INFO = 'tickets/GET_APP_INFO',
    GET_APP_INFO_SUCCESS = 'tickets/GET_APP_INFO_SUCCESS',
    GET_APP_INFO_FAILURE = 'tickets/GET_APP_INFO_FAILURE',
}

export const getAppInfo = () => ({
    type: Types.GET_APP_INFO,
});

export const getAppInfoSuccess = (appInfo: App) => ({
    type: Types.GET_APP_INFO_SUCCESS,
    payload: { appInfo },
});

export const getAppInfoFailure = (error: AxiosError) => ({
    type: Types.GET_APP_INFO_FAILURE,
    payload: { error },
});
