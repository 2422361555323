import styled from 'styled-components';
import { ThemeProps } from 'stylesheets/GlobalTheme';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 200px;
    margin: 20px;
    border: 1px solid ${(props: ThemeProps) => props.theme.colors.lightGrey};
    border-radius: 5%;

    &:hover {
        cursor: pointer;
        transition: 0.3s;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
    }
`;

export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
`;

export const StyledImg = styled.img`
    height: 60px;
    width: auto;
`;

export const TextWrapper = styled.span`
    padding: 0 15px;
    text-align: center;
    :last-child {
        padding-top: 10px;
    }
`;
