import PageHeader from 'ant-design-pro/lib/PageHeader';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import CTAButton from 'components/CTAButton';
import UserList from 'containers/UserList';
import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'redux';
import { Routes } from 'store/modules/ui/constants';
import { selectUsersInGroups } from 'store/modules/users/selectors';
import { ITranslator, Redux } from 'typings';
import { ExtendedUserType } from 'store/modules/users/@types';

interface Props extends ITranslator {
    push?: typeof push;
    users?: ExtendedUserType[];
}

class Users extends React.Component<Props> {
    handleClickAddUser = () => {
        const { push } = this.props;
        push(Routes.ADD_USER.path);
    };

    render() {
        const { t, users } = this.props;

        return (
            <>
                <PageHeader
                    itemRender={null}
                    action={
                        <Button type="primary" className="action" onClick={this.handleClickAddUser}>
                            {t('routes.Users.addUser')}
                        </Button>
                    }
                    title={`${t('routes.Users.title')} (${users?.length ? users?.length : 0})`}
                />
                <Card bordered={false}>
                    <UserList />
                </Card>
                <CTAButton onClick={this.handleClickAddUser} />
            </>
        );
    }
}
export default compose<any>(
    translate(),
    connect(
        (state: Redux) => ({
            users: selectUsersInGroups(state),
        }),
        { push },
    ),
)(Users);
