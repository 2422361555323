import Button from 'antd/es/button';
import React from 'react';
import { Field, InjectedFormProps } from 'redux-form';
import TextField from 'redux-form-antd/es/components/TextField';
import { changePassword } from 'store/modules/auth/actions';
import { ITranslator } from 'typings';

import { Form } from './styled';

interface Props extends ITranslator {
    submitting: boolean;
    handleSubmit: InjectedFormProps['handleSubmit'];
}

const passwordChangeForm = ({ handleSubmit, t, submitting }: Props) => {
    return (
        <Form onSubmit={handleSubmit(changePassword)}>
            <Field name="newPassword" type="password" size="large" label={t('routes.PasswordChange.newPassword')} component={TextField} />
            <Field
                name="passwordConfirmation"
                type="password"
                size="large"
                label={t('routes.PasswordChange.passwordConfirmation')}
                component={TextField}
            />
            <Button htmlType="submit" type="primary" loading={submitting}>
                {t('routes.PasswordChange.submit')}
            </Button>
        </Form>
    );
};
export default passwordChangeForm;
