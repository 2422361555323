import PageHeader from 'ant-design-pro/lib/PageHeader';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import React from 'react';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import TableContent from 'view/containers/Contacts/tableContent';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectContacts } from 'store/modules/contacts/selectors';
import { Routes } from 'store/modules/ui/constants';
import { ITranslator, Redux, SelectorType } from 'typings';

interface Props extends ITranslator {
    contacts: SelectorType<typeof selectContacts>;
}

class Contacts extends React.Component<Props> {
    render() {
        const { t, contacts } = this.props;

        return (
            <>
                <PageHeader
                    itemRender={null}
                    title={`${t('routes.Contacts.title')} (${contacts?.length ? contacts?.length : 0})`}
                    action={
                        <Button type="primary" className="action">
                            <Link to={Routes.ADD_CONTACT.path}>{t('routes.AddContact.add')}</Link>
                        </Button>
                    }
                />
                <Card bordered={false}>
                    <TableContent />
                </Card>
            </>
        );
    }
}

export default compose<any>(
    connect((state: Redux) => {
        return {
            contacts: selectContacts(state),
        };
    }, null),
    translate(),
)(Contacts);
