import PageHeader from 'ant-design-pro/lib/PageHeader';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import Row from 'antd/es/row';
import CenteredSpin from 'components/CenteredSpin';
import map from 'lodash-es/map';
import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'react-router-redux';
import { compose } from 'redux';
import { Branch } from 'store/modules/branches/@types';
import { getBranches } from 'store/modules/branches/actions';
import { selectBranches, selectBranchesError, selectBranchesLoading } from 'store/modules/branches/selectors';
import { Routes } from 'store/modules/ui/constants';
import { ITranslator, Redux, SelectorType } from 'typings';
import { ColStyled } from 'view/routes/Branches/styled';
import { Error, StyledCard } from './styled';

interface Props extends ITranslator {
    data?: SelectorType<typeof selectBranches>;
    loading?: SelectorType<typeof selectBranchesLoading>;
    error?: SelectorType<typeof selectBranchesError>;
    getBranches?: typeof getBranches;
    push?: typeof push;
}

class Branches extends React.Component<Props> {
    componentDidMount() {
        const { getBranches } = this.props;
        getBranches();
    }

    handleClickAddBranch = () => {
        const { push } = this.props;
        push(Routes.ADD_BRANCH.path);
    };

    render() {
        const { data, loading, error, t } = this.props;

        if (error) {
            return (
                <Card bordered={false}>
                    <Error>{t('routes.Branch.error')}</Error>
                </Card>
            );
        }

        if (loading) {
            return <CenteredSpin />;
        }

        return (
            <>
                <PageHeader
                    itemRender={null}
                    action={
                        <Button type="primary" className="action" onClick={this.handleClickAddBranch}>
                            {t('routes.Branches.addBranch')}
                        </Button>
                    }
                    title={t('routes.Branches.title')}
                />
                <Card bordered={false}>
                    <Row gutter={16}>
                        {map(data, (branch: Branch) => {
                            return (
                                <ColStyled key={branch.id} className="gutter-row" span={8}>
                                    <Link to={`${Routes.BRANCHES.path}/${branch.id}`}>
                                        <StyledCard>
                                            <Card.Meta
                                                title={branch.name}
                                                description={`${branch.address.street}, ${branch.address.city}`}
                                            />
                                        </StyledCard>
                                    </Link>
                                </ColStyled>
                            );
                        })}
                    </Row>
                </Card>
            </>
        );
    }
}
export default compose<any>(
    translate(),
    connect(
        (state: Redux) => ({
            data: selectBranches(state),
            loading: selectBranchesLoading(state),
            error: selectBranchesError(state),
        }),
        { getBranches, push },
    ),
)(Branches);
