import { AxiosError } from 'axios';
import { createSelector } from 'reselect';
import { Redux } from 'typings';

import { VideoData, VideoReducer } from './@types.d';

const selectVideoSubstate = (state: Redux) => state.video;

export const selectData = createSelector(selectVideoSubstate, (video: VideoReducer): VideoData => video.data);
export const selectLoading = createSelector(selectVideoSubstate, (video: VideoReducer): boolean => video.loading);
export const selectError = createSelector(selectVideoSubstate, (video: VideoReducer): AxiosError => video.error);
export const selectResponseSent = createSelector(selectVideoSubstate, (video: VideoReducer): boolean => video.responseSent);
