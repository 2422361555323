import PageHeader from 'ant-design-pro/lib/PageHeader';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import CenteredSpin from 'components/CenteredSpin';
import * as React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { isSubmitting, submit } from 'redux-form';
import { createContact } from 'store/modules/contacts/actions';
import { selectContactError, selectContactLoading, selectContactsError } from 'store/modules/contacts/selectors';
import { ITranslator, Redux, SelectorType } from 'typings';
import ContactForm from 'view/containers/Contacts/ContactForm';
import { Error } from './styled';

interface Props extends ITranslator {
    loading?: boolean;
    error?: SelectorType<typeof selectContactError>;
    primary: boolean;
    listError?: SelectorType<typeof selectContactsError>;
    submitting?: SelectorType<typeof isSubmitting>;
    submit?: typeof submit;
}

const initialValues = {
    primary: false,
};

class AddContact extends React.Component<Props> {
    render() {
        const { loading, error, t, listError, submit, submitting } = this.props;
        return (
            <>
                <PageHeader
                    itemRender={null}
                    title={t('routes.AddContact.add')}
                    action={
                        <Button type="primary" onClick={() => submit('contactForm')} loading={submitting}>
                            {t('containers.ContactForm.submit')}
                        </Button>
                    }
                />
                <Card bordered={false}>
                    {(error || listError) && <Error>{t('routes.EditContact.error')}</Error>}
                    {loading && <CenteredSpin />}
                    {!loading && !error && !listError && <ContactForm initialValues={initialValues} onSubmit={createContact} />}
                </Card>
            </>
        );
    }
}

export default compose<any>(
    connect(
        (state: Redux) => {
            return {
                loading: selectContactLoading(state),
                error: selectContactError(state),
                listError: selectContactsError(state),
            };
        },
        { submit },
    ),
    translate(),
    withRouter,
)(AddContact);
