import Col from 'antd/es/col';
import Divider from 'antd/es/divider';
import Row from 'antd/es/row';
import React from 'react';
import { TicketStateName } from 'store/modules/appInfo/constants';
import { Ticket } from 'store/modules/tickets/@types';
import { ITranslator } from 'typings';
import AddEvent from 'view/containers/AddEvent';
import EventTimeline from 'view/containers/EventTimeline';

import TicketInfo from './TicketInfo';

interface Props extends ITranslator {
    ticket?: Ticket;
    loading?: boolean;
}

export default class TicketDetail extends React.Component<Props> {
    render() {
        const { ticket, loading } = this.props;
        return (
            <>
                <Row justify="space-between" type="flex">
                    {ticket.status === TicketStateName.CLOSED ? null : <AddEvent />}
                    <TicketInfo />
                </Row>
                <Divider />
                <Row>
                    <Col span={14}>
                        <EventTimeline events={ticket.events} loading={loading} />
                    </Col>
                </Row>
            </>
        );
    }
}
