import Button from 'antd/es/button';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
    float: right;
`;

export const FormSectionLabel = styled.div`
    font-size: 20px;
    margin-top: 16px;
    margin-bottom: 8px;
    padding-bottom: 8px;
`;

export const AddButton = styled(Button)`
    display: block;
    margin-bottom: 16px;
`;
