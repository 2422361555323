import { AxiosError } from 'axios';
import find from 'lodash-es/find';
import { createSelector } from 'reselect';
import { Branch } from 'store/modules/appInfo/@types';
import { selectBranches } from 'store/modules/appInfo/selectors';
import { Ticket, TicketData, TicketsReducer } from 'store/modules/tickets/@types';
import { selectTicketsSubState } from 'store/modules/tickets/selectors';
import { _get } from 'utils/get';

import { DetailReducer } from './@types.d';

const selectDetail = createSelector(selectTicketsSubState, (tickets: TicketsReducer): DetailReducer => tickets.detail);

export const selectTicket = createSelector(selectDetail, (detail: DetailReducer): Ticket => detail.data);
export const selectCaseId = createSelector(selectTicket, (ticket: Ticket): string => ticket.caseId);
export const selectTicketData = createSelector(selectTicket, (ticket: Ticket): TicketData => ticket.data);
export const selectBranch = createSelector(
    [selectBranches, selectTicket],
    (branches: Branch[], ticket: Ticket): Branch => find(branches, (branch: Branch) => branch.id === _get(ticket, 'meta', 'branch')),
);

export const selectLoading = createSelector(selectDetail, (detail: DetailReducer): boolean => detail.loading);
export const selectError = createSelector(selectDetail, (detail: DetailReducer): AxiosError => detail.error);
