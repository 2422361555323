import Table from 'antd/es/table';
import Icon from 'antd/es/icon';
import styled from 'styled-components';
import theme, { ThemeProps } from 'stylesheets/GlobalTheme';
import Input from 'antd/es/input';

export const StyledTable = styled(Table)`
    .ant-table-tbody > tr:hover > td {
        background: ${(props: ThemeProps) => props.theme.colors.white};
    }
    .ant-table-row:hover {
        cursor: pointer;
    }
    .anticon-close {
        vertical-align: 0;
        padding: 0;
    }
`;

export const StyledContainer = styled.div`
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
    background-color: ${(props: ThemeProps) => props.theme.colors.white};
`;

export const CapitalizedSpan = styled.span`
    text-transform: capitalize;
`;

export const SearchInput = styled(Input)`
    width: 130px;
`;

export const FilterIcon = styled(Icon)`
    color: ${(props: any) => (props.filtered === 'true' ? `${theme.colors.antdFilter} !important` : `${theme.colors.antdGrey} !important`)};
`;
