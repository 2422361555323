import { t } from 'i18next';
import { push } from 'react-router-redux';
import { SagaIterator } from 'redux-saga';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { Routes } from 'store/modules/ui/constants';
import { openNotification } from 'utils/notifications';
import { getToken } from 'utils/withToken';

import { BranchOpeningHoursDay } from './@types';
import * as actions from './actions';
import { openingHoursTemplate } from './constants';
import * as ApiService from './requests';
import * as utils from './utils/updateBranches';

function adjustOpeningHours(openingHours: BranchOpeningHoursDay[]) {
    return openingHoursTemplate.map((day: BranchOpeningHoursDay, index: number) =>
        openingHours[index].from && openingHours[index].to ? openingHours[index] : day,
    );
}

function* getBranches() {
    yield takeLatest(actions.Types.GET_BRANCHES, function* handle() {
        try {
            const token = yield getToken();
            const result = yield call(ApiService.getBranches, token);
            yield put(actions.getBranchesSuccess(result));
        } catch (err) {
            yield put(actions.getBranchesFailure(err));
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
        }
    });
}

function* createBranch() {
    yield takeLatest(actions.Types.CREATE_BRANCH, function* handle(action: ReturnType<typeof actions.createBranch>) {
        const { branch } = action.payload;
        const adjustedOpeningHours = adjustOpeningHours(branch.openingHours);
        const adjustedBranch = { ...branch, openingHours: adjustedOpeningHours };

        try {
            const token = yield getToken();
            const branches = yield call(ApiService.getBranches, token);
            const result = yield call(ApiService.updateBranches, utils.createBranch(branches, adjustedBranch), token);
            yield put(actions.updateBranchesSuccess(result));
            yield put(push(Routes.BRANCHES.path));
            yield call(openNotification, 'success', t('apiRequest.success.title'), t('apiRequest.success.branchCreated'));
        } catch (err) {
            yield put(actions.updateBranchesFailure(err));
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
        }
    });
}

function* updateBranch() {
    yield takeLatest(actions.Types.UPDATE_BRANCH, function* handle(action: ReturnType<typeof actions.updateBranch>) {
        const { branch } = action.payload;
        const adjustedOpeningHours = adjustOpeningHours(branch.openingHours);
        const adjustedBranch = { ...branch, openingHours: adjustedOpeningHours };

        try {
            const token = yield getToken();
            const branches = yield call(ApiService.getBranches, token);
            const result = yield call(ApiService.updateBranches, utils.updateBranch(branches, adjustedBranch), token);
            yield put(actions.updateBranchesSuccess(result));
            yield put(push(Routes.BRANCHES.path));
            yield call(openNotification, 'success', t('apiRequest.success.title'), t('apiRequest.success.branchUpdated'));
        } catch (err) {
            yield put(actions.updateBranchesFailure(err));
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
        }
    });
}

export default function* branchesSagas(): SagaIterator {
    yield fork(getBranches);
    yield fork(createBranch);
    yield fork(updateBranch);
}
