import DescriptionList from 'ant-design-pro/lib/DescriptionList';
import PageHeader from 'ant-design-pro/lib/PageHeader';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import Modal from 'antd/es/modal';
import Tag from 'antd/es/tag';
import CenteredSpin from 'components/CenteredSpin';
import moment from 'moment';
import React from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import { connect } from 'react-redux';
import { match } from 'react-router';
import { compose } from 'redux';
import { Branch } from 'store/modules/appInfo/@types';
import { TicketStateName } from 'store/modules/appInfo/constants';
import { closeTicket, getTicket } from 'store/modules/tickets/detail/actions';
import { selectBranch, selectError, selectLoading, selectTicket } from 'store/modules/tickets/detail/selectors';
import { statusTranslation, typeTranslation } from 'store/modules/tickets/list/utils/createRows';
import { ITranslator, Redux, SelectorType } from 'typings';
import TicketDetail from 'view/containers/TicketDetail';

const confirm = Modal.confirm;
const { Description } = DescriptionList;

interface Props extends ITranslator {
    match?: match<{ caseId: string }>;
    getTicket?: typeof getTicket;
    branch?: Branch;
    closeTicket?: typeof closeTicket;
    error?: SelectorType<typeof selectError>;
    loading?: SelectorType<typeof selectLoading>;
    ticket?: SelectorType<typeof selectTicket>;
}

const statusColor = {
    [TicketStateName.OPEN]: 'green',
    [TicketStateName.CLOSED]: 'geekblue',
    [TicketStateName.PENDING]: 'orange',
};

const description = (ticket: SelectorType<typeof selectTicket>, branch: Branch, t: TranslationFunction) => (
    <DescriptionList title="" size="small" col={8}>
        <Description term={t('routes.Tickets.columns.createdAt')}>{moment(ticket.createdAt).format('DD. MM. YYYY')}</Description>
        <Description term={t('routes.Tickets.columns.status')}>
            <Tag color={statusColor[ticket.status]}>{statusTranslation[ticket.status]}</Tag>
        </Description>
        <Description term={t('routes.Tickets.columns.type')}>{typeTranslation[ticket.type]}</Description>
        <Description term={t('routes.Tickets.columns.branch')}>{branch.name}</Description>
    </DescriptionList>
);

class Ticket extends React.Component<Props> {
    componentDidMount() {
        const { getTicket, match } = this.props;
        getTicket(match.params.caseId);
    }

    handleTicketClose = () => {
        const { closeTicket, match, t } = this.props;
        confirm({
            title: t('routes.Ticket.closeWarning'),
            okText: t('routes.Ticket.okText'),
            okType: 'danger',
            cancelText: t('routes.Ticket.cancelText'),
            onOk() {
                closeTicket(match.params.caseId);
            },
        });
    };

    render() {
        const { t, loading, ticket, branch } = this.props;

        if (!ticket || !branch || loading) {
            return <CenteredSpin />;
        }

        return (
            <>
                <PageHeader
                    itemRender={null}
                    title={`${t('routes.Ticket.title')} - ${ticket.caseNumber}`}
                    action={
                        ticket.status === TicketStateName.CLOSED ? null : (
                            <Button type="danger" loading={loading} onClick={this.handleTicketClose}>
                                {t('routes.Ticket.close')}
                            </Button>
                        )
                    }
                    content={description(ticket, branch, t)}
                />
                <Card bordered={false}>
                    <TicketDetail ticket={ticket} loading={loading} />
                </Card>
            </>
        );
    }
}

export default compose<any>(
    connect(
        (state: Redux) => ({
            ticket: selectTicket(state),
            loading: selectLoading(state),
            error: selectError(state),
            branch: selectBranch(state),
        }),
        { closeTicket, getTicket },
    ),
    translate(),
)(Ticket);
