import createReducer from 'utils/createReducer';

import { VideoReducer } from './@types.d';
import { Types } from './actions';
import * as actions from './actions';

export const initialState: VideoReducer = {
    loading: false,
    data: null,
    error: null,
    responseSent: false,
};

export default createReducer(initialState, {
    [Types.GET_DATA]: (state: VideoReducer): VideoReducer => {
        return {
            ...state,
            loading: true,
        };
    },
    [Types.GET_DATA_SUCCESS]: (state: VideoReducer, action: ReturnType<typeof actions.getDataSuccess>): VideoReducer => {
        const { data } = action.payload;
        return {
            ...state,
            data: {
                ...data,
            },
            loading: false,
        };
    },
    [Types.GET_DATA_FAILURE]: (state: VideoReducer, action: ReturnType<typeof actions.getDataFailure>): VideoReducer => {
        const { error } = action.payload;
        return {
            ...state,
            error,
            loading: false,
        };
    },
    [Types.MAKE_RESPONSE]: (state: VideoReducer): VideoReducer => {
        return {
            ...state,
            loading: true,
        };
    },
    [Types.MAKE_RESPONSE_SUCCESS]: (state: VideoReducer, action: ReturnType<typeof actions.makeResponseSuccess>): VideoReducer => {
        const { status, choices } = action.payload.data;
        return {
            ...state,
            loading: false,
            responseSent: true,
            data: {
                ...state.data,
                status,
                choices,
            },
        };
    },
    [Types.MAKE_RESPONSE_FAILURE]: (state: VideoReducer, action: ReturnType<typeof actions.makeResponseFailure>): VideoReducer => {
        const { error } = action.payload;
        return {
            ...state,
            error,
            loading: false,
        };
    },
});
