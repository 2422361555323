import formActionSaga from 'redux-form-saga';

import { sagas as appInfo } from './modules/appInfo';
import { sagas as auth } from './modules/auth';
import { sagas as branches } from './modules/branches';
import { sagas as carTypes } from './modules/carTypes';
import { sagas as contacts } from './modules/contacts';
import { sagas as customers } from './modules/customers';
import { sagas as manuals } from './modules/manuals';
import { sagas as s3Files } from './modules/s3files';
import { sagas as symbols } from './modules/symbols';
import { sagas as ticketDetail } from './modules/tickets/detail';
import { sagas as ticketList } from './modules/tickets/list';
import { sagas as users } from './modules/users';
import { sagas as video } from './modules/video';
import { sagas as scanCode } from './modules/scanCode';

export default [
    auth,
    users,
    formActionSaga,
    branches,
    manuals,
    s3Files,
    ticketList,
    ticketDetail,
    symbols,
    contacts,
    appInfo,
    carTypes,
    customers,
    video,
    scanCode,
];
