import { AxiosError } from 'axios';
import { createFormAction } from 'redux-form-saga';

import { Session, User } from './@types';

export enum Types {
    SIGN_IN = 'auth/SIGN_IN',
    SIGN_IN_SUCCESS = 'auth/SIGN_IN_SUCCESS',
    SIGN_IN_FAILURE = 'auth/SIGN_IN_FAILURE',
    SIGN_OUT = 'auth/SIGN_OUT',
    SIGN_OUT_SUCCESS = 'auth/SIGN_OUT_SUCCESS',
    CHANGE_PASSWORD = 'auth/CHANGE_PASSWORD',
    CHANGE_PASSWORD_SUCCESS = 'auth/CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE = 'auth/CHANGE_PASSWORD_FAILURE',
    RESET_PASSWORD = 'auth/RESET_PASSWORD',
    RESET_PASSWORD_SUCCESS = 'auth/RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE = 'auth/RESET_PASSWORD_FAILURE',
    RESET_PASSWORD_CONFIRM = 'auth/RESET_PASSWORD_CONFIRM',
    RESET_PASSWORD_CONFIRM_SUCCESS = 'auth/RESET_PASSWORD_CONFIRM_SUCCESS',
    RESET_PASSWORD_CONFIRM_FAILURE = 'auth/RESET_PASSWORD_CONFIRM_FAILURE',
}

export const signIn = createFormAction(Types.SIGN_IN);
export const changePassword = createFormAction(Types.CHANGE_PASSWORD);
export const resetPassword = createFormAction(Types.RESET_PASSWORD);
export const resetPasswordConfirm = createFormAction(Types.RESET_PASSWORD_CONFIRM);

export function signInSuccess(user: User, session?: Session) {
    return {
        type: Types.SIGN_IN_SUCCESS,
        payload: {
            user,
            session,
        },
    };
}

export function signInFailure(error?: AxiosError) {
    return {
        type: Types.SIGN_IN_FAILURE,
        payload: {
            error,
        },
    };
}

export function signOut() {
    return {
        type: Types.SIGN_OUT,
    };
}

export function signOutSuccess() {
    return {
        type: Types.SIGN_OUT_SUCCESS,
    };
}

export function changePasswordSuccess() {
    return {
        type: Types.CHANGE_PASSWORD_SUCCESS,
    };
}

export function changePasswordFailure(error: AxiosError) {
    return {
        type: Types.CHANGE_PASSWORD_FAILURE,
        payload: { error },
    };
}

export function resetPasswordSuccess(signedOutEmail: string) {
    return {
        type: Types.RESET_PASSWORD_SUCCESS,
        payload: { signedOutEmail },
    };
}

export function resetPasswordFailure(error: AxiosError) {
    return {
        type: Types.RESET_PASSWORD_FAILURE,
        payload: { error },
    };
}

export function resetPasswordConfirmSuccess() {
    return {
        type: Types.RESET_PASSWORD_CONFIRM_SUCCESS,
    };
}

export function resetPasswordConfirmFailure(error: AxiosError) {
    return {
        type: Types.RESET_PASSWORD_CONFIRM_FAILURE,
        payload: { error },
    };
}
