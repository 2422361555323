export enum NotificationType {
    SERVICE_REMINDER_PROBE = 'SERVICE_REMINDER_PROBE',
    SERVICE_REMINDER_CONFIRM = 'SERVICE_REMINDER_CONFIRM',
    SERVICE_APPROVAL_REQUEST = 'SERVICE_APPROVAL_REQUEST',
    SERVICE_STK_REMINDER_PROBE = 'SERVICE_STK_REMINDER_PROBE',
    CARD_EXPIRE_NOTICE = 'CARD_EXPIRE_NOTICE',
    WARRANTY_EXPIRE_NOTICE = 'WARRANTY_EXPIRE_NOTICE',
    SERVICE_APPROVAL_CHOICES_REQUEST = 'SERVICE_APPROVAL_CHOICES_REQUEST',
}

export enum EventType {
    STATE_CHANGE = 'STATE_CHANGE',
    USER_RESPONSE = 'USER_RESPONSE',
    CONTACT = 'CONTACT',
    NOTIFICATION = 'NOTIFICATION',
}
