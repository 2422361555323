import * as React from 'react';
import { List } from 'store/modules/scanCode/@types';
import { Alert } from 'antd';

export interface ScanCodeAlertProps {
    list: List[];
}

const ScanCodeAlert = ({ list }: ScanCodeAlertProps) => {
    return (
        <React.Fragment>
            {list.map(({ car, user }: List, index: number) => {
                const isLast = list.length - 1 === index;

                return (
                    <Alert
                        key={index}
                        message={car.vin}
                        description={user?.name}
                        type="success"
                        closable
                        style={{ marginBottom: isLast ? 0 : '12px' }}
                    />
                );
            })}
        </React.Fragment>
    );
};

export default ScanCodeAlert;
