import { AxiosError } from 'axios';

import { VideoData, Choice } from './@types';

export enum Types {
    GET_DATA = 'video/GET_DATA',
    GET_DATA_SUCCESS = 'video/GET_DATA_SUCCESS',
    GET_DATA_FAILURE = 'video/GET_DATA_FAILURE',
    MAKE_RESPONSE = 'video/MAKE_RESPONSE',
    MAKE_RESPONSE_SUCCESS = 'video/MAKE_RESPONSE_SUCCESS',
    MAKE_RESPONSE_FAILURE = 'video/MAKE_RESPONSE_FAILURE',
}

export function getData(caseId: string) {
    return {
        type: Types.GET_DATA,
        payload: { caseId },
    };
}

export function getDataSuccess(data: VideoData) {
    return {
        type: Types.GET_DATA_SUCCESS,
        payload: { data },
    };
}

export function getDataFailure(error: AxiosError) {
    return {
        type: Types.GET_DATA_FAILURE,
        payload: { error },
    };
}

export function makeResponse(caseId: string, response: Choice[]) {
    return {
        type: Types.MAKE_RESPONSE,
        payload: { caseId, response },
    };
}

export function makeResponseSuccess(data: VideoData) {
    return {
        type: Types.MAKE_RESPONSE_SUCCESS,
        payload: { data },
    };
}

export function makeResponseFailure(error: AxiosError) {
    return {
        type: Types.MAKE_RESPONSE_FAILURE,
        payload: { error },
    };
}
