export enum TicketTypeName {
    SERVICE_REMINDER = 'SERVICE_REMINDER',
    SERVICE_APPROVAL = 'SERVICE_APPROVAL',
    SERVICE_REQUEST = 'SERVICE_REQUEST',
    CARD_EXPIRATION = 'CARD_EXPIRATION',
    WARRANTY_EXPIRATION = 'WARRANTY_EXPIRATION',
    SERVICE_APPROVAL_CHOICE = 'SERVICE_APPROVAL_CHOICE',
    SIGN_UP_HELP = 'SIGN_UP_HELP',
}

export enum TicketStateName {
    OPEN = 'OPEN',
    PENDING = 'PENDING',
    CLOSED = 'CLOSED',
}

export namespace Events {
    export enum StateChanged {
        CLOSED = 'TICKET_CLOSED',
    }

    export enum Reception {
        CONTACT_FAILED = 'CONTACT_FAILED',
        CONTACT_SUCCEEDED = 'CONTACT_SUCCEEDED',
    }

    export enum Notification {
        SENT = 'NOTIFICATION_SENT',
        RECEIVED = 'NOTIFICATION_RECEIVED',
        SEEN = 'NOTIFICATION_SEEN',
        RESPONSE = 'USER_RESPONSE',
        SCHEDULED = 'NOTIFICATION_SCHEDULED',
        CANCELLED = 'NOTIFICATION_CANCELLED',
    }
}

export enum TranslationLanguage {
    cs = 'cs',
    en = 'en',
}

export type TicketEventName = Events.Reception | Events.Notification | Events.StateChanged;

export type Translation = Record<TranslationLanguage, string>;
