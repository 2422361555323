import get from 'lodash-es/get';
export interface TypedExtractor {
    <T, K1 extends keyof T>(object: T, key1: K1): T[K1];
    <T, K1 extends keyof T, K2 extends keyof T[K1]>(object: T, key1: K1, key2: K2): T[K1][K2];
    <T, K1 extends keyof T, K2 extends keyof T[K1], K3 extends keyof T[K1][K2]>(object: T, key1: K1, key2: K2, key3: K3): T[K1][K2][K3];
    <T, K1 extends keyof T, K2 extends keyof T[K1], K3 extends keyof T[K1][K2], K4 extends keyof T[K1][K2][K3]>(
        object: T,
        key1: K1,
        key2: K2,
        key3: K3,
        key4: K4,
    ): T[K1][K2][K3][K4];
    <
        T,
        K1 extends keyof T,
        K2 extends keyof T[K1],
        K3 extends keyof T[K1][K2],
        K4 extends keyof T[K1][K2][K3],
        K5 extends keyof T[K1][K2][K3][K4]
    >(
        object: T,
        key1: K1,
        key2: K2,
        key3: K3,
        key4: K4,
        key5: K5,
    ): T[K1][K2][K3][K4][K5];
    <
        T,
        K1 extends keyof T,
        K2 extends keyof T[K1],
        K3 extends keyof T[K1][K2],
        K4 extends keyof T[K1][K2][K3],
        K5 extends keyof T[K1][K2][K3][K4],
        K6 extends keyof T[K1][K2][K3][K4][K5]
    >(
        object: T,
        key1: K1,
        key2: K2,
        key3: K3,
        key4: K4,
        key5: K5,
        key6: K6,
    ): T[K1][K2][K3][K4][K5][K6];
    <
        T,
        K1 extends keyof T,
        K2 extends keyof T[K1],
        K3 extends keyof T[K1][K2],
        K4 extends keyof T[K1][K2][K3],
        K5 extends keyof T[K1][K2][K3][K4],
        K6 extends keyof T[K1][K2][K3][K4][K5],
        K7 extends keyof T[K1][K2][K3][K4][K5][K6]
    >(
        object: T,
        key1: K1,
        key2: K2,
        key3: K3,
        key4: K4,
        key5: K5,
        key6: K6,
        key7: K7,
    ): T[K1][K2][K3][K4][K5][K6][K7];
    <
        T,
        K1 extends keyof T,
        K2 extends keyof T[K1],
        K3 extends keyof T[K1][K2],
        K4 extends keyof T[K1][K2][K3],
        K5 extends keyof T[K1][K2][K3][K4],
        K6 extends keyof T[K1][K2][K3][K4][K5],
        K7 extends keyof T[K1][K2][K3][K4][K5][K6],
        K8 extends keyof T[K1][K2][K3][K4][K5][K6][K7]
    >(
        object: T,
        key1: K1,
        key2: K2,
        key3: K3,
        key4: K4,
        key5: K5,
        key6: K6,
        key7: K7,
        key8: K8,
    ): T[K1][K2][K3][K4][K5][K6][K7][K8];
    <
        T,
        K1 extends keyof T,
        K2 extends keyof T[K1],
        K3 extends keyof T[K1][K2],
        K4 extends keyof T[K1][K2][K3],
        K5 extends keyof T[K1][K2][K3][K4],
        K6 extends keyof T[K1][K2][K3][K4][K5],
        K7 extends keyof T[K1][K2][K3][K4][K5][K6],
        K8 extends keyof T[K1][K2][K3][K4][K5][K6][K7],
        K9 extends keyof T[K1][K2][K3][K4][K5][K6][K7][K8]
    >(
        object: T,
        key1: K1,
        key2: K2,
        key3: K3,
        key4: K4,
        key5: K5,
        key6: K6,
        key7: K7,
        key8: K8,
        key9: K9,
    ): T[K1][K2][K3][K4][K5][K6][K7][K8][K9];
    <
        T,
        K1 extends keyof T,
        K2 extends keyof T[K1],
        K3 extends keyof T[K1][K2],
        K4 extends keyof T[K1][K2][K3],
        K5 extends keyof T[K1][K2][K3][K4],
        K6 extends keyof T[K1][K2][K3][K4][K5],
        K7 extends keyof T[K1][K2][K3][K4][K5][K6],
        K8 extends keyof T[K1][K2][K3][K4][K5][K6][K7],
        K9 extends keyof T[K1][K2][K3][K4][K5][K6][K7][K8],
        K10 extends keyof T[K1][K2][K3][K4][K5][K6][K7][K8][K9]
    >(
        object: T,
        key1: K1,
        key2: K2,
        key3: K3,
        key4: K4,
        key5: K5,
        key6: K6,
        key7: K7,
        key8: K8,
        key9: K9,
        key10: K10,
    ): T[K1][K2][K3][K4][K5][K6][K7][K8][K9][K10];
}

export const _get: TypedExtractor = (object: any, ...keys: (string | number)[]): string | number | object | undefined => {
    return get(object, keys);
};
