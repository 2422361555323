import DescriptionList from 'ant-design-pro/lib/DescriptionList';
import Col from 'antd/es/col';
import Divider from 'antd/es/divider';
import React from 'react';
import { translate, TranslationFunction } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Ticket, TicketCar } from 'store/modules/tickets/@types';
import { selectTicket } from 'store/modules/tickets/detail/selectors';
import { ITranslator, Redux } from 'typings';
import { InfoIcon, StyledDescription } from './styled';
import AWS from 'aws-sdk';
import Auth from 'aws-amplify/lib/Auth';
import { TicketTypeName } from 'store/modules/appInfo/constants';

declare const Conf;

interface Props extends ITranslator {
    ticket?: Ticket;
}

const { Description } = DescriptionList;

const isSignUpHelp = (ticket: Ticket) => ticket.type === TicketTypeName.SIGN_UP_HELP;

const carInformation = (ticket: TicketCar, t: TranslationFunction) => (
    <DescriptionList
        title={
            <>
                <InfoIcon type="car" theme="twoTone" />
                <span> {t('routes.Ticket.carInformation')}</span>
            </>
        }
        size="small"
        col={2}
    >
        <Description term={t('routes.Ticket.car.model')}>{ticket?.model}</Description>
        <Description term={t('routes.Ticket.car.year')}>{ticket?.modelYear}</Description>
        <Description term={t('routes.Ticket.car.spz')}>{ticket?.spz}</Description>
        <Description term={t('routes.Ticket.car.vin')}>{ticket?.vin}</Description>
        <Description term={t('routes.Ticket.car.technician')}>{ticket?.carTechnician || '(neuveden)'}</Description>
    </DescriptionList>
);

const metaInformation = (ticket: Ticket, t: TranslationFunction) => (
    <DescriptionList
        title={
            <>
                <InfoIcon type="info-circle" theme="twoTone" />
                <span> {t('routes.Ticket.metaInformation')}</span>
            </>
        }
        size="small"
        col={2}
    >
        {ticket?.meta?.note && <Description term={t('routes.Ticket.meta.note')}>{ticket?.meta?.note}</Description>}
        <StyledDescription term={t('routes.Ticket.meta.createdBy')}>{ticket?.meta?.createdBy?.email}</StyledDescription>
        {ticket?.closedBy && (
            <StyledDescription term={t('routes.Ticket.meta.closedBy')}>{ticket?.closedBy?.cognito?.email}</StyledDescription>
        )}
    </DescriptionList>
);

const userInformation = (ticket: Ticket, t: TranslationFunction) => (
    <DescriptionList
        title={
            <>
                <InfoIcon type="idcard" theme="twoTone" />
                <span> {t('routes.Ticket.userInformation')}</span>
            </>
        }
        size="small"
        col={2}
    >
        <Description term={t('routes.Ticket.user.email')}>{ticket?.data?.user?.email}</Description>
        {isSignUpHelp(ticket) ? (
            <Description term={t('routes.Ticket.user.phone')}>{ticket?.customer?.phone}</Description>
        ) : (
            <Description term={t('routes.Ticket.user.phone')}>
                {ticket?.customer?.phoneNumbers?.map((number: string, index: number) => {
                    if (ticket?.customer?.phoneNumbers.length === 1) {
                        return `${number}`;
                    }

                    if (ticket?.customer?.phoneNumbers.length - 1 === index) {
                        return `${number}`;
                    }

                    return `${number}, `;
                })}
            </Description>
        )}
    </DescriptionList>
);

const videoAttachment = (ticket: Ticket, videoUrl: string, t: TranslationFunction) => (
    <DescriptionList
        title={
            <>
                <InfoIcon type="video-camera" theme="twoTone" />
                <span> {t('routes.Ticket.videoAttachment.title')}</span>
            </>
        }
        size="small"
        col={2}
    >
        <Description term={t('routes.Ticket.videoAttachment.description')}>{ticket?.data?.videoAttachment?.description || ''}</Description>
        <video controls width="400" style={{ margin: 15 }}>
            <source src={videoUrl} type="video/mp4" />
            Sorry, your browser doesn't support embedded videos.
        </video>
    </DescriptionList>
);

class TicketInfo extends React.Component<Props, { videoAttachmentUrl: string }> {
    constructor(props) {
        super(props);

        this.state = {
            videoAttachmentUrl: null,
        };
    }

    componentDidMount(): void {
        if (!this.props.ticket?.data?.videoAttachment) {
            return;
        }

        const { s3Key } = this.props.ticket.data.videoAttachment;

        Auth.currentCredentials().then((credentials: any) => {
            const s3 = new AWS.S3({ credentials: Auth.essentialCredentials(credentials) });

            s3.getObject({
                Bucket: Conf.SERVICE_VIDEOS_BUCKET,
                Key: s3Key,
            })
                .promise()
                .then((r: any) => {
                    if (!r.Body) {
                        return;
                    }

                    const blob = new Blob([r.Body]);
                    this.setState({
                        videoAttachmentUrl: URL.createObjectURL(blob),
                    });
                });
        });
    }

    render() {
        const { ticket, t } = this.props;

        return (
            <Col span={14}>
                {ticket?.data?.user && userInformation(ticket, t)}
                <Divider />
                {ticket?.data?.car && carInformation(ticket?.data?.car, t)}
                <Divider />
                {ticket?.meta && metaInformation(ticket, t)}
                <Divider />
                {ticket?.data?.videoAttachment &&
                    this.state.videoAttachmentUrl &&
                    videoAttachment(ticket, this.state.videoAttachmentUrl, t)}
            </Col>
        );
    }
}

export default compose<any>(
    connect((state: Redux) => ({
        ticket: selectTicket(state),
    })),
    translate(),
)(TicketInfo);
