import { Icon, Modal } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { translate } from 'react-i18next';
import ReactPlayer from 'react-player';
import { Events } from 'store/modules/appInfo/constants';
import { ITranslator } from 'typings';
import { formatPrice } from 'utils/helpers';
import FromS3 from 'view/components/FromS3';
import {
    ModalWrapper,
    StyledButton,
    StyledCard,
    StyledChoice,
    StyledDate,
    StyledHeader,
    StyledLabel,
    StyledPrice,
    StyledValue,
} from './styled';
import { GenericData, Props } from './types';
import createEventBody from './utils/createEventBody';

const Title = (title: string, timestamp: string) => (
    <>
        <StyledHeader>{title}</StyledHeader>
        <StyledDate>{moment(timestamp).format('DD. MM. YYYY - HH:mm')}</StyledDate>
    </>
);

interface PropsWithTranslation extends ITranslator {
    data: any;
}

const NotificationSentInfo = ({ data, t }: PropsWithTranslation) => {
    const [isVisible, setVisible] = useState(false);
    const { name, phone, email, choices, videoFile } = data.data;

    return (
        <>
            {(name || phone || email) && (
                <div>
                    <StyledLabel>{t('containers.EventTimeline.servisTechInfo')}:</StyledLabel>
                    <StyledValue>
                        <div>{name}</div>
                        <div>{phone}</div>
                        <div>{email}</div>
                    </StyledValue>
                </div>
            )}
            {choices && (
                <div>
                    <StyledLabel>{t('containers.EventTimeline.choices')}:</StyledLabel>
                    <StyledValue>
                        {JSON.parse(choices).map((item: any, index: number) => (
                            <div key={index}>
                                <span>{`${item.name} -`}</span>
                                <StyledPrice>{formatPrice(item.price)}</StyledPrice>
                            </div>
                        ))}
                    </StyledValue>
                </div>
            )}
            {videoFile && (
                <>
                    <StyledButton size="small" onClick={() => setVisible(true)}>
                        {t('containers.EventTimeline.showVideo')}
                    </StyledButton>
                    <Modal width={800} onCancel={() => setVisible(false)} footer={null} visible={isVisible}>
                        <ModalWrapper>
                            <FromS3 name={videoFile} isVideo>
                                {(url: string) => {
                                    return <ReactPlayer url={url} width="100%" height="100%" controls />;
                                }}
                            </FromS3>
                        </ModalWrapper>
                    </Modal>
                </>
            )}
        </>
    );
};

const CheckedItem = ({ checked }: { checked: boolean }) =>
    checked ? <Icon type="check" style={{ color: '#1ebf41' }} /> : <Icon type="close" style={{ color: '#c92626' }} />;

const UserResponseInfo = ({ data, t }: PropsWithTranslation) => {
    if (!data || !data.choices) {
        return null;
    }
    return (
        <div>
            <StyledLabel>{t('containers.EventTimeline.choices')}:</StyledLabel>
            <StyledValue>
                {data.choices.map((choice: any, index: number) => (
                    <StyledChoice key={index}>
                        <span>{`${choice.name} - `}</span>
                        <StyledPrice>{formatPrice(choice.price)}</StyledPrice>
                        <CheckedItem checked={choice.approved} />
                    </StyledChoice>
                ))}
            </StyledValue>
        </div>
    );
};

const Event = ({ timestamp, data, title, eventName, t }: Props<GenericData>) => {
    const eventBody = createEventBody(data);
    const hasAdditionalInfo = eventName === Events.Notification.SENT || (data && !!data.choices);

    return (
        <StyledCard bodyStyle={{ display: eventBody || hasAdditionalInfo ? 'block' : 'none' }} type="inner" title={Title(title, timestamp)}>
            {eventBody && <p>{eventBody.label ? `${eventBody.label}: ${eventBody.value}` : eventBody.value}</p>}
            {eventName === Events.Notification.SENT && <NotificationSentInfo t={t} data={data} />}
            {eventName === Events.Notification.RESPONSE && <UserResponseInfo t={t} data={data} />}
        </StyledCard>
    );
};

export default translate()(Event);
