import { t } from 'i18next';
import { push } from 'react-router-redux';
import { SagaIterator } from 'redux-saga';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { Routes } from 'store/modules/ui/constants';
import { openNotification } from 'utils/notifications';
import { uploadAppData } from 'utils/s3';
import { getToken } from 'utils/withToken';

import * as actions from './actions';
import { allowedImageTypes, FILE_SIZE } from './constants';
import ApiService from './requests';

function* getContacts() {
    yield takeLatest(actions.Types.GET_CONTACTS, function* handle() {
        try {
            const token = yield getToken();
            const contacts = yield call(ApiService.getContacts, token);
            yield put(actions.getContactsSuccess(contacts));
        } catch (err) {
            yield put(actions.getContactsFailure(err));
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
        }
    });
}

function* getContact() {
    yield takeLatest(actions.Types.GET_CONTACT, function* handle(action: ReturnType<typeof actions.getContact>) {
        try {
            const { contactId } = action.payload;
            const token = yield getToken();
            const contact = yield call(ApiService.getContact, contactId, token);
            yield put(actions.getContactSuccess(contact));
        } catch (err) {
            yield put(actions.getContactFailure(err));
        }
    });
}

function* createContact() {
    yield takeLatest(actions.createContact.REQUEST, function* handle(action: ReturnType<typeof actions.createContact>) {
        try {
            const { image, department_position, ...rest } = action.payload;
            const token = yield getToken();

            if (image) {
                if (image.file.size > FILE_SIZE || !allowedImageTypes.includes(image.file.type)) {
                    yield call(openNotification, 'error', t('validation.title'), t('validation.contactImageInvalid'));
                    return yield put(actions.createContact.failure(t('validation.contactImageInvalid')));
                }
                image.name = (yield uploadAppData(image)).key;
            }

            const department = department_position.split('-')[0];
            const position = department_position.split('-')[1];
            const contact = {
                ...rest,
                image: image ? image.name : '',
                department,
                position,
            };
            yield call(ApiService.createContact, contact, token);
            yield put(actions.createContactSuccess());
            yield put(push(Routes.CONTACTS.path));
            yield call(openNotification, 'success', t('apiRequest.success.title'), t('apiRequest.success.createContact'));
        } catch (err) {
            yield put(actions.createContactFailure(err));
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.createContact'));
        }
    });
}

function* updateContact() {
    yield takeLatest(actions.updateContact.REQUEST, function* handle(action: ReturnType<typeof actions.updateContact>) {
        try {
            const { _id, id, image, department_position, ...rest } = action.payload;
            const token = yield getToken();

            if (image) {
                if (image.file) {
                    if (image.file.size > FILE_SIZE || !allowedImageTypes.includes(image.file.type)) {
                        yield call(openNotification, 'error', t('validation.title'), t('validation.contactImageInvalid'));
                        return yield put(actions.updateContact.failure(t('validation.contactImageInvalid')));
                    }

                    image.name = (yield uploadAppData(image)).key;
                }
            }

            const department = department_position.split('-')[0];
            const position = department_position.split('-')[1];
            const contact = {
                ...rest,
                image: image ? image.name : '',
                department,
                position,
            };
            yield call(ApiService.updateContact, contact, _id, token);
            yield put(actions.updateContactSuccess());
            yield put(push(Routes.CONTACTS.path));
            yield call(openNotification, 'success', t('apiRequest.success.title'), t('apiRequest.success.updateContact'));
        } catch (err) {
            yield put(actions.updateContactFailure(err));
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.updateContact'));
        }
    });
}

function* deleteContact() {
    yield takeLatest(actions.Types.DELETE_CONTACT, function* handle(action: ReturnType<typeof actions.deleteContact>) {
        try {
            const { contactId } = action.payload;
            const token = yield getToken();
            yield call(ApiService.deleteContact, contactId, token);
            yield put(actions.deleteContactSuccess());
            yield put(push(Routes.CONTACTS.path));
            yield call(openNotification, 'success', t('apiRequest.success.title'), t('apiRequest.success.deleteContact'));
        } catch (err) {
            yield put(actions.deleteContactFailure(err));
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.deleteContact'));
        }
    });
}

export default function* contactsSagas(): SagaIterator {
    yield fork(getContact);
    yield fork(getContacts);
    yield fork(createContact);
    yield fork(updateContact);
    yield fork(deleteContact);
}
