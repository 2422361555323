import styled from 'styled-components';
import { ThemeProps } from 'stylesheets/GlobalTheme';

export const SearchWrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${(props: ThemeProps) => props.theme.resolution.screenMd}) {
        flex-direction: row;
    }
`;
