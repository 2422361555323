import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';

declare const Conf;
const SERVICE_URL: string = Conf.SERVICE_URL;
const REQUEST_TIMEOUT: number = Conf.REQUEST_TIMEOUT;
const DOCUMENTS_URL: string = Conf.DOCUMENTS_URL;

const makeApiRequestFunction = (baseURL: string) => (url: string, { body, headers, returnRaw, ...options }: Options, params?: any) => {
    return Axios({
        ...options,
        baseURL,
        url,
        data: body,
        timeout: REQUEST_TIMEOUT,
        headers: {
            ...headers,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        params,
        paramsSerializer: (params: any) => qs.stringify(params, { indices: false }),
    })
        .then((response: AxiosResponse) => (returnRaw ? response : response.data))
        .catch((error: AxiosError) => {
            throw new FetchError(error);
        });
};

export const api = makeApiRequestFunction(SERVICE_URL);
export const documentsApi = makeApiRequestFunction(DOCUMENTS_URL);

interface Options {
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
    headers?: Record<string, string>;
    body?: any;
    returnRaw?: boolean;
}

class FetchError extends Error {
    config: AxiosRequestConfig;
    code?: string;
    request?: any;
    response?: AxiosResponse;

    constructor({ response }: AxiosError) {
        super(response.statusText);

        this.config = response.config;
        this.code = String(response.status);
        this.request = response.request;
        this.response = response;

        Object.setPrototypeOf(this, FetchError.prototype);
    }
}
