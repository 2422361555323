import { api } from 'utils/request';

import { ManualsParams, ManualsRequestBody } from './@types';

declare const Conf;

const fetcher = (token: string) => ({
    getFilters: (type: string) =>
        api(`apps/${Conf.APP_ID}/manuals/filter/${type}`, {
            method: 'GET',
            headers: {
                authorization: token,
            },
        }),
    getManuals: (params: ManualsParams) =>
        api(
            `apps/${Conf.APP_ID}/manuals`,
            {
                method: 'GET',
                headers: {
                    authorization: token,
                },
            },
            params,
        ),
    getManual: (manualId: string) =>
        api(`apps/${Conf.APP_ID}/manuals/${manualId}`, {
            method: 'GET',
            headers: {
                authorization: token,
            },
        }),
    deleteManual: (manualId: string) =>
        api(`apps/${Conf.APP_ID}/manuals/${manualId}`, {
            method: 'DELETE',
            headers: {
                authorization: token,
            },
        }),
    createManual: (body: ManualsRequestBody) =>
        api(`apps/${Conf.APP_ID}/manuals`, {
            method: 'POST',
            body,
            headers: {
                authorization: token,
            },
        }),
    updateManual: (manualId: string, body: ManualsRequestBody) =>
        api(`apps/${Conf.APP_ID}/manuals/${manualId}`, {
            method: 'PUT',
            body,
            headers: {
                authorization: token,
            },
        }),
});

export default fetcher;
