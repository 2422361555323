import PageHeader from 'ant-design-pro/lib/PageHeader';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import Table, { ColumnProps } from 'antd/es/table';
import Tag from 'antd/es/tag';
import uniq from 'lodash-es/uniq';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'redux';
import { Filter, Pagination, TableRow } from 'store/modules/manuals/@types';
import { getFilters, getManuals } from 'store/modules/manuals/actions';
import { selecPagination, selectIsLoading, selectTableColumns, selectTableRows } from 'store/modules/manuals/selectors';
import { Routes } from 'store/modules/ui/constants';
import { ITranslator, Redux } from 'typings';

const { Column } = Table;

interface Props extends ITranslator {
    getFilters?: typeof getFilters;
    push?: typeof push;
    getManuals?: typeof getManuals;
    columns?: ColumnProps<TableRow>[];
    rows?: TableRow[];
    loading?: boolean;
    pagination?: Pagination;
}

class Manuals extends Component<Props> {
    componentDidMount() {
        const { getFilters, getManuals } = this.props;
        getFilters();
        getManuals();
    }

    handleClickAddManual = () => {
        const { push } = this.props;
        push(Routes.ADD_MANUAL.path);
    };

    renderTags = (values: string[]) => (
        <span>
            {uniq(values).map((value: string) => (
                <Tag color="blue" key={value}>
                    {value}
                </Tag>
            ))}
        </span>
    );

    renderColumn = (column: ColumnProps<TableRow>) => {
        const shouldRenderTags = ['model', 'year'].includes(column.dataIndex);
        const props = Object.assign(
            column,
            shouldRenderTags && {
                render: this.renderTags,
            },
        );
        return <Column {...props} />;
    };

    handleTableChange = (pagination: Pagination, filter: Filter) => {
        const { getManuals } = this.props;
        const { current } = pagination;

        getManuals({ ...filter, page: current });
    };

    render() {
        const { loading, columns, rows, pagination, t, push } = this.props;
        return (
            <>
                <PageHeader
                    itemRender={null}
                    action={
                        <Button type="primary" className="action" onClick={this.handleClickAddManual}>
                            {t('containers.ManualForm.addManual')}
                        </Button>
                    }
                    title={t('routes.Manuals.title')}
                />
                <Card bordered={false}>
                    <Table
                        dataSource={rows}
                        onChange={this.handleTableChange}
                        loading={loading}
                        pagination={pagination}
                        onRow={(record: Props['rows'][0]) => {
                            return {
                                onClick: () => push(`${Routes.MANUALS.path}/${record.manualId}`),
                            };
                        }}
                    >
                        {columns.map(this.renderColumn)}
                    </Table>
                </Card>
            </>
        );
    }
}

export default compose<any>(
    translate(),
    connect(
        (state: Redux, props: Props) => ({
            columns: selectTableColumns(props.t)(state),
            rows: selectTableRows(state),
            loading: selectIsLoading(state),
            pagination: selecPagination(state),
        }),
        { getFilters, getManuals, push },
    ),
)(Manuals);
