import Card from 'antd/es/card';
import styled from 'styled-components';

export const Center = styled.div`
    text-align: center;
`;

export const StyledCard = styled(Card)`
    .ant-card-body {
        display: ${(props: any) => (props.loading ? 'flex' : 'block')};
        justify-content: center;
    }
`;
