import { AxiosError } from 'axios';
import { createFormAction } from 'redux-form-saga';

import { Manual, ManualsParams, ResponsePagination } from './@types';

export enum Types {
    // Fetching filters
    GET_FILTERS = 'manuals/GET_FILTERS',
    GET_FILTERS_SUCCESS = 'manuals/GET_FILTERS_SUCCESS',
    GET_FILTERS_FAILURE = 'manuals/GET_FILTERS_FAILURE',

    // Fetching manuals
    GET_MANUALS = 'manuals/GET_MANUALS',
    GET_MANUALS_SUCCESS = 'manuals/GET_MANUALS_SUCCESS',
    GET_MANUALS_FAILURE = 'manuals/GET_MANUALS_FAILURE',

    // Fetching single manual
    GET_MANUAL = 'manuals/GET_MANUAL',
    GET_MANUAL_SUCCESS = 'manuals/GET_MANUAL_SUCCESS',
    GET_MANUAL_FAILURE = 'manuals/GET_MANUAL_FAILURE',

    // Create manual
    CREATE_MANUAL = 'manuals/CREATE_MANUAL',
    CREATE_MANUAL_SUCCESS = 'manuals/CREATE_MANUAL_SUCCESS',
    CREATE_MANUAL_FAILURE = 'manuals/CREATE_MANUAL_FAILURE',

    // Update manual
    UPDATE_MANUAL = 'manuals/UPDATE_MANUAL',
    UPDATE_MANUAL_SUCCESS = 'manuals/UPDATE_MANUAL_SUCCESS',
    UPDATE_MANUAL_FAILURE = 'manuals/UPDATE_MANUAL_FAILURE',

    // Delete manual
    DELETE_MANUAL = 'manuals/DELETE_MANUAL',
    DELETE_MANUAL_SUCCESS = 'manuals/DELETE_MANUAL_SUCCESS',
    DELETE_MANUAL_FAILURE = 'manuals/DELETE_MANUAL_FAILURE',
}

export const createManual = createFormAction(Types.CREATE_MANUAL);
export const updateManual = createFormAction(Types.UPDATE_MANUAL);

export const getFilters = () => ({
    type: Types.GET_FILTERS,
});

export const getFiltersSuccess = (filters: string[][]) => ({
    type: Types.GET_FILTERS_SUCCESS,
    payload: {
        filters,
    },
});

export const getFiltersFailure = (error: AxiosError) => ({
    type: Types.GET_FILTERS_FAILURE,
    payload: { error },
});

export const getManuals = (params?: ManualsParams) => ({
    type: Types.GET_MANUALS,
    payload: { params },
});

export const getManualsSuccess = (manuals: Manual[], pagination: ResponsePagination) => ({
    type: Types.GET_MANUALS_SUCCESS,
    payload: { manuals, pagination },
});

export const getManualsFailure = (error: AxiosError) => ({
    type: Types.GET_MANUALS_FAILURE,
    payload: { error },
});

export const getManual = (manualId?: string) => ({
    type: Types.GET_MANUAL,
    payload: { manualId },
});

export const getManualSuccess = (manual: Manual) => ({
    type: Types.GET_MANUAL_SUCCESS,
    payload: { manual },
});

export const getManualFailure = (error: AxiosError) => ({
    type: Types.GET_MANUAL_FAILURE,
    payload: { error },
});

export const deleteManual = (manualId?: string) => ({
    type: Types.DELETE_MANUAL,
    payload: { manualId },
});

export const deleteManualSuccess = (manual: Manual) => ({
    type: Types.DELETE_MANUAL_SUCCESS,
    payload: { manual },
});

export const deleteManualFailure = (error: AxiosError) => ({
    type: Types.DELETE_MANUAL_FAILURE,
    payload: { error },
});
