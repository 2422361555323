import Input, { InputProps } from 'antd/es/input';
import { t } from 'i18next';
import React, { ChangeEvent } from 'react';
import { ITranslator } from 'typings';

import { Wrapper } from './styled';

const Search = Input.Search;

interface Props extends ITranslator {
    size?: InputProps['size'];
    onChange?: (event: string) => void;
    placeholder?: string;
}

class SearchInput extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.state = { searchText: '' };
    }

    handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { onChange } = this.props;
        this.setState({ searchText: event.target.value });
        onChange(event.target.value);
    };

    render() {
        const { size, placeholder } = this.props;
        return (
            <Wrapper>
                <Search
                    placeholder={placeholder || t('components.SearchInput.placeholder')}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => this.handleInputChange(event)}
                    size={size}
                />
            </Wrapper>
        );
    }
}

export default SearchInput;
