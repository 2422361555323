export const getModelsParams = (models: string[]) => {
    let modelsParams = '';
    models &&
        models.forEach((model: string) => {
            modelsParams += `&model=${model}`;
        });
    return modelsParams;
};

export const getYearsParams = (years: string[]) => {
    let yearsParams = '';
    years &&
        years.forEach((year: string) => {
            yearsParams += `&year=${year}`;
        });
    return yearsParams;
};
