import React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, RouteProps } from 'react-router';
import { selectToken } from 'store/modules/auth/selectors';
import { Redux } from 'typings';

interface Props extends RouteProps {
    token?: string;
}

class PublicRoute extends React.Component<Props> {
    render() {
        const { component: Component, ...rest } = this.props;
        return <Route {...rest} render={(props: RouteComponentProps<any>) => <Component {...props} />} />;
    }
    AnyAction;
}
export default connect(
    (state: Redux) => ({
        token: selectToken(state),
    }),
    null,
)(PublicRoute);
