import Icon from 'antd/es/icon';
import Menu from 'antd/es/menu';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Routes } from 'store/modules/ui/constants';
import { ITranslator } from 'typings';

interface Props extends ITranslator {
    key: string;
    route: string;
    iconType?: string;
    labelText: string;
}

export const SimpleMenuItem = ({ route, iconType, labelText, key, ...props }: Props) => {
    return (
        <Menu.Item key={key} {...props}>
            <NavLink
                exact={route === Routes.HOME.path}
                to={route}
                activeClassName="ant-menu-item ant-menu-item-selected"
                activeStyle={{ position: 'absolute', color: 'white' }}
            >
                <Icon type={iconType} />
                <span>{labelText}</span>
            </NavLink>
        </Menu.Item>
    );
};
