import { AxiosError } from 'axios';
import { createFormAction } from 'redux-form-saga';
import { Ticket } from 'store/modules/tickets/@types';

export enum Types {
    GET_TICKET = 'tickets/GET_TICKET',
    GET_TICKET_SUCCESS = 'tickets/GET_TICKET_SUCCESS',
    GET_TICKET_FAILURE = 'tickets/GET_TICKET_FAILURE',
    CLOSE_TICKET = 'tickets/CLOSE_TICKET',
    CLOSE_TICKET_SUCCESS = 'tickets/CLOSE_TICKET_SUCCESS',
    CLOSE_TICKET_FAILURE = 'tickets/CLOSE_TICKET_FAILURE',
    UPDATE_TICKET = 'tickets/UPDATE_TICKET',
    UPDATE_TICKET_SUCCESS = 'tickets/UPDATE_TICKET_SUCCESS',
    UPDATE_TICKET_FAILURE = 'tickets/UPDATE_TICKET_FAILURE',

    SCHEDULE_NOTIFICATION = 'tickets/SCHEDULE_NOTIFICATION',
    SCHEDULE_NOTIFICATION_SUCCESS = 'tickets/SCHEDULE_NOTIFICATION_SUCCESS',
    SCHEDULE_NOTIFICATION_FAILURE = 'tickets/SCHEDULE_NOTIFICATION_FAILURE',

    CANCEL_NOTIFICATION = 'tickets/CANCEL_NOTIFICATION',
    CANCEL_NOTIFICATION_SUCCESS = 'tickets/CANCEL_NOTIFICATION_SUCCESS',
    CANCEL_NOTIFICATION_FAILURE = 'tickets/CANCEL_NOTIFICATION_FAILURE',
}

export const updateTicket = createFormAction(Types.UPDATE_TICKET);
export const scheduleNotification = createFormAction(Types.SCHEDULE_NOTIFICATION);

export const getTicket = (caseId: string) => ({
    type: Types.GET_TICKET,
    payload: {
        caseId,
    },
});

export function getTicketSuccess(ticket: Ticket) {
    return {
        type: Types.GET_TICKET_SUCCESS,
        payload: {
            ticket,
        },
    };
}

export function getTicketFailure(error: AxiosError) {
    return {
        type: Types.GET_TICKET_FAILURE,
        payload: {
            error,
        },
    };
}

export const closeTicket = (caseId: string) => ({
    type: Types.CLOSE_TICKET,
    payload: {
        caseId,
    },
});

export function closeTicketSuccess(ticket: Ticket) {
    return {
        type: Types.CLOSE_TICKET_SUCCESS,
        payload: {
            ticket,
        },
    };
}

export function closeTicketFailure(error: AxiosError) {
    return {
        type: Types.CLOSE_TICKET_FAILURE,
        payload: {
            error,
        },
    };
}

export function updateTicketSuccess(ticket: Ticket) {
    return {
        type: Types.UPDATE_TICKET_SUCCESS,
        payload: {
            ticket,
        },
    };
}

export function updateTicketFailure(error: AxiosError) {
    return {
        type: Types.UPDATE_TICKET_FAILURE,
        payload: {
            error,
        },
    };
}

export function scheduleNotificationSuccess(ticket: Ticket) {
    return {
        type: Types.SCHEDULE_NOTIFICATION_SUCCESS,
        payload: {
            ticket,
        },
    };
}

export function scheduleNotificationFailure(error: AxiosError) {
    return {
        type: Types.SCHEDULE_NOTIFICATION_FAILURE,
        payload: {
            error,
        },
    };
}

export const cancelNotification = (eventId: string, caseId: string) => ({
    type: Types.CANCEL_NOTIFICATION,
    payload: {
        eventId,
        caseId,
    },
});

export function cancelNotificationSuccess(ticket: Ticket) {
    return {
        type: Types.CANCEL_NOTIFICATION_SUCCESS,
        payload: {
            ticket,
        },
    };
}

export function cancelNotificationFailure(error: AxiosError) {
    return {
        type: Types.CANCEL_NOTIFICATION_FAILURE,
        payload: {
            error,
        },
    };
}
