import Select from 'antd/es/select';
import styled from 'styled-components';
import { ThemeProps } from 'stylesheets/GlobalTheme';

export const StyledSelect = styled(Select)`
    width: 100%;
`;

export const Wrapper = styled.div`
    width: 100%;
    margin: 10px;

    @media screen and (min-width: ${(props: ThemeProps) => props.theme.resolution.screenMd}) {
        width: 50%;
        margin: 0 10px;
    }

    @media screen and (min-width: ${(props: ThemeProps) => props.theme.resolution.screenLg}) {
        width: 25%;
    }
`;
