import Auth from 'aws-amplify/lib/Auth';
import CognitoIdentityServiceProvider, { UserType } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import AWS from 'aws-sdk/global';
import { t } from 'i18next';
import { push } from 'react-router-redux';
import { SagaIterator } from 'redux-saga';
import { all, call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { UserGroups } from 'store/modules/contacts/constants';
import { Routes } from 'store/modules/ui/constants';
import { UserGroup } from 'store/modules/users/@types';
import { UserAction } from 'store/modules/users/constants';
import { selectUserGroups } from 'store/modules/users/selectors';
import { openNotification } from 'utils/notifications';
import translator from 'utils/translator';

import * as actions from './actions';
import { selectUserById } from './selectors';
import { listUsersInGroup } from 'store/modules/users/cognito';

declare const Conf;
function updateAWSCredentials() {
    return Auth.currentCredentials().then((credentials: any) => {
        AWS.config.update({
            region: Conf.amplify.auth.region,
            credentials: Auth.essentialCredentials(credentials),
        });
    });
}

function* getUsersInGroups() {
    yield takeEvery(actions.Types.GET_USERS_IN_GROUPS, function* handle(action: ReturnType<typeof actions.getUsersInGroups>) {
        const { userGroup } = action.payload;

        try {
            yield updateAWSCredentials();

            const users = yield listUsersInGroup(Conf.amplify.auth.userPoolId, userGroup);

            yield put(actions.getUsersInGroupsSuccess(users, userGroup));
        } catch (err) {
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
            yield put(actions.getUsersInGroupsFailure(err));
        }
    });
}

function* createUser() {
    yield takeLatest(actions.createUser.REQUEST, function* handle(action: ReturnType<typeof actions.createUser>) {
        const { email, password, userGroup } = action.payload;
        try {
            yield updateAWSCredentials();
            const cognitoidentityserviceprovider = new CognitoIdentityServiceProvider();
            const response: { User: UserType } = yield new Promise((resolve: any, reject: any) => {
                cognitoidentityserviceprovider.adminCreateUser(
                    {
                        Username: email,
                        UserPoolId: Conf.amplify.auth.userPoolId,
                        DesiredDeliveryMediums: ['EMAIL'],
                        TemporaryPassword: password,
                        UserAttributes: [
                            {
                                Name: 'email',
                                Value: email,
                            },
                            {
                                Name: 'email_verified',
                                Value: 'true',
                            },
                        ],
                    },
                    (err: any, data: CognitoIdentityServiceProvider.Types.AdminCreateUserResponse) => {
                        if (err) {
                            reject(err);
                        } else {
                            resolve(data);
                        }
                    },
                );
            });
            yield new Promise((resolve: any, reject: any) => {
                cognitoidentityserviceprovider.adminAddUserToGroup(
                    {
                        GroupName: userGroup,
                        Username: response.User.Username,
                        UserPoolId: Conf.amplify.auth.userPoolId,
                    },
                    (err: any, data: CognitoIdentityServiceProvider.Types.AdminCreateUserResponse) => {
                        if (err) {
                            reject(err);
                        } else {
                            resolve(data);
                        }
                    },
                );
            });
            yield put(actions.createUserSuccess(response.User));
            yield put(actions.getUserGroups());
            yield put(push(Routes.USERS.path));
        } catch (err) {
            if (err.code === 'UsernameExistsException') {
                yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.userAlreadyExists'));
            } else {
                yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
            }
            yield put(actions.createUserFailure(err));
        }
    });
}

function* getUser() {
    yield takeLatest(actions.Types.GET_USER, function* handle(action: ReturnType<typeof actions.getUser>) {
        const { userId } = action.payload;
        const user = yield select(selectUserById(userId));
        if (!user.length) {
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.userDoesntExist'));
            yield put(push(`/users`));
            yield put(actions.getUserFailure('User not found'));
        } else {
            yield put(actions.getUserSuccess(user[0]));
        }
    });
}

function* updateUser() {
    yield takeLatest(actions.updateUser.REQUEST, function* handle(action: ReturnType<typeof actions.updateUser>) {
        const { email, fromGroup, uId, username, userGroup } = action.payload;

        const translate = translator('userGroups');
        const groups = {
            [translate('tech')]: UserGroups.TECH,
            [translate('admin')]: UserGroups.ADMIN,
            [translate('backoffice')]: UserGroups.BACKOFFICE,
        };

        try {
            yield updateAWSCredentials();
            const cognitoidentityserviceprovider = new CognitoIdentityServiceProvider();

            if (fromGroup !== userGroup) {
                yield new Promise((resolve: any, reject: any) => {
                    cognitoidentityserviceprovider.adminRemoveUserFromGroup(
                        {
                            Username: uId,
                            UserPoolId: Conf.amplify.auth.userPoolId,
                            GroupName: groups[fromGroup],
                        },
                        (err: any, data: {}) => {
                            if (err) {
                                reject(err);
                            } else {
                                resolve(data);
                            }
                        },
                    );
                });

                yield new Promise((resolve: any, reject: any) => {
                    cognitoidentityserviceprovider.adminAddUserToGroup(
                        {
                            Username: uId,
                            UserPoolId: Conf.amplify.auth.userPoolId,
                            GroupName: userGroup,
                        },
                        (err: any, data: {}) => {
                            if (err) {
                                reject(err);
                            } else {
                                resolve(data);
                            }
                        },
                    );
                });
            }

            yield new Promise((resolve: any, reject: any) => {
                cognitoidentityserviceprovider.adminUpdateUserAttributes(
                    {
                        Username: username,
                        UserPoolId: Conf.amplify.auth.userPoolId,
                        UserAttributes: [
                            {
                                Name: 'email',
                                Value: email,
                            },
                        ],
                    },
                    (err: any, data: CognitoIdentityServiceProvider.Types.AdminUpdateUserAttributesResponse) => {
                        if (err) {
                            reject(err);
                        } else {
                            resolve(data);
                        }
                    },
                );
            });

            yield put(push(Routes.USERS.path));
            yield put(actions.updateUser.success());
            yield put(actions.getUserGroups());
        } catch (err) {
            if (err.code === 'UsernameExistsException') {
                yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.userAlreadyExists'));
            } else {
                yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
            }
            yield put(actions.updateUser.failure());
        }
    });
}

function* userAction() {
    yield takeLatest(actions.Types.USER_ACTION, function* handle(action: ReturnType<typeof actions.userAction>) {
        const { userAction, userId } = action.payload;

        try {
            yield updateAWSCredentials();
            const isDeleteAction = userAction === UserAction.DELETE;
            const cognitoidentityserviceprovider = new CognitoIdentityServiceProvider();
            yield new Promise((resolve: any, reject: any) => {
                cognitoidentityserviceprovider[`admin${userAction}User`](
                    {
                        Username: userId,
                        UserPoolId: Conf.amplify.auth.userPoolId,
                    },
                    (err: any, data: null) => {
                        if (err) {
                            reject(err);
                        } else {
                            resolve(data);
                        }
                    },
                );
            });
            if (isDeleteAction) {
                yield put(push(Routes.USERS.path));
            }
            yield put(actions.userActionSuccess(userAction));
            yield put(actions.getUserGroups());
        } catch (err) {
            if (err.code === 'UserNotFoundException') {
                yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.userDoesntExist'));
                yield put(push(`/404`));
            } else {
                yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.unknown'));
            }
            yield put(actions.userActionFailure(err));
        }
    });
}

function* getUserGroups() {
    yield takeLatest(actions.Types.GET_USER_GROUPS, function* handle(action: ReturnType<typeof actions.getUserGroups>) {
        try {
            yield updateAWSCredentials();
            const cognitoidentityserviceprovider = new CognitoIdentityServiceProvider();
            const response: { Groups: UserGroup[]; NextToken: string } = yield new Promise((resolve: any, reject: any) => {
                cognitoidentityserviceprovider.listGroups(
                    {
                        Limit: 60,
                        UserPoolId: Conf.amplify.auth.userPoolId,
                    },
                    (err: any, data: any) => {
                        if (err) {
                            reject(err);
                        } else {
                            resolve(data);
                        }
                    },
                );
            });
            yield put(actions.getUserGroupsSuccess(response.Groups, response.NextToken));

            const userGroups = yield select(selectUserGroups);
            yield all(userGroups.map((record: UserGroup) => put(actions.getUsersInGroups(record.GroupName))));
        } catch (err) {
            yield call(openNotification, 'error', t('apiRequest.error.title'), t('apiRequest.error.getUserGroups'));
            yield put(actions.getUserGroupsFailure(err));
        }
    });
}

export default function* authSaga(): SagaIterator {
    yield fork(createUser);
    yield fork(getUser);
    yield fork(updateUser);
    yield fork(userAction);
    yield fork(getUserGroups);
    yield fork(getUsersInGroups);
}
